import React, { createContext, useReducer, useContext } from "react"
import { setupState } from "@prospecer/ffsds/src/fe/state"
import {
    initialState as studioInitialState,
    reducer as studioReducer,
} from "./studio"

import {
    initialState as clientInitialState,
    reducer as clientReducer,
} from "./clients"

import {
    initialState as tagTypesInitialState,
    reducer as tagTypesReducer,
} from "./tag_types"

import {
    initialState as contractorsInitialState,
    reducer as contractorsReducer,
} from "./contractors"

import {
    initialState as projectsInitialState,
    reducer as projectsReducer,
} from "./projects"

import {
    initialState as specificationsInitialState,
    reducer as specificationsReducer,
} from "./specifications"

import {
    initialState as suppliersInitialState,
    reducer as suppliersReducer,
} from "./suppliers"

import {
    initialState as usersInitialState,
    reducer as usersReducer,
} from "./users"

import {
    initialState as infosInitialState,
    reducer as infosReducer,
} from "./infos"

import {
    initialState as imagesInitialState,
    reducer as imagesReducer,
} from "./images"

import {
    initialState as filesInitialState,
    reducer as filesReducer,
} from "./files"

import {
    initialState as projectSharesInitialState,
    reducer as projectSharesReducer,
} from "./project_shares"
import {
    initialState as issuancesInitialState,
    reducer as issuancesReducer,
} from "./issuances"

import {
    initialState as consultantsInitialState,
    reducer as consultantsReducer,
} from "./consultants"

import {
    initialState as loginsInisitalState,
    reducer as loginsReducer,
} from "./logins"

import {
    initialState as refersInitialState,
    reducer as refersReducer,
} from "./refers"

import {
    initialState as invoicesInitialState,
    reducer as invoicesReducer,
} from "./invoices"

import {
    initialState as invoiceLineItemsInitialState,
    reducer as invoiceLineItemsReducer,
} from "./invoiceLineItems"

import {
    initialState as taxesInitialState,
    reducer as taxesReducer,
} from "./taxes"

const reducers = [
    studioReducer,
    clientReducer,
    tagTypesReducer,
    contractorsReducer,
    projectsReducer,
    specificationsReducer,
    suppliersReducer,
    usersReducer,
    infosReducer,
    imagesReducer,
    projectSharesReducer,
    issuancesReducer,
    consultantsReducer,
    loginsReducer,
    refersReducer,
    filesReducer,
    invoicesReducer,
    invoiceLineItemsReducer,
    taxesReducer,
]
let initialState = {
    studios: studioInitialState,
    clients: clientInitialState,
    tag_types: tagTypesInitialState,
    contractors: contractorsInitialState,
    projects: projectsInitialState,
    specifications: specificationsInitialState,
    suppliers: suppliersInitialState,
    users: usersInitialState,
    infos: infosInitialState,
    images: imagesInitialState,
    project_shares: projectSharesInitialState,
    issuances: issuancesInitialState,
    consultants: consultantsInitialState,
    logins: loginsInisitalState,
    refers: refersInitialState,
    files: filesInitialState,
    invoices: invoicesInitialState,
    invoice_line_items: invoiceLineItemsInitialState,
    taxes: taxesInitialState,
}

const { store, StateProvider, useGlobalState } = setupState(
    initialState,
    reducers
)
export { store, StateProvider, useGlobalState }
