import { Pivot, PivotItem } from "@fluentui/react"
import { useParams } from "react-router-dom"
import { SpecificationsList } from "./Specifications"
import { ProcurementList } from "./Procurement"
import config from "../config"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

import { useEntityFromConfig } from "../hooks/Entity"
import { infoConfig } from "./Infos"

export default function SpecsMain(props) {
    const { id } = useParams()
    const enabled = useFeatureIsOn("invoicing")

    const useInfo = useEntityFromConfig(infoConfig)
    const infos = useInfo.filter([])
    const hasBilling = infos[0]?.hasBilling

    if (!enabled) {
        return (
            <div data-is-scrollable="true">
                <SpecificationsList projectId={id} />
            </div>
        )
    }

    return (
        <Pivot aria-label="Specification Tabs">
            <PivotItem
                headerText="Specs"
                headerButtonProps={{
                    "data-order": 1,
                    "data-title": "Specs",
                }}
            >
                <div data-is-scrollable="true">
                    <SpecificationsList projectId={id} />
                </div>
            </PivotItem>
            <PivotItem
                headerText="Procurement"
                headerButtonProps={{
                    "data-order": 2,
                    "data-title": "Procurement",
                }}
            >
                <div data-is-scrollable="true">
                    <ProcurementList projectId={id} />
                </div>
            </PivotItem>
        </Pivot>
    )
}
