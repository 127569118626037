import { Panel, PanelType, Text } from "@fluentui/react"
import { useEntityFromConfig } from "../hooks/Entity"
import { useConfig } from "./Specifications"
import { useStudioConfig } from "./Studios"
import { infoConfig } from "./Infos"
import { useTaxCalculator } from "./Taxes"
import MiniList from "../layouts/MiniList"
import { over } from "lodash"
import { useTagTypeConfig, tagTypeTypes } from "./TagTypes"

function useCalculateBudget(studio, fullRows, rows) {
    const useTag = useEntityFromConfig(useTagTypeConfig())

    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })
    let totalBudget = 0

    for (const r of fullRows) {
        if (r.budget) {
            totalBudget = totalBudget + r.budget
        }
    }

    let overUnder = totalBudget

    for (const r of fullRows) {
        if (r.budget && r.price) {
            overUnder =
                overUnder - r.price * (r.quantity === null ? 1 : r.quantity)
        }
    }

    let overUnderAll = totalBudget

    for (const r of fullRows) {
        if (r.price) {
            overUnderAll =
                overUnderAll - r.price * (r.quantity === null ? 1 : r.quantity)
        }
    }

    const scheduleValues = {}
    for (const r of fullRows) {
        if (r.budget) {
            const tag = useTag.filter([
                {
                    field: "id",
                    type: "eq",
                    value: r.tagTypeId,
                },
            ])
            if (!tag[0]) {
                continue
            }
            if (!scheduleValues[tag[0].type]) {
                scheduleValues[tag[0].type] = { total: 0, overUnder: 0 }
            }
            scheduleValues[tag[0].type].total =
                scheduleValues[tag[0].type].total + r.budget
            scheduleValues[tag[0].type].overUnder =
                scheduleValues[tag[0].type].overUnder +
                r.budget -
                r.price * (r.quantity === null ? 1 : r.quantity)
        }
    }

    const budgetValues = []
    budgetValues.push(
        {
            key: "__total_budget",
            value: formatter.format(totalBudget / 100),
            label: "Total Budget",
        },
        {
            key: "__over_under",
            value: formatter.format(overUnder / 100),
            label: "Over Under",
        },
        {
            key: "__over_under_all",
            value: formatter.format(overUnderAll / 100),
            label: "Over Under (ALL)",
        }
    )

    const catBudgetValues = {}

    for (const cat in scheduleValues) {
        const catagory = tagTypeTypes.find((e) => e.key === cat)
        catBudgetValues[catagory.text] = [
            {
                key: "__" + cat + "_budget",
                value: formatter.format(scheduleValues[cat].total / 100),
                label: "Total Budget",
            },
            {
                key: "__" + cat + "_over_under",
                value: formatter.format(scheduleValues[cat].overUnder / 100),
                label: "Over Under",
            },
        ]
    }

    return { budgetValues, totalBudget, catBudgetValues }
}

function useCalculateProjectPrices(studio, fullRows, rows) {
    const taxCalculator = useTaxCalculator()
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })
    let total = 0
    let tradeTotal = 0
    let tax = 0

    for (const r of fullRows) {
        if (r.tradePrice > 0) {
            tradeTotal =
                tradeTotal +
                r.tradePrice * (r.quantity === null ? 1 : r.quantity)
        }
        if (r.price > 0) {
            total = total + r.price * (r.quantity === null ? 1 : r.quantity)
            const taxrate = r.tax ? r.tax : studio ? studio.defaultTax : 0

            tax =
                tax +
                taxCalculator(
                    r.price * (r.quantity === null ? 1 : r.quantity),
                    r.taxIds
                )?.totalTaxAmount
        }
    }

    let stotal = 0
    let stax = 0
    let stradeTotal = 0
    let sRetail = 0
    for (const r of rows) {
        if (r.tradePrice > 0) {
            stradeTotal =
                stradeTotal +
                r.tradePrice * (r.quantity === null ? 1 : r.quantity)
        }
        if (r.price > 0) {
            stotal = stotal + r.price * (r.quantity === null ? 1 : r.quantity)

            stax =
                stax +
                taxCalculator(
                    r.price * (r.quantity === null ? 1 : r.quantity),
                    r.taxIds
                )?.totalTaxAmount
        }
    }

    const selection = []
    selection.push({
        key: "__trade_total_selection",
        value: formatter.format(stradeTotal / 100),
        label: "Shown Trade Subtotal",
    })
    selection.push({
        key: "__total_selection",
        value: formatter.format(stotal / 100),
        label: "Shown Subtotal",
    })
    selection.push({
        key: "__total_selection_tax",
        text: formatter.format(stax / 100),
        label: "Shown Tax",
    })
    selection.push({
        key: "__total_selection_total",
        value: formatter.format(stax / 100 + stotal / 100),
        label: "Shown Total",
    })

    const values = []
    values.push({
        key: "__trade_total",
        value: formatter.format(tradeTotal / 100),
        label: "Trade Subtotal",
    })
    values.push({
        key: "__total",
        value: formatter.format(total / 100),
        label: "Subtotal",
    })
    values.push({
        key: "__total_tax",
        value: formatter.format(tax / 100),
        label: "Tax",
    })
    values.push({
        key: "__total_total",
        value: formatter.format(tax / 100 + total / 100),
        label: "Total",
    })

    let preTaxProfit = 0

    let p_preTaxProfit = 0

    for (const r of fullRows) {
        if (r.tradePrice > 0 && r.price > 0) {
            preTaxProfit =
                preTaxProfit +
                (r.price * (r.quantity === null ? 1 : r.quantity) -
                    r.tradePrice * (r.quantity === null ? 1 : r.quantity))
        }
    }

    for (const r of rows) {
        if (r.tradePrice > 0 && r.price > 0) {
            p_preTaxProfit =
                p_preTaxProfit +
                (r.price * (r.quantity === null ? 1 : r.quantity) -
                    r.tradePrice * (r.quantity === null ? 1 : r.quantity))
        }
    }
    const profit = []
    profit.push({
        key: "__total_profit",
        value: formatter.format(preTaxProfit / 100),
        label: "Profit",
    })
    profit.push({
        key: "__selected_profit",
        value: formatter.format(p_preTaxProfit / 100),
        label: "Profit Selected",
    })

    return { selection, values, profit }
}

export default function BudgetPanel(props) {
    const filter = props.filter
    const projectId = props.projectId
    const isOpen = props.isOpen

    const useSpecifictions = useEntityFromConfig(useConfig(projectId))
    const useStudio = useEntityFromConfig(useStudioConfig())
    const useInfo = useEntityFromConfig(infoConfig)

    const info = useInfo.filter([])[0]

    const studio = useStudio.filter([
        {
            field: "id",
            type: "eq",
            value: info?.studioId,
        },
    ])[0]

    const fullRows = useSpecifictions.filter([
        { field: "projectId", type: "eq", value: projectId },
    ])

    const rows = useSpecifictions.filter(filter)

    const { selection, values, profit } = useCalculateProjectPrices(
        studio,
        fullRows,
        rows
    )
    const { budgetValues, catBudgetValues } = useCalculateBudget(
        studio,
        fullRows,
        rows
    )

    const columns = [
        {
            key: "label",
            name: "Name",
            fieldName: "label",
            maxWidth: 140,
            minWidth: 140,
            isMultiline: false,
        },
        {
            key: "value",
            name: "Value",
            fieldName: "value",
            isMultiline: false,
        },
    ]

    const bContent = []
    for (const k in catBudgetValues) {
        bContent.push(
            <div key="k">
                <Text variant="large">{k}</Text>
                <MiniList items={catBudgetValues[k]} columns={columns} />
            </div>
        )
    }

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.smallFixedNear}
            isLightDismiss
            onDismiss={props.onDismiss}
            headerText="Pricing"
        >
            <Text variant="large">Shown Pricing</Text>
            <MiniList items={selection} columns={columns} />

            <Text variant="large">Project Pricing</Text>
            <MiniList items={values} columns={columns} />

            <Text variant="large">Profit</Text>
            <MiniList items={profit} columns={columns} />
            <br />
            <Text variant="xLarge">Budget</Text>
            <br />
            <Text variant="large">Project Budget</Text>
            <MiniList items={budgetValues} columns={columns} />
            {bContent}
        </Panel>
    )
}
