import { registerIcons } from "@fluentui/react"

export function prospecerIcons() {
    registerIcons({
        icons: {
            pOverBudget: (
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 573 541"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        fillRule: "evenodd",
                        clipRule: "evenodd",
                        strokelinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 1.5,
                    }}
                >
                    <g transform="matrix(1,0,0,1,-113,-24)">
                        <g transform="matrix(26.4259,0,0,26.4259,-4360.97,-7753.44)">
                            <path
                                d="M182.925,308.247C182.925,308.631 182.829,308.997 182.637,309.345C182.445,309.693 182.163,309.983 181.791,310.215C181.419,310.447 180.973,310.579 180.453,310.611L180.453,311.583L179.805,311.583L179.805,310.611C179.077,310.547 178.485,310.313 178.029,309.909C177.573,309.505 177.341,308.991 177.333,308.367L178.497,308.367C178.529,308.703 178.655,308.995 178.875,309.243C179.095,309.491 179.405,309.647 179.805,309.711L179.805,306.711C179.269,306.575 178.837,306.435 178.509,306.291C178.181,306.147 177.901,305.923 177.669,305.619C177.437,305.315 177.321,304.907 177.321,304.395C177.321,303.747 177.547,303.211 177.999,302.787C178.451,302.363 179.053,302.127 179.805,302.079L179.805,301.083L180.453,301.083L180.453,302.079C181.133,302.135 181.681,302.353 182.097,302.733C182.513,303.113 182.753,303.607 182.817,304.215L181.653,304.215C181.613,303.935 181.489,303.681 181.281,303.453C181.073,303.225 180.797,303.075 180.453,303.003L180.453,305.931C180.981,306.067 181.411,306.205 181.743,306.345C182.075,306.485 182.355,306.707 182.583,307.011C182.811,307.315 182.925,307.727 182.925,308.247ZM178.437,304.335C178.437,304.727 178.553,305.027 178.785,305.235C179.017,305.443 179.357,305.615 179.805,305.751L179.805,302.979C179.389,303.019 179.057,303.153 178.809,303.381C178.561,303.609 178.437,303.927 178.437,304.335ZM180.453,309.723C180.885,309.675 181.223,309.519 181.467,309.255C181.711,308.991 181.833,308.675 181.833,308.307C181.833,307.915 181.713,307.613 181.473,307.401C181.233,307.189 180.893,307.019 180.453,306.891L180.453,309.723Z"
                                style={{ fillRule: "nonzero", fill: "#DA9A29" }}
                            />
                        </g>
                        <g transform="matrix(1,0,0,1,31.7812,-5.84375)">
                            <path
                                d="M368.672,55.781L632.177,532.333L105.167,532.333L368.672,55.781Z"
                                style={{
                                    fill: "none",
                                    stroke: "#DA9A29",
                                    strokeWidth: "40px",
                                }}
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
    })
}
