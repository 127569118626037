import {
    DetailsList,
    GroupedList,
    ImageFit,
    Label,
    SelectionMode,
    Stack,
    StackItem,
    Text,
    useTheme,
} from "@fluentui/react"
import { issuances as issuancesValidator } from "@prospecer/schema"
import { values } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import config from "../config"
import { Images } from "../controls/Image"
import { useEntityFromConfig } from "../hooks/Entity"
import Panel from "../layouts/Panel"
import UpsertPanel from "../layouts/UpsertPanel"
import { EntityForm, EntityList } from "./Entities"
import {
    PrintDialog,
    SpecificationsForm,
    tagFormatter,
    useConfig as useSpecificationConfig,
} from "./Specifications"
import { useTagTypeConfig } from "./TagTypes"
import { Panel as FPanel, PanelType } from "@fluentui/react/lib/Panel"
import { useConfig as useProjectSharesConfig } from "./ProjectShares"
import { supplierConfig } from "./Suppliers"
import { consultantsConfig } from "./Consultants"

export function TagList(props) {
    const specsConfig = useSpecificationConfig(props.projectId)
    const useSpecs = useEntityFromConfig(specsConfig)
    const useTagTypes = useEntityFromConfig(useTagTypeConfig())
    const theme = useTheme()
    const tt = useTagTypes
        .filter([{ type: "_sort", key: "type" }])
        .map((t) => [t.id])

    const ttNames = useTagTypes
        .filter([{ type: "_sort", key: "type" }])
        .map((t) => [t.id, t.type])

    const projectSpecs = useSpecs.filter([
        { field: "id", type: "in", value: props.ids ? props.ids : [] },
        { field: "projectId", type: "eq", value: props.projectId },
        { type: "listSort", value: tt, field: "tagTypeId" },
    ])

    const issuedSpecs = useSpecs.filter([
        { field: "projectId", type: "eq", value: props.issuanceId },
        { type: "listSort", value: tt, field: "tagTypeId" },
    ])
    const items = []
    const groups = []

    const columns = [
        { key: "tag", name: "Tag", fieldName: "tag" },
        { key: "item", name: "Item", fieldName: "item" },
    ]

    const specs = props.isNew ? projectSpecs : issuedSpecs

    for (const s of specs) {
        const tt = useTagTypes.filter([
            { field: "id", type: "eq", value: s.tagTypeId },
        ])

        if (!items[tt[0].type]) {
            items[tt[0].type] = []
        }
        items[tt[0].type].push({
            key: s.id,
            tag: tagFormatter(useTagTypes, s.tagTypeId, s.tagNumber),
            item: s.name,
        })
    }
    const flattenedItems = []
    let index = 0
    for (const g in items) {
        flattenedItems.push(...items[g])
        groups.push({
            key: g,
            name: g.toLocaleUpperCase(),
            startIndex: index,
            count: items[g].length,
            level: 0,
        })
        index = items[g].length
    }

    return (
        <div style={{ paddingTop: theme.spacing.s1 }}>
            <label>
                <b>Specifications</b>
            </label>
            <br />
            <DetailsList
                items={flattenedItems}
                selectionMode={SelectionMode.none}
                groups={groups}
                compact={true}
                columns={columns}
            />
        </div>
    )
}

function SharedWith(props) {
    const useSharing = useEntityFromConfig(useProjectSharesConfig())
    const useConsultant = useEntityFromConfig(consultantsConfig)
    const shareWith = useSharing.filter([
        { field: "projectId", type: "eq", value: props.values.projectId },
        { field: "enabled", type: "eq", value: 1 },
    ])
    const results = []

    let sharedWithClient = false

    for (const s of shareWith) {
        if (s.consultantId) {
            const consultants = useConsultant.filter([
                { field: "id", type: "eq", value: s.consultantId },
            ])
            results.push(
                <div>
                    <Text>
                        {consultants && consultants[0]
                            ? consultants[0].contact.name
                            : ""}
                    </Text>
                </div>
            )
        }
        if (!s.consultantId && !s.supplierId) {
            sharedWithClient = true
        }
    }
    return (
        <Stack>
            <Label>Shared With</Label>
            {results.length === 0 && <div>No one</div>}
            {results}
            {sharedWithClient && <Text>The Client</Text>}
        </Stack>
    )
}

export function useIssuanceConfig(projectId) {
    const filter = [{ field: "projectId", type: "eq", value: projectId }]

    return {
        baseUrl: config.api.baseUrl + "/issuances",
        type: "ISSUANCES",
        stateKey: "issuances",
        name: "Issuances",
        nameSingle: "Issuance",
        readScope: "read:issuances",
        writeScope: "write:issuances",
        defaultSortKey: "createdAt",
        validator: issuancesValidator,
        allowDelete: true,
        partitionField: "projectId",
        partitionKey: projectId,
        filter: filter,
        showEventsOnForm: true,
        listColumns: [
            {
                key: "name",
                title: "Name",
            },
            {
                key: "createdAt",
                title: "Issued",
                formatter: (value) => {
                    return new Date(value).toLocaleString()
                },
            },

            {
                key: "attachments",
                title: "Attachments",

                formatter: (value) => {
                    return (
                        <Images
                            anyFiles
                            imageFit={ImageFit.cover}
                            value={value}
                        />
                    )
                },
            },
            {
                key: "note",
                title: "Note",
                formatter: (value) => {
                    return (
                        <div style={{ whiteSpace: "break-spaces" }}>
                            {value}
                        </div>
                    )
                },
            },
        ],
        formFields: [
            {
                label: "Name",
                key: "name",
                type: "string",
            },
            {
                key: "createdAt",
                type: "info",
                displayOnly: true,
                label: "Created",
                valueFunction: (values) => {
                    return new Date(values.createdAt).toLocaleString()
                },
            },
            {
                key: "sharewith",
                type: "info",
                displayOnly: true,
                label: "Shared With",
                component: true,
                valueFunction: (values) => {
                    return <SharedWith values={values} filter={filter} />
                },
            },

            {
                label: "Send Email Update",
                key: "sendEmail",
                type: "toggle",
                onText: "Send",
                offText: "Don't Send",
                disabledOnUpdate: true,
            },

            {
                label: "Attachments",
                key: "attachments",
                type: "image",
                allowDelete: true,
                anyFiles: true,
            },
            {
                label: "Notes",
                key: "note",
                type: "string",
                option: "multiline",
            },
            {
                label: "Issued Specs",
                key: "issuedSpecificationIds",
                type: "info",
                option: "multiline",
                component: true,

                valueFunction: (values) => {
                    return (
                        <TagList
                            projectId={values.projectId}
                            issuanceId={values.id}
                            ids={values.issuedSpecificationIds}
                            isNew={values.__new}
                        />
                    )
                },
            },
        ],
        emptyFormValue: {
            __new: true,
            issuedSpecificationIds: [],
            projectId: projectId,
            sendEmail: 1,
            createdAt: new Date(),
        },
        Form: (props) => {
            return <IssuancesForm {...props} projectId={projectId} />
        },
    }
}

export function usePublicIssuanceConfig(studioId, id) {
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/issuances?id=" +
            id +
            "&studioId=" +
            studioId,
        type: "ISSUANCES",
        stateKey: "issuances",
        name: "Issuances",
        nameSingle: "Issuance",
        readScope: "read:issuances",
        writeScope: "write:issuances",
        defaultSortKey: "createdAt",
        validator: issuancesValidator,
        allowDelete: false,
        disablePrint: true,
        showEventsOnForm: true,
        insecure: true,
    }
}

export function IssuancesList(props) {
    const issuancesConfig = useIssuanceConfig(props.projectId)
    const history = useHistory()

    return (
        <div>
            <EntityList
                onNew={() => {
                    history.push("/schedule/" + props.projectId + "/issuance")
                }}
                onEdit={(obj) => {
                    history.push(
                        "/schedule/" + props.projectId + "/issuance/" + obj.id
                    )
                }}
                config={issuancesConfig}
            />
        </div>
    )
}

export function IssuancesForm(props) {
    const issuancesConfig = useIssuanceConfig(props.projectId)
    return <EntityForm {...props} config={issuancesConfig} />
}

export function IssuancesPage(props) {
    const [printRows, setPrintRows] = useState(null)
    const [panelOpen, setPanelOpen] = useState(true)

    const id = props.issuanceId
    const issuancesConfig = useIssuanceConfig(props.projectId)
    const specConfig = useSpecificationConfig(
        props.projectId,
        id ? { issuanceId: id } : {}
    )
    const useSpec = useEntityFromConfig(specConfig)

    const useIssuance = useEntityFromConfig(issuancesConfig)
    const submitRef = useRef()
    const history = useHistory()
    const theme = useTheme()
    const [selected, setSelected] = useState([])
    const [renderKey, setRenderKey] = useState(1)

    let spec = id
        ? useIssuance.filter([
              { field: "id", type: "eq", value: id },
              { field: "projectId", type: "eq", value: props.projectId },
          ])
        : false

    const values =
        spec && spec.length > 0 ? spec[0] : issuancesConfig.emptyFormValue
    const [specs, setSpecs] = useState(id ? [] : values.issuedSpecificationIds)

    const setValuesRef = useRef()
    const valuesRef = useRef()

    //Supper annoying.
    useEffect(() => {
        useSpec.dispatch.refresh([
            { field: "projectId", type: "eq", value: id },
        ])
    }, [id])

    if ((spec === false || spec.length === 0) && id) {
        //Means our data has not loaded yet. We will force load
        return <span>Loading</span>
    }

    const additionalButtons = specConfig.additionalButtons

    if (!id) {
        specConfig.additionalButtons = [
            {
                key: "addChecked",
                text: "Add Checked To Issuance",
                iconProps: { iconName: "Add" },
                doNotHide: true,
                onClick: () => {
                    const sp = { ...valuesRef.current }
                    for (const i of selected) {
                        if (!sp.issuedSpecificationIds.includes(i._id)) {
                            sp.issuedSpecificationIds.push(i._id)
                        }
                    }
                    setValuesRef.current(sp)
                    setRenderKey(renderKey + 1)
                },
            },
            {
                key: "removeCheked",
                text: "Remove Checked To Issuance",
                iconProps: { iconName: "Remove" },
                doNotHide: true,
                onClick: () => {
                    const sp = { ...valuesRef.current }
                    for (const i of selected) {
                        if (sp.issuedSpecificationIds.includes(i._id)) {
                            const index = sp.issuedSpecificationIds.indexOf(
                                i._id
                            )
                            sp.issuedSpecificationIds.splice(index, 1)
                        }
                    }
                    setValuesRef.current(sp)
                    setRenderKey(renderKey + 1)
                },
            },
            ...additionalButtons,
        ]
    }

    specConfig.listColumns.push({
        key: "__markerColor",
        formatter: (fields) => {
            if (
                valuesRef.current &&
                valuesRef.current.issuedSpecificationIds.includes(fields.id)
            ) {
                return theme.palette.green
            }
        },
    })

    return (
        <div>
            {printRows && (
                <PrintDialog
                    onClose={() => {
                        setPrintRows(null)
                    }}
                    filter={specConfig.filter}
                    projectId={props.projectId}
                    rows={printRows}
                />
            )}
            <div
                style={{
                    backgroundColor: theme.palette.themeLight,
                    padding: theme.spacing.m,
                    margin: theme.spacing.s1,
                    marginBottom: 0,
                    border: "1px dashed " + theme.palette.black,
                }}
            >
                <>
                    {!id && (
                        <Text variant="large">
                            Add specifications to issuance.
                        </Text>
                    )}
                    {id && (
                        <Text variant="large">
                            Specs issued. (To change delete issuance and
                            recreate)
                        </Text>
                    )}
                </>
            </div>

            <EntityList
                multiSelection
                hideButtons
                doNotHidePrint={!!id}
                searchStack={true}
                onSelectionChanged={(selection) => setSelected(selection)}
                config={{ ...specConfig, readOnly: true, Form: null }}
                styles={{
                    listContainer: { height: "calc(100vh - 270px) !important" },
                }}
                printFunction={
                    config.reporting.enabled
                        ? (rows) => {
                              setPrintRows(rows)
                          }
                        : null
                }
            />

            <UpsertPanel
                boguskey={renderKey}
                buttonLabel={id ? "Add Issuance" : "Edit Issuance"}
                AddForm={(props) => {
                    return (
                        <IssuancesForm
                            {...props}
                            setValuesRef={setValuesRef}
                            valuesRef={valuesRef}
                        />
                    )
                }}
                submitRef={submitRef}
                values={valuesRef.current ? valuesRef.current : values}
                onClose={() => {
                    useSpec.dispatch.refresh([
                        { field: "projectId", value: valuesRef.current.id },
                    ])
                    history.push(
                        "/schedule/" + props.projectId + "?issuanceOpen"
                    )
                }}
                isBlocking={false}
                noAnimation={true}
                isOpen={panelOpen}
                showHideButton
            />
        </div>
    )
}
