import * as React from "react"
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button"
import { Panel as FPanel, PanelType } from "@fluentui/react/lib/Panel"
import { useBoolean } from "@fluentui/react-hooks"
import { Icon, mergeStyles, useTheme } from "@fluentui/react"
const buttonStyles = { root: { marginRight: 8 } }

export default function Panel(props) {
    const [hide, setHide] = React.useState(false)
    const theme = useTheme()
    const onRenderFooterContent = React.useCallback(
        () => (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                }}
            >
                {!props.hideButtons && (
                    <>
                        <PrimaryButton
                            onClick={props.onSave}
                            styles={buttonStyles}
                        >
                            {props.saveButtonLabel
                                ? props.saveButtonLabel
                                : "Save"}
                        </PrimaryButton>
                        <DefaultButton onClick={props.onClose}>
                            Cancel
                        </DefaultButton>
                    </>
                )}
            </div>
        ),
        [props.onClose, props.onSave, props.hideButtons]
    )

    const styles = {
        content: {
            paddingTop: "10px",
        },

        footer: {
            background: theme.palette.white,
            boxShadow: " 0 1px 5px 1px " + theme.palette.themeDark,
        },
        commands: {
            background: theme.palette.white,
            zIndex: 99,
            boxShadow: " 0 -1px 5px 1px " + theme.palette.themeDark,
        },
    }
    if (props.hideButtons) {
        delete styles.footer
    }
    if (props.noAnimation) {
        styles.main = {
            animation: "none",
        }
    }
    if (hide) {
        styles.root = {
            height: "50px",
        }
    }

    const hideTitleStyle = mergeStyles({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        textOverflow: "ellipsis",
    })
    return (
        <div>
            <FPanel
                type={
                    props.large
                        ? PanelType.large
                        : props.extraLarge
                        ? PanelType.extraLarge
                        : props.near
                        ? PanelType.smallFixedNear
                        : PanelType.small
                }
                styles={styles}
                layerProps={{ eventBubblingEnabled: true }}
                isOpen={props.isOpen !== undefined ? props.isOpen : true}
                isLightDismiss={props.lightDismiss}
                onDismiss={(ev) => {
                    if (!props.enableEscape) {
                        if (ev.code && ev.code === "Escape") {
                            ev.preventDefault()
                            return
                        }
                    }
                    props.onClose(ev)
                }}
                headerText={
                    props.showHideButton ? (
                        <div className={hideTitleStyle}>
                            {props.title}
                            <span>
                                <Icon
                                    styles={{ root: { cursor: "pointer" } }}
                                    onClick={() => {
                                        setHide(!hide)
                                    }}
                                    iconName={
                                        hide
                                            ? "ChromeRestore"
                                            : "ChromeMinimize"
                                    }
                                />
                            </span>
                        </div>
                    ) : (
                        props.title
                    )
                }
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                // Stretch panel content to fill the available height so the footer is positioned
                // at the bottom of the page
                isFooterAtBottom={true}
                focusTrapZoneProps={{}}
                isBlocking={props.isBlocking}
            >
                {props.children}
            </FPanel>
        </div>
    )
}
