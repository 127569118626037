import { Stack, mergeStyleSets, useTheme } from "@fluentui/react"
import { tagFormatter } from "../components/Specifications"
import ImageControl from "../controls/Image"
import config from "../config"
import { SpecificationDescription } from "../components/SpecificationDescription"

function useSpecCardStyle() {
    const theme = useTheme()
    return mergeStyleSets({
        root: { width: 500, height: 400 },
        imageContainer: {
            padding: theme.spacing.l1,
            paddingTop: 0,
            minWidth: 100,
        },
        meta: { width: 300 },
    })
}

export function SpecCard(props) {
    const styles = useSpecCardStyle()
    const spec = props.spec
    const useTag = props.useTag

    return (
        <div className={styles.root}>
            <h3>{tagFormatter(useTag, spec.tagTypeId, spec.tagNumber)}</h3>
            <Stack horizontal>
                <div className={styles.imageContainer}>
                    {spec.images && spec.images[0] && (
                        <img
                            src={config.images.baseUrl + spec.images[0] + "-xs"}
                            alt="spec"
                        />
                    )}
                </div>
                <div className={styles.meta}>
                    <SpecificationDescription
                        value={spec.meta}
                        tagFields={spec.meta.tagTypeFields}
                        tagTypeId={spec.tagTypeId}
                        archive={spec.archive}
                    />
                </div>
            </Stack>
        </div>
    )
}
