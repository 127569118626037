import { getRTL } from "@fluentui/merge-styles/lib/StyleOptionsState"
import {
    FocusZone,
    FocusZoneDirection,
    getFocusStyle,
    getTheme,
    Icon,
    List,
    mergeStyleSets,
    TextField,
} from "@fluentui/react"
import React, { useState, useEffect } from "react"

function useMergedStyles() {
    const theme = getTheme()
    const { palette, semanticColors, fonts } = theme

    return mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: "border-box",
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: "flex",
                selectors: {
                    "&:hover": { background: palette.neutralLight },
                },
            },
        ],
        itemImage: {
            flexShrink: 0,
        },
        itemContent: {
            marginLeft: 10,
            overflow: "hidden",
            flexGrow: 1,
        },
        itemName: [
            fonts.xLarge,
            {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        ],
        itemIndex: {
            fontSize: fonts.small.fontSize,
            color: palette.neutralTertiary,
            marginBottom: 10,
        },
        chevron: {
            alignSelf: "center",
            marginLeft: 10,
            color: palette.neutralTertiary,
            fontSize: fonts.large.fontSize,
            flexShrink: 0,
        },
    })
}
const onRenderCell = (item, index, classNames, itemClick) => {
    return (
        <div
            onClick={
                itemClick
                    ? () => {
                          itemClick(item.clickData)
                      }
                    : null
            }
            className={classNames.itemCell}
            data-is-focusable={true}
        >
            <div className={classNames.itemContent}>
                <div className={classNames.itemName}>{item.name}</div>
                <div>{item.description}</div>
            </div>
            <Icon
                className={classNames.chevron}
                iconName={getRTL() ? "ChevronLeft" : "ChevronRight"}
            />
        </div>
    )
}
export default function ActionList(props) {
    const originalItems = props.items
    const [items, setItems] = useState(originalItems)
    const [text, setText] = useState("")

    const classNames = useMergedStyles()
    const resultCountText =
        items.length === originalItems.length
            ? ""
            : ` (${items.length} of ${originalItems.length} shown)`

    const onFilterChanged = (_, text) => {
        setText(text)
    }

    useEffect(() => {
        setItems(
            originalItems.filter(
                (item) =>
                    item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0 ||
                    item.description
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
            )
        )
    }, [text, originalItems])

    return (
        <FocusZone direction={FocusZoneDirection.vertical}>
            <TextField
                label={"Filter by name" + resultCountText}
                onChange={onFilterChanged}
            />
            <div style={{ height: "calc(100vh - 175px)", overflow: "auto" }}>
                <List
                    items={items}
                    onRenderCell={(item, index) =>
                        onRenderCell(item, index, classNames, props.itemClick)
                    }
                />
            </div>
        </FocusZone>
    )
}
