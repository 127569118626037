import {
    ContextualMenuItemType,
    FocusZone,
    FocusZoneDirection,
    FocusZoneTabbableElements,
    Icon,
    ProgressIndicator,
    Stack,
    TextField,
    useTheme,
} from "@fluentui/react"
import { useRef, useState } from "react"
import { useEntityFromConfig } from "../hooks/Entity"
import { calPercnt } from "../utils"
import { EntityList } from "./Entities"
import { infoConfig } from "./Infos"
import {
    columnImages,
    columnLeadTime,
    columnLocation,
    columnMeta,
    columnNotes,
    columnSupplier,
    columnTagType,
    useConfig,
    formFields,
    SpecificationsForm,
    schedulesFilter,
} from "./Specifications"
import { useStudioConfig } from "./Studios"
import { supplierConfig } from "./Suppliers"
import { usePublicTagTypes, useTagTypeConfig } from "./TagTypes"
import Money from "../controls/Money"
import { project } from "@prospecer/schema"
import MiniList from "../layouts/MiniList"
import SummaryBar from "../layouts/SummaryBar"
import config from "../config"
import {
    InvoiceFormPanel,
    InvoiceList,
    useInvoiceAutoGenerator,
} from "./Invoices"
import Panel from "../layouts/Panel"
import { useNotification } from "../hooks/Notifications"

export function useProcurementConfig(projectId) {
    const useSpecifications = useConfig(projectId)
    useSpecifications.disablePrint = true
    useSpecifications.Form = ProcurementForm
    return useSpecifications
}

function columnRetailPrice(studio) {
    return {
        key: "price",
        title: "Client Price",
        additionalKeys: ["quantity", "id", "tax"],

        formatter: (value, values) => {
            const formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            })

            const taxRate =
                !values[2] && studio?.defaultTax
                    ? studio?.defaultTax
                    : values[2]
            const itemtax = calPercnt(value, taxRate)
            const tax = calPercnt(
                value * (values[0] === null ? 1 : values[0]),
                taxRate
            )
            const columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    maxWidth: 100,
                    isMultiline: true,
                },
                {
                    key: "value",
                    name: "Value",
                    fieldName: "value",
                    isMultiline: true,
                },
            ]
            //Client price, qty, total
            const items = [
                {
                    key: "price",
                    name: "Client Price",
                    value: formatter.format(value / 100),
                },
                {
                    key: "quantity",
                    name: "Quantity",
                    value: values[0],
                },
                {
                    key: "Client Total",
                    name: <b>Sub Total</b>,
                    value: <b>{formatter.format((values[0] * value) / 100)}</b>,
                },
                ...(taxRate
                    ? [
                          {
                              key: "Tax Rate",
                              name: "Tax Rate",
                              value: taxRate + "%",
                          },

                          {
                              key: "Total Tax",
                              name: "Total Tax",
                              value: formatter.format(tax / 100),
                          },
                          {
                              key: "Grand Tax",
                              name: <b>Total</b>,
                              value: (
                                  <b>
                                      {formatter.format(
                                          (tax + values[0] * value) / 100
                                      )}
                                  </b>
                              ),
                          },
                      ]
                    : []),
            ]

            return <MiniList items={items} columns={columns} />
        },
    }
}

function columnProfit(studio, updater) {
    return {
        key: "profit",
        title: "Profit",
        additionalKeys: [
            "price",
            "retailPrice",
            "tradePrice",
            "quantity",
            "id",
        ],

        formatter: (value, values) => {
            const formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            })
            const profit = 0
            const quantity = values[3] ? values[3] : 1

            const columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    maxWidth: 100,
                    isMultiline: true,
                },
                {
                    key: "value",
                    name: "Value",
                    fieldName: "value",
                    isMultiline: true,
                },
            ]
            //Client price, qty, total
            const items = [
                {
                    key: "trade",
                    name: "Trade Price",
                    value: formatter.format(values[2] / 100),
                },
                ...(values[1]
                    ? [
                          {
                              key: "Retail Price",
                              name: "Retail Price",
                              value: formatter.format(values[1] / 100),
                          },
                          {
                              key: "Client Savings",
                              name: "Client Savings",
                              value: formatter.format(
                                  ((values[1] - values[0]) * values[3]) / 100
                              ),
                          },
                      ]
                    : []),
                {
                    key: "Profit",
                    name: <b>Profit</b>,
                    value: (
                        <b>
                            {formatter.format(
                                (values[0] * quantity - values[2] * quantity) /
                                    100
                            )}
                        </b>
                    ),
                },
            ]

            return <MiniList items={items} columns={columns} />
        },
    }
}
function valueUpdatorFunction(useEntity, projectId) {
    return (id, values) => {
        const specs = useEntity.filter([
            { field: "id", type: "eq", value: id },
            { field: "projectId", type: "eq", value: projectId },
        ])
        if (specs[0]) {
            useEntity.dispatch.upsert({ ...specs[0], ...values })
        }
    }
}

function ProcurementForm(props) {
    return <SpecificationsForm {...props} pricingFirst />
}

function ProcurementInfo(props) {
    const procurementConfig = useProcurementConfig(props.projectId)
    const useProcurment = useEntityFromConfig(procurementConfig)

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })

    const specs = useProcurment.filter([
        { field: "projectId", type: "eq", value: props.projectId },
    ])

    let profit = 0
    let price = 0
    let tradePrice = 0

    let retailPrice = 0
    let priceWithRetail = 0
    let savings = 0

    for (const s of specs) {
        profit =
            profit + (s.price * s.quantity - s.tradePrice * s.quantity) / 100
        price = price + (s.price * s.quantity) / 100
        tradePrice = tradePrice + (s.tradePrice * s.quantity) / 100

        if (s.retailPrice) {
            retailPrice = retailPrice + (s.retailPrice * s.quantity) / 100
            priceWithRetail = priceWithRetail + (s.price * s.quantity) / 100

            savings =
                savings +
                (s.retailPrice * s.quantity - s.price * s.quantity) / 100
        }
    }

    //(values[0] * quantity - values[2] * quantity) / 100
    const items = [
        {
            key: "price",
            value: formatter.format(price),
            label: "Client Price",
            descr: `The sum of all client prices times with quantities `,
            suffix: "-",
        },
        {
            key: "tradePrice",
            value: formatter.format(tradePrice),
            label: "Trade Price",
            descr: `The sum of all trade prices times with quantities `,
            suffix: "=",
        },
        {
            key: "profit",
            value: formatter.format(profit),
            label: "Profit",
            descr: `This represents a reflection of the 
                    current workbook where the total of the client 
                    price is subtracted from the trade price`,
        },
        {
            key: "space_1",
            value: " ",
            label: " ",
            descr: ``,
            suffix: " ",
        },
        {
            key: "retailPrice",
            value: formatter.format(retailPrice),
            label: "Retail Price",
            descr: `The sum of all retail prices times with quantities`,
            suffix: "-",
        },
        {
            key: "price_1",
            value: formatter.format(price),
            label: "Client Price",
            descr: `The sum of all client prices for items that have a retail price times with quantities `,
            suffix: "=",
        },
        {
            key: "savingts",
            value: formatter.format(savings),
            label: "Client Savings",
            descr: `The amount of money this client is saving over retail `,
            suffix: "",
        },
    ]
    return <SummaryBar items={items} />
}

function useAdditionalButtons(projectId, selectedItems, setProgress, setModal) {
    const theme = useTheme()
    const procurementConfig = useProcurementConfig(projectId)
    const useProcurement = useEntityFromConfig(procurementConfig)
    const [processing, setProcessing] = useState(false)
    const submitRef = useRef()
    const autoInvoice = useInvoiceAutoGenerator(projectId)
    const autoPos = useInvoiceAutoGenerator(projectId, true)

    async function mark(invoiceStatus, isPurchaseOrder = false) {
        let count = 1
        setProcessing(true)
        let cont = false
        for (const s of selectedItems) {
            if (cont) {
                break
            }
            const id = s._id
            const spec = useProcurement.filter([
                { field: "id", type: "eq", value: id },
                { field: "projectId", type: "eq", value: projectId },
            ])
            if (spec[0]) {
                const sp = spec[0]
                if (isPurchaseOrder) {
                    sp.poStatus = invoiceStatus
                } else {
                    sp.invoiceStatus = invoiceStatus
                }
                setProgress({
                    title: "Marking Specs",
                    description:
                        "(" +
                        count +
                        "/" +
                        selectedItems.length +
                        ") " +
                        sp.name,
                    percentComplete: count / selectedItems.length,
                })
                await useProcurement.dispatch.upsert(sp)
            }
            count++
        }
        setProgress(false)
        setProcessing(false)
    }

    return [
        {
            key: "RootInvoice",
            text: "Invoicing",
            iconProps: { iconName: "M365InvoicingLogo" },

            subMenuProps: {
                items: [
                    {
                        key: "mark",
                        text: "Mark For Invoice",
                        disabled:
                            processing ||
                            (selectedItems ? selectedItems.length === 0 : true),
                        iconProps: {
                            iconName: "CheckboxComposite",
                        },
                        split: true,
                        onClick: () => {
                            mark("pending")
                        },
                    },

                    {
                        key: "unmark",
                        text: "Unmark For Invoice",
                        disabled:
                            processing ||
                            (selectedItems ? selectedItems.length === 0 : true),
                        iconProps: {
                            iconName: "Checkbox",
                            styles: {},
                        },
                        onClick: () => {
                            mark("none")
                        },
                    },

                    {
                        key: "__sep",
                        itemType: ContextualMenuItemType.Divider,
                    },

                    {
                        key: "invoices",
                        text: "Invoices",
                        iconProps: { iconName: "M365InvoicingLogo" },
                        split: true,
                        onClick: () => {
                            setModal(
                                <Panel
                                    onClose={() => {
                                        setModal(null)
                                    }}
                                    large
                                    hideButtons
                                >
                                    <InvoiceList projectId={projectId} />
                                </Panel>
                            )
                        },
                    },

                    {
                        key: "auto_invoice",
                        text: "Create Invoice From Marked",
                        iconProps: {
                            iconName: "WorkItemBarSolid",
                            styles: {},
                        },
                        onClick: async () => {
                            const invoiceId = await autoInvoice()

                            setModal(
                                <InvoiceFormPanel
                                    title={"New Invoice From Marked"}
                                    projectId={projectId}
                                    submitRef={submitRef}
                                    onClose={() => setModal(null)}
                                    invoiceId={invoiceId}
                                />
                            )
                        },
                    },
                ],
            },
        },
        {
            key: "rootPurcahsing",
            text: "Purcahsing",
            iconProps: { iconName: "M365InvoicingLogo" },
            subMenuProps: {
                items: [
                    {
                        key: "mark",
                        text: "Mark For Purcahse Order",
                        disabled:
                            processing ||
                            (selectedItems ? selectedItems.length === 0 : true),
                        iconProps: {
                            iconName: "CheckboxComposite",
                        },
                        split: true,
                        onClick: () => {
                            mark("pending", true)
                        },
                    },
                    {
                        key: "mark",
                        text: "Unmark For Purcahse Order",
                        disabled:
                            processing ||
                            (selectedItems ? selectedItems.length === 0 : true),
                        iconProps: {
                            iconName: "Checkbox",
                        },
                        split: true,
                        onClick: () => {
                            mark("none", true)
                        },
                    },

                    {
                        key: "__sep",
                        itemType: ContextualMenuItemType.Divider,
                    },
                    {
                        key: "purchase_orders",
                        text: "Purchase Orders",
                        iconProps: { iconName: "M365InvoicingLogo" },
                        split: true,
                        onClick: () => {
                            setModal(
                                <Panel
                                    onClose={() => {
                                        setModal(null)
                                    }}
                                    large
                                    hideButtons
                                >
                                    <InvoiceList projectId={projectId} po />
                                </Panel>
                            )
                        },
                    },

                    {
                        key: "auto_pos",
                        text: "Create PO's From Marked",
                        iconProps: {
                            iconName: "WorkItemBarSolid",
                            styles: {},
                        },
                        onClick: async () => {
                            const invoiceId = await autoPos()
                            setModal(
                                <Panel
                                    onClose={() => {
                                        setModal(null)
                                    }}
                                    large
                                    hideButtons
                                >
                                    <InvoiceList projectId={projectId} po />
                                </Panel>
                            )
                        },
                    },
                ],
            },
        },
    ]
}

export function ProcurementList(props) {
    const theme = useTheme()
    const procurementConfig = useProcurementConfig(props.projectId)
    const useSupplier = useEntityFromConfig(supplierConfig)
    const studioConfig = useStudioConfig()
    const filter = []
    const { status } = useNotification()

    function setProgress(obj) {
        if (obj === false) {
            return
        }
        status("ProcurementList-marking-dialog", obj)
    }

    const useStudio = useEntityFromConfig(studioConfig)
    const useInfo = useEntityFromConfig(infoConfig)

    const [selectedItems, setSelectedItems] = useState([])
    const [modal, setModal] = useState(null)

    const additionalButtons = useAdditionalButtons(
        props.projectId,
        selectedItems,
        setProgress,
        setModal
    )

    const useProcurment = useEntityFromConfig(procurementConfig)
    const updater = valueUpdatorFunction(useProcurment, props.projectId)
    const info = useInfo.filter([])

    const studios = info[0]
        ? useStudio.filter([
              { field: "id", type: "eq", value: info[0].studioId },
          ])
        : null

    const [supplierFilter, setSupplierFilter] = useState("")
    const useTag = useEntityFromConfig(useTagTypeConfig())

    procurementConfig.listColumns = [
        columnTagType(useTag),
        {
            key: "name",
            title: "Item",
            maxWidth: 100,
        },
        columnImages(),
        columnRetailPrice(studios && studios[0] ? studios[0] : null),
        columnProfit(studios && studios[0] ? studios[0] : null, updater),
        columnMeta(useTag),

        columnSupplier(useSupplier, supplierFilter, setSupplierFilter),
    ]
    procurementConfig.additionalButtons = [
        ...additionalButtons,
        procurementConfig.additionalButtons.find(
            (e) => e.key === "filterTagType"
        ),
    ]

    if (supplierFilter) {
        procurementConfig.filter.push({
            type: "eq",
            field: "supplierId",
            value: supplierFilter,
        })
    }

    return (
        <>
            {modal && modal}
            <EntityList
                onSelectionChanged={(selection) => {
                    setSelectedItems(selection)
                }}
                noExport
                hideNew
                multiSelection
                config={procurementConfig}
            >
                <ProcurementInfo projectId={props.projectId} />
            </EntityList>
        </>
    )
}
