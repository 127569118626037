import React from "react"
import { DefaultPalette, DefaultEffects, Stack } from "@fluentui/react"

const contentStackStyles = {
    root: {
        boxShadow: DefaultEffects.elevation4,
        backgroundColor: DefaultPalette.white,
        padding: 28,
    },
}

export default function ContentBox(props) {
    return (
        <div style={{ marginBottom: "12px" }}>
            <Stack styles={contentStackStyles}>{props.children}</Stack>
        </div>
    )
}
