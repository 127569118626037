import { Position, SpinButton, TextField } from "@fluentui/react"
import { padNumber as pad } from "../utils"
import { useMemo } from "react"

export default function NumberControl(props) {
    const parse = props.allowFloat ? parseFloat : parseInt
    const oc = (value, e) => {
        props.onChange(e, parse(value ? value : 0))
    }

    const groupKey = props.groupKey
    const groupKeyValue = groupKey ? props.values[groupKey] : false
    let value = props.value

    const v = useMemo(() => {
        if (props.autoincriment && groupKey && props.values.__new) {
            const filter = props.filter ? [...props.filter] : []
            if (groupKey && props.values[groupKey]) {
                filter.push({
                    type: "eq",
                    field: groupKey,
                    value: props.values[groupKey],
                })
            }
            const entities = props.entity.filter(filter)
            const max =
                Math.max.apply(
                    Math,
                    entities.map(function (o) {
                        return o[props.fieldKey]
                    })
                ) + 1
            value = max
            if (isNaN(value) || Infinity === Math.abs(value)) {
                value = 1
            }
            props.onChange(null, value)
        }
    }, [groupKeyValue])

    if (props.readOnly) {
        return (
            <TextField
                key={props.name}
                label={props.label}
                name={props.name}
                value={props.value}
                readOnly={props.readOnly}
                disabled={props.disabled}
            />
        )
    }
    return (
        <SpinButton
            key={props.name}
            label={props.label}
            name={props.name}
            type={props.type}
            value={pad(parse(value ? value : 0), 2)}
            onValidate={oc}
            onIncrement={(value) => {
                props.onChange(null, ++value)
            }}
            onDecrement={(value) => {
                props.onChange(null, --value)
            }}
            errorMessage={props.error ? props.error : false}
            onGetErrorMessage={props.onGetErrorMessage}
            multiline={props.option === "multiline"}
            labelPosition={Position.top}
            min={props.min}
            prefix={props.prefix}
            readOnly={props.readOnly}
            disabled={props.disabled}
        />
    )
}
