import fileDownload from "js-file-download"
import { useEffect } from "react"

export default function Download(props) {
    useEffect(() => {
        if (props.blob) {
            if (props.onBeforeDownload) {
                props.onBeforeDownload()
            }
            fileDownload(props.blob, "ProspecerReport_a.pdf")
            if (props.onAfterDownload) {
                props.onAfterDownload()
            }
        }
    }, [props.blob])

    return null
}
