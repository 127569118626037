import React, { useEffect, useRef } from "react"
import "../css/fluentGlobal.css"
import { BrowserRouter, Route, useParams } from "react-router-dom"
import {
    Stack,
    mergeStyles,
    AnimationClassNames,
    getTheme,
    PivotItem,
    Pivot,
} from "@fluentui/react"
import Header, { PublicHeader } from "./Header"

import useResizer from "../hooks/Resizer"
import PublicSpecifications from "./PublicSpecifications"
import { useAuth0 } from "@auth0/auth0-react"
import { useEntityFromConfig } from "../hooks/Entity"
import { usePublicProjectSharesConfig as useProjectSharesConfig } from "./ProjectShares"
import config from "../config"
import { PublicInvoices } from "./Invoices"
import { usePublicGrowthBookValues } from "../hooks/GrowthBook"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

const mainStackStyles = {
    margin: 0,
    height: "100%",
}

function Share(props) {
    const { projectShareId, studioId } = useParams()
    const ps = useRef([])

    usePublicGrowthBookValues(studioId, projectShareId)
    const invoicingEnabled = useFeatureIsOn("invoicing")

    const projectShares = useEntityFromConfig(
        useProjectSharesConfig(props.studioId, props.projectShareId)
    )

    useEffect(() => {
        ps.current = projectShares.filter([])
    })

    if (
        ps.current[0] &&
        ps.current[0].consultantId === null &&
        config.features.procurement &&
        invoicingEnabled
    ) {
        return (
            <Pivot>
                <PivotItem headerText="Specifications">
                    <PublicSpecifications
                        projectShareId={projectShareId}
                        studioId={studioId}
                    />
                </PivotItem>

                <PivotItem
                    headerText={
                        ps.current[0].supplierId
                            ? "Purchase Orders"
                            : "Invoices"
                    }
                >
                    <PublicInvoices
                        po={!!ps.current[0].supplierId}
                        projectShareId={projectShareId}
                        studioId={studioId}
                    />
                </PivotItem>
            </Pivot>
        )
    } else {
        return (
            <PublicSpecifications
                projectShareId={projectShareId}
                studioId={studioId}
            />
        )
    }
}

export default function PublicMain(props) {
    const size = useResizer()
    const theme = getTheme()
    const { isAuthenticated } = useAuth0()
    const contentClass = mergeStyles([
        {
            color: theme.palette.white,
            lineHeight: "50px",
            bottom: 0,
            position: "fixed",
            right: 0,
            textAlign: "center",
            minWidth: "300px",
            padding: 16,
        },

        AnimationClassNames.fadeIn100,
    ])

    return (
        <Stack style={{ height: "100%", width: "100%" }}>
            <PublicHeader />
            <BrowserRouter>
                <Route path="/share/:studioId/:projectShareId">
                    <Share />
                </Route>
            </BrowserRouter>
        </Stack>
    )
}
