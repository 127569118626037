import React from "react"
import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog"
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button"

export default function ConfirmDialog(props) {
    const dialogStyles = { main: { maxWidth: 450 } }
    const modalProps = {
        isBlocking: false,
        styles: dialogStyles,
        dragOptions: false,
    }

    return (
        <Dialog
            hidden={false}
            onDismiss={props.onDismiss}
            dialogContentProps={props.dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                {!props.noPrimaryButton && (
                    <PrimaryButton
                        onClick={props.onConfirm}
                        text={props.actionText}
                    />
                )}
                <DefaultButton
                    onClick={props.onDismiss}
                    text={props.dismissText}
                />
            </DialogFooter>
        </Dialog>
    )
}
