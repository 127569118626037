import { useState } from "react"

import { TextField } from "@fluentui/react"

export default function Money(props) {
    const [focused, setFocused] = useState(false)
    const [value, setValue] = useState((props.value / 100).toFixed(2))
    //console.log({ value, name: props.name })
    return (
        <TextField
            label={props.label}
            name={props.name}
            type="number"
            value={props.readOnly ? (props.value / 100).toFixed(2) : value}
            onBlur={() => {
                setFocused(false)
                if (!value) {
                    props.onChange(0)
                } else {
                    if (value.indexOf(".") === -1) {
                        props.onChange(value * 100)
                        setValue(value + ".00")
                    } else {
                        props.onChange(value.replace(".", ""))
                    }
                }
            }}
            onFocus={() => {
                setFocused(true)
            }}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            errorMessage={props.errorMessage ? props.errorMessage : false}
            onGetErrorMessage={props.onGetErrorMessage}
            prefix={"$"}
            suffix={props.suffix}
            readOnly={props.readOnly}
            borderless={props.borderless}
            underlined={props.underlined}
            styles={{
                field:
                    value < 0
                        ? {
                              color: "#FF0000",
                          }
                        : {},
            }}
        />
    )
}
