import { useRef } from "react"
import { PublicHeader } from "./Header"
import { SignupStudioForm } from "./Studios"
import useResizer from "../hooks/Resizer"
import {
    Button,
    DefaultButton,
    Icon,
    mergeStyles,
    PrimaryButton,
    Stack,
    Text,
    useTheme,
} from "@fluentui/react"
import config from "../config"
import Logo from "../images/Small-Logo.svg"
import ImpactLogo from "../images/logo-impact.svg"

import { merge, stubTrue } from "lodash"

export function SalesHeader() {
    return (
        <div
            style={{
                padding: "40px",
                paddingLeft: "100px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
            }}
        >
            <img src={Logo} />
            <PrimaryButton
                label="Log in"
                onClick={() => {
                    window.location = config.main.baseUrl
                }}
            >
                Login in &nbsp;
            </PrimaryButton>
        </div>
    )
}

function Sales(props) {
    const theme = useTheme()
    const priceArea = mergeStyles({
        marginBottom: "50px",
        maxWidth: "500px",
        minWidth: "300px",
        textAlign: "center",
        //border: "1px dashed rgb(193, 193, 193)",
        padding: "30px",
        position: "realtive",
        borderRadius: "25px",
        backgroundColor: "white",
        fontSize: "14px",
        zIndex: 999,
    })
    const root = mergeStyles({
        display: "block",
        top: "550px",
        left: "700px",
        marginTop: "0px",
    })
    const listItem = mergeStyles({
        fontSize: "14px",
        display: "block",
        textAlign: "left",
        paddingBottom: "30px",
        zIndex: 9999,
        position: "relative",
    })

    const sellList = mergeStyles({
        textAlign: "left",
        display: "flex",
        marginTop: "40px",
        gap: "15px",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
    })
    const listContainer = mergeStyles({
        position: "realtive",
        borderRadius: "25px",
        //backgroundColor: "#86D3FE",
        background:
            "linear-gradient(45deg, rgb(150, 220, 255) 60%, rgba(181,163,255,0.2) 100%)",

        padding: "30px",
        maxWidth: "500px",
        minWidth: "300px",
    })

    return (
        <div className={root}>
            <div className={priceArea}>
                <Text variant="xxLarge">$50</Text>/user
                <br />
                <div className={sellList}>
                    <span>
                        <Icon iconName="Telemarketer" /> <b>Support included</b>
                    </span>
                    <span>
                        <Icon iconName="Contact" /> <b>Unlimited Clients</b>
                    </span>
                    <span>
                        <Icon iconName="BulletedList" />{" "}
                        <b>Unlimited Projects</b>
                    </span>
                    <span>
                        <Icon iconName="FavoriteStar" />{" "}
                        <b>No setup fees or hidden fees</b>
                    </span>
                    <span>
                        <Icon iconName="Share" /> <b>Unlimited sharing</b>
                    </span>
                </div>
            </div>
            <div className={listContainer}>
                <div class={listItem}>
                    <Icon iconName="SkypeCheck" />
                    <span>
                        <b>Get started quickly</b>
                        <br />
                        Friendly and expert onboarding to get your team and
                        projects online easily.
                    </span>
                </div>
                <div class={listItem}>
                    <Icon iconName="SkypeCheck" />

                    <span class="css-0">
                        <b>Supporting projects and teams</b>
                        <br />
                        Interior Design, architecture, suppliers, contractors,
                        developers, and clients
                    </span>
                </div>
                <div class={listItem}>
                    <Icon iconName="SkypeCheck" />

                    <span class="css-0">
                        <b>Modernize your design business</b>
                        <br />
                        Apply technology to evolve your project process and
                        transform your business.
                    </span>
                </div>
            </div>
        </div>
    )
}
export function Signup(props) {
    const screenSize = useResizer()
    const formRef = useRef()
    const signupArea = mergeStyles({
        margin: "50px",
        maxWidth: "500px",
        minWidth: "300px",
        textAlign: "left",
        border: "1px dashed rgb(193, 193, 193)",
        padding: "30px",
        position: "relative",
        filter: "drop-shadow(rgb(170, 170, 170) 10px 10px 10px)",
        backgroundColor: "white",
    })
    const salesArea = mergeStyles({
        margin: "50px",
    })

    const gradFill = mergeStyles({
        position: "absolute",
        top: "150px",
        left: 0,
        width: "100vw",
        background:
            "linear-gradient(45deg, rgb(150, 220, 255) 60%, rgba(255,255,255,0.8) 100%)",

        height: "1000px",
        minHeight: "100vh",
        clipPath: "polygon(0 400px, 100% 0%, 100% 100%, 0% 100%)",
        zIndex: -1,
    })
    const stackStyle = {
        root: {
            textAlign: "center",
        },
    }
    const impact = mergeStyles({
        position: "fixed",
        top: "380px",
        width: "180px",
        paddingLeft: "50px",
        zIndex: -4,
    })
    return (
        <div style={{ height: "100vh", overflow: "auto", overflowX: "hidden" }}>
            <div className={gradFill} />
            <div className={impact}>
                <img style={{ height: "200px" }} src={ImpactLogo} />
            </div>
            <SalesHeader />

            <h1
                style={{
                    fontSize: "40pt",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Poppins-Bold",
                }}
            >
                Get Started Today
            </h1>
            <div
                style={{
                    paddingBottom: "100px",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Stack styles={stackStyle} horizontal={screenSize !== "small"}>
                    <div className={salesArea}>
                        <Sales />
                    </div>
                    <div className={signupArea}>
                        <h1>Create your Prospecer account</h1>
                        <SignupStudioForm
                            showButtons
                            actionText={"Create account"}
                            submitRef={formRef}
                            onUpsert={async (entity, values) => {
                                if (values) {
                                    if (
                                        await entity.dispatch.apiCalls.insertInsecure(
                                            values
                                        )
                                    ) {
                                        window.location = config.main.baseUrl
                                    }
                                } else {
                                    console.error("Failed upsert ")
                                }
                            }}
                        ></SignupStudioForm>
                    </div>
                    <div>
                        &nbsp;
                        <br />
                        &nbsp;
                    </div>
                </Stack>
            </div>
        </div>
    )
}
