import React, { useState, useEffect, useRef, useMemo } from "react"
import { StateProvider } from "./state/index"
import "./css/fluentGlobal.css"
import { ThemeProvider } from "@fluentui/react/lib/Theme"
import { mergeStyles, getTheme, AnimationClassNames } from "@fluentui/react"

import { initializeIcons } from "@fluentui/font-icons-mdl2"
import AuthMain from "./components/AuthMain"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import PublicMain from "./components/PublicMain"
import { useEntityFromConfig } from "./hooks/Entity"
import { userConfig } from "./components/Users"
import { infoConfig } from "./components/Infos"
import themes from "./themes"
import { Signup } from "./components/Signup"
import { useGrowthBook } from "./hooks/GrowthBook"
import { GrowthBookProvider } from "@growthbook/growthbook-react"
import { prospecerIcons } from "./hooks/ProspecerIcons"

initializeIcons()
prospecerIcons()

function AppWithTheme() {
    const myTheme = {
        palette: {
            ...themes.light,
        },
        defaultFontStyle: { fontFamily: "Poppins-Regular" },
    }

    return (
        <ThemeProvider
            theme={myTheme}
            applyTo="body"
            style={{ height: "100%", width: "100%" }}
        >
            <BrowserRouter>
                <Switch>
                    <Route path="/share">
                        <PublicMain />
                    </Route>
                    <Route path="/signup">
                        <Signup />
                    </Route>
                    <Route>
                        <AuthMain />
                    </Route>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    )
}

function App() {
    const growthbook = useGrowthBook()

    useEffect(() => {
        // Load features asynchronously when the app renders
        growthbook.loadFeatures({ autoRefresh: true })
    }, [])

    return (
        <GrowthBookProvider growthbook={growthbook}>
            <StateProvider>
                <AppWithTheme />
            </StateProvider>
        </GrowthBookProvider>
    )
}

export default App
