import React from "react"

import { taxes as taxesValidator } from "@prospecer/schema"
import config from "../config"

import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { useStudioConfig } from "./Studios"
import { infoConfig } from "./Infos"

export const taxesConfig = {
    baseUrl: config.api.baseUrl + "/taxes",
    type: "TAXES",
    stateKey: "taxes",
    name: "Taxes",
    nameSingle: "Tax",
    readScope: "read:specifications",
    writeScope: "write:billing",
    defaultSortKey: "name",
    listColumns: [
        {
            key: "name",
            title: "Name",
        },
        {
            key: "rate",
            title: "Rate",
        },
        {
            key: "number",
            title: "Number",
        },
    ],
    formFields: [
        {
            label: "Name",
            key: "name",
            type: "text",
        },
        {
            label: "Tax Number/Description",
            key: "number",
            type: "text",
        },
        {
            label: "Rate",
            key: "rate",
            type: "text",
        },
        {
            label: "Recoverable",
            key: "recoverable",
            type: "toggle",
        },
        {
            label: "Compound",
            key: "compound",
            type: "toggle",
        },
    ],
    emptyFormValue: { recoverable: 0, compound: 0 },
    validator: taxesValidator,
    Form: TaxesForm,
    allowDelete: true,
}

export function TaxesList(props) {
    return <EntityList config={taxesConfig} />
}

export function TaxesForm(props) {
    return <EntityForm {...props} config={taxesConfig} />
}

export function useTaxCalculator() {
    const useInfos = useEntityFromConfig(infoConfig)
    const useStudios = useEntityFromConfig(useStudioConfig())
    const useTaxes = useEntityFromConfig(taxesConfig)

    const infos = useInfos.filter([])
    const studioId = infos[0] ? infos[0].studioId : null

    const studios = useStudios.filter([
        { field: "id", type: "eq", value: studioId },
    ])
    const defaultTaxes = studios[0] ? studios[0].defaultTaxIds : []

    return (amount, _taxIds) => {
        const taxIds = _taxIds ? _taxIds : defaultTaxes
        if (taxIds === null) {
            return { total: amount, totalTaxAmount: 0, taxes: [] }
        }
        let total = amount
        let totalTaxAmount = 0
        const results = { taxes: [] }
        for (const t of taxIds) {
            const taxs = useTaxes.filter([
                { field: "id", type: "eq", value: t },
            ])
            if (taxs[0]) {
                const taxAmount = parseFloat(
                    ((taxs[0].rate / 100) * amount).toFixed(2)
                )
                totalTaxAmount = taxAmount + totalTaxAmount
                results.taxes.push({ tax: taxs[0], amount: taxAmount })
                total = total + taxAmount
            }
        }
        results.total = total
        results.totalTaxAmount = totalTaxAmount
        return results
    }
}

export function useTaxControl(key, label, pub = false) {
    const useTax = useEntityFromConfig(taxesConfig)
    const useStudio = useEntityFromConfig(useStudioConfig())
    const useInfo = useEntityFromConfig(infoConfig)

    if (pub) {
        return {
            label: "Tax",
            type: "money",
            key: pub !== true ? pub : "_totalTax",
            supplierHide: true,
            isPricing: true,
        }
    }

    const infos = useInfo.filter([])

    const studios = useStudio.filter([
        { field: "id", type: "eq", value: infos[0]?.studioId },
    ])

    const taxIds = studios[0]?.defaultTaxIds

    const defaultTaxes = useTax.filter([
        { field: "id", type: "any", value: taxIds ? taxIds : [] },
    ])

    return {
        label: label,
        addLabel: "Add Tax",
        key: key,
        type: "array",
        placeholder: defaultTaxes.map((t) => t.name).join(", "),
        emptyFormValue: () => {
            return null
        },

        field: {
            label: "Tax",
            type: "entity",
            options: useTax.filter([]),
            descrKeyPath: "name",
            detailsKeyPath: "number",
            valueKeyPath: "id",
            Form: (props) => {
                return <TaxesForm {...props} config={taxesConfig} />
            },
        },
    }
}
