import { useRef, useState } from "react"

import { TagPicker, useTheme } from "@fluentui/react"
import { useId } from "@fluentui/react-hooks"
import { mergeStyles } from "@fluentui/merge-styles"

export default function Picker(props) {
    const [newValue, setNewValue] = useState("")
    const theme = useTheme()
    const timeoutRef = useRef()
    timeoutRef.current = newValue
    const pickerId = useId("picker")

    const tags = [...props.tags()].map((item) => ({
        key: item.toUpperCase(),
        name: item.toUpperCase(),
    }))

    let selectedItems = []
    for (const i of (props.value ? props.value : "")
        .replace(", and ", ",")
        .replace(" and ", ",")
        .split(",")) {
        if (i.trim()) {
            const si = []

            selectedItems = [
                ...selectedItems,
                {
                    key: i,
                    name: i,
                },
            ]
        }
    }

    selectedItems = selectedItems.filter((c, index) => {
        return selectedItems.findIndex((e) => e.key === c.key) === index
    })

    const listContainsTagList = (tag, tagList) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false
        }
        return tagList.some((compareTag) => compareTag.key === tag.key)
    }
    const pickerSuggestionsProps = {
        suggestionsHeaderText: "Suggested " + props.descr,
        noResultsFoundText: "No " + props.descr + " found",
    }

    const filterSuggestedTags = (filterText, tagList) => {
        const result = newValue
            ? tags.filter((tag) => {
                  return (
                      tag.name.toLowerCase().indexOf(newValue.toLowerCase()) ===
                          0 && !listContainsTagList(tag, tagList)
                  )
              })
            : []
        return result
    }
    const labelStyle = mergeStyles({
        paddingTop: theme.spacing.s2,
        paddingBottom: theme.spacing.s2,
        display: "block",
    })
    const getTextFromItem = (item) => item.name

    const errorClass = mergeStyles({
        color: theme.semanticColors.errorText,
    })
    return (
        <div>
            <b>
                <label className={labelStyle} htmlFor={pickerId}>
                    {props.label}
                </label>
            </b>
            <TagPicker
                label={props.label}
                removeButtonAriaLabel="Remove"
                selectionAriaLabel={"Selected " + props.descr}
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                errorMessage={props.errorMessage}
                searchForMoreText={"Use , to add tag"}
                inputProps={{
                    id: pickerId,
                    value: newValue,

                    onKeyUp: (e) => {
                        if (e.key === "Enter" || e.key === ",") {
                            if (newValue.trim()) {
                                if (props.value) {
                                    props.onChange(
                                        props.value.trim() +
                                            ", " +
                                            newValue.trim()
                                    )
                                } else {
                                    props.onChange(newValue)
                                }
                                setNewValue("")
                            }
                        }
                    },
                    onBlur: () => {
                        setTimeout(() => {
                            if (timeoutRef.current.trim()) {
                                if (props.value) {
                                    props.onChange(
                                        props.value.trim() +
                                            ", " +
                                            newValue.trim()
                                    )
                                } else {
                                    props.onChange(newValue)
                                }
                                setNewValue("")
                            }
                        }, 100)
                    },
                }}
                readOnly={props.readOnly}
                disabled={props.disabled}
                selectedItems={selectedItems}
                onChange={(items) => {
                    props.onChange(
                        items
                            .filter((i) => {
                                return !!i.key.trim()
                            })
                            .map((item) => item.key)
                            .join(", ")
                    )
                }}
                onInputChange={(value) => {
                    setNewValue(value)
                }}
                onItemSelected={(item) => {
                    if (item && item.isNewItem) {
                        props.onChange(
                            props.value.trim() + ", " + item.key.trim()
                        )
                        setNewValue("")

                        return item
                    }
                    setNewValue("")
                    return item
                }}
            />
            {props.errorMessage && (
                <span className={errorClass}>{props.errorMessage}</span>
            )}
        </div>
    )
}
