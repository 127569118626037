import { mergeStyles, TooltipHost, useTheme } from "@fluentui/react"
import { useId } from "@fluentui/react-hooks"

export default function ProPill(props) {
    const color = props.color
    const label = props.label
    const tooltipId = useId("tooltip")
    const theme = useTheme()

    const css = mergeStyles({
        height: "10px",
        width: props.circle ? "10px" : "30px",
        borderRadius: props.circle ? "10px" : "5px",
        border: "1px solid " + theme.palette.black,
        backgroundColor: color,
    })
    return (
        <TooltipHost
            content={label}
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            id={tooltipId}
        >
            {props.children && props.children}
            {!props.children && <div className={css}>&nbsp;</div>}
        </TooltipHost>
    )
}
