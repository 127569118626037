import React, { useState, useEffect, useMemo } from "react"
import "../css/fluentGlobal.css"
import {
    BrowserRouter,
    Route,
    Switch,
    useHistory,
    useParams,
} from "react-router-dom"
import Nav from "./Nav"
import {
    Stack,
    Layer,
    MessageBar,
    MessageBarType,
    mergeStyles,
    AnimationClassNames,
    getTheme,
    useTheme,
    ThemeProvider,
    Spinner,
    SpinnerSize,
} from "@fluentui/react"
import Header from "./Header"
import Main from "./Main"
import Auth0ProdiverProspecer from "./Auth0Provider"
import { v4 as uuidv4 } from "uuid"

import Protected from "./Protected"
import ProjectOpener from "./ProjectOpener"
import useResizer from "../hooks/Resizer"
import { useEntityFromConfig } from "../hooks/Entity"
import themes from "../themes"
import { userConfig } from "./Users"
import { infoConfig } from "./Infos"
import { IssuancesPage } from "./Issuances"
import config from "../config"
import useLiveEntities from "../hooks/LiveEntities"
import { loginsConfig } from "./Logins"
import SpecsMain from "./SpecsMain"
import { NotificationArea, NotificationProvider } from "../hooks/Notifications"
import { useAuthGrowthBookValues } from "../hooks/GrowthBook"

function useStyle() {
    const theme = useTheme()
    const navStyles = {
        root: {
            background: theme.palette.neutralLight,
            color: theme.palette.black,
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            overflow: "auto",
            width: 200,
        },
    }

    const navStylesSmall = { root: { ...navStyles.root, width: 40 } }
    const stackStyles = {
        height: "100%",
    }

    const mainStackStyles = {
        margin: 0,
        height: "100%",
    }
    return { mainStackStyles, navStyles, navStylesSmall, stackStyles }
}

function useUserTheme(props) {
    const useUsers = useEntityFromConfig(userConfig)
    const infos = useEntityFromConfig(infoConfig)

    const info = infos.filter([])

    let users = null
    if (info[0]) {
        users = useUsers.filter([
            { type: "one", field: "id", value: info[0].userId },
        ])
    }
    const user = useMemo(() => (users ? users[0] : []), [users])

    const pp = themes.light
    const ppDark = themes.dark

    const [dark, setDark] = useState("hih")

    useEffect(() => {
        if (user && user.prefs && user.prefs.darkMode === "dark") {
            setDark(true)
        } else if (user && user.prefs && user.prefs.darkMode === "light") {
            setDark(false)
        } else {
            setDark(
                window.matchMedia("(prefers-color-scheme: dark)").matches
                    ? true
                    : false
            )
        }

        const func = (e) => {
            if (user && user.prefs && user.prefs.darkMode === "auto") {
                setDark(e.matches ? true : false)
            }
        }
        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", func)

        return () => {
            window
                .matchMedia("(prefers-color-scheme: dark)")
                .removeEventListener("change", func)
        }
    }, [user])
    const buttonStyles = {
        styles: {
            root: {
                borderRadius: "5px !important",
            },
        },
    }
    const myTheme = {
        palette: {
            ...(dark ? ppDark : pp),
        },
        ...(dark
            ? {
                  semanticColors: {
                      errorText: "#FAE6F5",
                  },
              }
            : {}),
        defaultFontStyle: { fontFamily: "Poppins-Regular" },
        components: {
            DefaultButton: buttonStyles,
            CommandBar: {
                styles: {
                    root: {
                        borderRadius: "8px",
                        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2)",
                        overflow: "hidden",
                        marginLeft: "5px",
                        marginRight: "5px",
                    },
                },
            },
        },
    }
    return myTheme
}

export default function AuthWrappedMain(props) {
    return (
        <Auth0ProdiverProspecer>
            <GrowthBookWrappedAuthMain {...props} />
        </Auth0ProdiverProspecer>
    )
}

function GrowthBookWrappedAuthMain(props) {
    useAuthGrowthBookValues()
    return <EventsWrappedAuthMain {...props} />
}

function EventsWrappedAuthMain(props) {
    useLiveEntities()
    return <ThemeWrappedAuthMain {...props} />
}

function ThemeWrappedAuthMain(props) {
    const userTheme = useUserTheme()

    return (
        <ThemeProvider
            theme={userTheme}
            applyTo="body"
            style={{
                height: "100%",
                width: "100%",
            }}
        >
            <div style={{ backgroundColor: userTheme.palette.neutralLight }}>
                <AuthMain {...props} />
            </div>
        </ThemeProvider>
    )
}

function Issuances(props) {
    const { issuanceId, id } = useParams()
    return <IssuancesPage projectId={id} issuanceId={issuanceId} />
}

function LoginFinish(props) {
    const useLogins = useEntityFromConfig(loginsConfig)
    const useInfos = useEntityFromConfig(infoConfig)
    const infos = useInfos.filter([])
    const history = useHistory()

    useEffect(() => {
        if (infos[0]) {
            const id = uuidv4()
            window.document.session = id
            useLogins.dispatch.upsert({
                id: id,
                userId: infos[0].userId,
                event: "login",
                createdAt: new Date(),
            })
            history.push(config.auth0.afterLoginUrl)
        }
    }, [infos])

    return (
        <>
            <div style={{ height: "200px" }}> </div>
            <Spinner
                label="Starting your session..."
                ariaLive="assertive"
                labelPosition="top"
                size={SpinnerSize.large}
            />
        </>
    )
}

function AuthMain(props) {
    const size = useResizer()
    const theme = getTheme()
    const { navStyles, navStylesSmall, mainStackStyles, stackStyles } =
        useStyle()

    const contentClass = mergeStyles([
        {
            color: theme.palette.white,
            lineHeight: "50px",
            bottom: 0,
            position: "fixed",
            right: 0,
            textAlign: "center",
            minWidth: "300px",
            padding: 16,
        },

        AnimationClassNames.fadeIn100,
    ])

    const messageBarStyles = {
        root: { margin: 5 },
    }
    return (
        <BrowserRouter>
            <NotificationProvider>
                <Stack style={{ height: "100%", width: "100%" }}>
                    <Switch>
                        <Route path="/schedule/:id">
                            <Header fullScreen />
                        </Route>
                        <Route>
                            <Header />
                        </Route>
                    </Switch>
                    <Protected>
                        <Switch>
                            <Route path="/login">
                                <LoginFinish />
                            </Route>
                            <Route path="/open">
                                <ProjectOpener />
                            </Route>
                            <Route path="/schedule/:id/issuance/:issuanceId">
                                <div data-is-scrollable="true">
                                    <Issuances />
                                </div>
                            </Route>
                            <Route path="/schedule/:id/issuance">
                                <div data-is-scrollable="true">
                                    <Issuances />
                                </div>
                            </Route>

                            <Route path="/schedule/:id">
                                <SpecsMain />
                            </Route>

                            <Route>
                                <Stack
                                    disableShrink
                                    horizontal
                                    tokens={{ childrenGap: 5 }}
                                    style={stackStyles}
                                >
                                    <Stack.Item
                                        disableShrink
                                        styles={
                                            size !== "large"
                                                ? navStylesSmall
                                                : navStyles
                                        }
                                        className="no-print"
                                    >
                                        <Nav size={size} />
                                    </Stack.Item>
                                    <Stack.Item
                                        style={mainStackStyles}
                                        grow={1}
                                        className="main-print"
                                    >
                                        <Main />
                                    </Stack.Item>
                                </Stack>
                            </Route>
                        </Switch>
                        <NotificationArea />
                    </Protected>
                </Stack>
            </NotificationProvider>
        </BrowserRouter>
    )
}
