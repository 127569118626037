import React from "react"

import { tagType as tagTypeValadator } from "@prospecer/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"
import { v4 as uuidv4 } from "uuid"
import { useEntityFromConfig } from "../hooks/Entity"
import { infoConfig } from "./Infos"

//let is for a total hack
export let tagTypeTypes = [
    { key: "finish", text: "Finish" },
    { key: "equipment", text: "Equipment" },
    { key: "plumbing", text: "Plumbing" },
    { key: "light", text: "Light" },
    { key: "hardware", text: "Hardware" },
    { key: "furniture", text: "Furniture" },
    { key: "art", text: "Art" },
    { key: "signage", text: "Signage" },
    { key: "accessories", text: "Accessories" },
]

const _tagTypeTypes = [...tagTypeTypes]

const sunBeatStudio = [
    { key: "external_wall_finishes", text: "External Wall Finishes" },
    { key: "windows_glazing", text: "Windows & Glazing" },
    { key: "doors", text: "Doors" },
    { key: "roofing_accessories", text: "Roofing & Accessories" },
    { key: "heating_cooling", text: "Heating & Cooling" },
    { key: "solar", text: "Solar" },
    { key: "pumbing_fixtures", text: "Plumbing Fixtures" },
    { key: "hot_water_heating", text: "Hot Water Heating" },
    { key: "water_tank_filters", text: "Water Tank & Filters" },
    { key: "internal_linings", text: "Internal Linings" },
    { key: "painting", text: "Painting" },
    { key: "floor_finishes", text: "Floor Finishes" },
    { key: "tiles_splashbacks", text: "Tiles & Splashbacks" },
    { key: "joinery_finsishes", text: "Joinery Finishes" },
    { key: "landscaping", text: "Landscaping" },
]
let extraLoaded = false

function useHacks(studioId = null) {
    const useInfo = useEntityFromConfig(infoConfig)

    //* HACK
    const hackStudioId = "58069b93-6f08-42be-a1e6-524d2244c263"
    if (!extraLoaded) {
        if (studioId) {
            if (studioId === hackStudioId) {
                tagTypeTypes = [..._tagTypeTypes, ...sunBeatStudio]
                extraLoaded = true
            }
        } else {
            const infos = useInfo.filter([])
            if (infos[0] && infos[0].studioId === hackStudioId) {
                tagTypeTypes = [..._tagTypeTypes, ...sunBeatStudio]
                extraLoaded = true
            }
        }
    }
}

export function useTagTypeConfig() {
    useHacks()

    return {
        baseUrl: config.api.baseUrl + "/tagtypes",
        type: "TAG_TYPES",
        stateKey: "TAG_TYPES".toLocaleLowerCase(),
        name: "Tag Types",
        nameSingle: "Tag Type",
        readScope: "read:tagtypes",
        writeScope: "write:tagtypes",
        defaultSortKey: "name",
        allowDelete: true,
        listColumns: [
            {
                key: "name",
                title: "Tag Prefix",
            },
            {
                key: "description",
                title: "Description",
            },
            {
                key: "type",
                title: "Schedule Type",
                formatter: (f) => {
                    const entry = tagTypeTypes.find((e) => e.key === f)

                    if (entry) {
                        return entry.text
                    } else {
                        return f[0].toUpperCase() + f.slice(1).toLowerCase()
                    }
                },
            },
            {
                key: "fields",
                title: "Fields",
                formatter: (f) => {
                    if (!f) {
                        return ""
                    }
                    const values = f.map((e) => e.name)
                    return values.join(", ")
                },
            },
        ],
        formFields: [
            {
                label: "Tag Prefix",
                key: "name",
                type: "text",
                maxLength: 5,
            },
            {
                label: "Description",
                key: "description",
                type: "text",
            },
            {
                label: "Schedule Type",
                key: "type",
                type: "dropdown",
                options: tagTypeTypes,
            },
            {
                label: "Fields",
                addLabel: "Add Field",
                key: "fields",
                type: "array",
                emptyFormValue: () => {
                    return { key: uuidv4(), type: "string" }
                },
                fields: [
                    {
                        label: "Name",
                        key: "name",
                        type: "text",
                    },
                ],
            },
        ],
        emptyFormValue: { fields: [] },
        validator: tagTypeValadator,
        Form: TagTypeForm,
    }
}
export function usePublicTagTypes(studioId, projectSharesId) {
    const tagTypeConfig = useTagTypeConfig()
    useHacks(studioId)

    return {
        baseUrl:
            config.api.baseUrl +
            "/public/tagtypes?id=" +
            projectSharesId +
            "&studioId=" +
            studioId,
        type: "TAG_TYPES",
        stateKey: "TAG_TYPES".toLocaleLowerCase(),
        name: "Tag Types",
        nameSingle: "Tag Type",
        readScope: "read:tagtypes",
        writeScope: "write:tagtypes",
        defaultSortKey: "name",
        insecure: true,
        allowDelete: false,
        listColumns: tagTypeConfig.listColumns,
    }
}

export function TagTypesList(props) {
    const tagTypeConfig = useTagTypeConfig()
    return <EntityList config={tagTypeConfig} />
}

export function TagTypeForm(props) {
    const tagTypeConfig = useTagTypeConfig()

    return <EntityForm {...props} config={tagTypeConfig} />
}
