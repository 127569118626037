import { useLiveEvents } from "@prospecer/ffsds/src/fe/entity"
import { consultantsConfig } from "../components/Consultants"
import { infoConfig } from "../components/Infos"
import { loginsConfig } from "../components/Logins"
import { useConfig as useSpecificationConfig } from "../components/Specifications"
import { supplierConfig } from "../components/Suppliers"
import config from "../config"
import { useGlobalState } from "../state"
import { useEntityFromConfig } from "./Entity"
import { v4 as uuidv4 } from "uuid"
import { useEffect, useState } from "react"
import { useInvoiceLineItmesConfig } from "../components/InvoiceLineItems"
import { useInvoiceConfig } from "../components/Invoices"

function liveHelper(event, entity, config, dispatch, doDelete = true) {
    if (["update", "insert"].includes(event.type)) {
        entity.dispatch.refreshOne(event.entityId)
    } else if (event.type === "remove" && doDelete) {
        dispatch({
            type: config.type,
            action: "DELETE",
            payload: { id: event.entityId },
        })
    }
}
function useAutoLogout(eventId, userId) {
    const useLogins = useEntityFromConfig(loginsConfig)
    const logins = useLogins.filter([
        { field: "id", type: "eq", value: eventId },
    ])
    const login = logins[0] ? logins[0] : null
    useEffect(() => {
        if (
            login &&
            eventId !== window.document.session &&
            login.event === "login" &&
            login.userId === userId
        ) {
            useLogins.dispatch.upsert({
                id: uuidv4(),
                userId: userId,
                event: "pushedout",
                createdAt: new Date(),
            })
        }
    }, [eventId, userId, login])
}

export default function useLiveEntities(props) {
    const state = useGlobalState()
    const useSupplier = useEntityFromConfig(supplierConfig)
    const useConsultants = useEntityFromConfig(consultantsConfig)
    const useInfos = useEntityFromConfig(infoConfig)
    const infos = useInfos.filter([])
    const info = infos[0] ? infos[0] : null
    const useLogins = useEntityFromConfig(loginsConfig)
    const invoiceLintItemConfig = useInvoiceLineItmesConfig()
    const useInvoiceLineItem = useEntityFromConfig(invoiceLintItemConfig)
    const invoiceConfig = useInvoiceConfig()
    const useInvoice = useEntityFromConfig(invoiceConfig)

    const specificationsConfig = useSpecificationConfig(0)
    const useSpecifications = useEntityFromConfig(specificationsConfig)

    const [userEventId, setUserEventId] = useState(null)

    useAutoLogout(userEventId, info ? info.userId : null)

    function loginEvent(event) {
        setUserEventId(event.entityId)
    }

    function processEvent(event) {
        if (event.entityName === "supplier") {
            liveHelper(event, useSupplier, supplierConfig, state.dispatch)
        }
        if (event.entityName === "consultant") {
            liveHelper(event, useConsultants, consultantsConfig, state.dispatch)
        }
        if (event.entityName === "specification") {
            liveHelper(
                event,
                useSpecifications,
                specificationsConfig,
                state.dispatch,
                false
            )
        }
        if (event.entityName === "login") {
            liveHelper(event, useLogins, loginsConfig, state.dispatch, false)
            loginEvent(event)
        }

        if (event.entityName === "invoiceLineItem") {
            liveHelper(
                event,
                useInvoiceLineItem,
                invoiceLintItemConfig,
                state.dispatch,
                false
            )
        }
        if (event.entityName === "invoice") {
            liveHelper(event, useInvoice, invoiceConfig, state.dispatch, false)
        }
    }
    useLiveEvents(config.auth0, config, processEvent)
}
