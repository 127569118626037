import React from "react"

import { ClientsList } from "./Clients"
import { UsersList } from "./Users"
import { SuppliersList } from "./Suppliers"
import { TagTypesList } from "./TagTypes"
import { ProjectsList } from "./Projects"

import { Route, Switch } from "react-router"
import { Text, useTheme } from "@fluentui/react"
import { DefaultPalette } from "@fluentui/react"
import useResizer from "../hooks/Resizer"
import { ConsultantList } from "./Consultants"
import { StudioList } from "./Studios"
import { TaxesList } from "./Taxes"

function useStyle() {
    const theme = useTheme()

    const main = {
        overflowY: "auto",
        height: "100%",
        paddingBottom: "0",
        // backgroundColor: theme.palette.white,
    }
    const large = {
        ...main,
        padding: "0",
        margin: "0",
        width: "calc(100vw - 200px)",
    }

    const mainSmall = {
        ...main,
        width: "calc(100vw - 40px )",
        paddingLeft: "0px",
    }

    return { mainStyle: large, mainSmallStyle: mainSmall }
}

export default function Main(props) {
    const size = useResizer()

    const { mainStyle, mainSmallStyle } = useStyle()
    return (
        <main style={size !== "large" ? mainSmallStyle : mainStyle}>
            <Switch>
                <Route path="/clients">
                    <div className="content-container">
                        <ClientsList />
                    </div>
                </Route>
                <Route path="/users">
                    <div className="content-container">
                        <UsersList />
                    </div>
                </Route>
                <Route path="/suppliers">
                    <div className="content-container">
                        <SuppliersList />
                    </div>
                </Route>
                <Route path="/tagtypes">
                    <div className="content-container">
                        <TagTypesList />
                    </div>
                </Route>
                <Route path="/projects">
                    <div className="content-container">
                        <ProjectsList />
                    </div>
                </Route>
                <Route path="/consultants">
                    <div className="content-container">
                        <ConsultantList />
                    </div>
                </Route>
                <Route path="/studios">
                    <div className="content-container">
                        <StudioList />
                    </div>
                </Route>
                <Route path="/taxes">
                    <div className="content-container">
                        <TaxesList />
                    </div>
                </Route>
                <Route path="/">
                    <ClientsList />
                </Route>
            </Switch>
        </main>
    )
}
