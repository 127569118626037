import React from "react"
import {
    Position,
    Separator,
    SpinButton,
    Stack,
    Text,
    TextField,
} from "@fluentui/react"
import { useTagTypeConfig } from "../components/TagTypes"
import { useEntityFromConfig } from "../hooks/Entity"
import { cloneDeep } from "lodash"

export default function Meta(props) {
    const tagTypeId = props.coupledValue
    const useTag = useEntityFromConfig(useTagTypeConfig())
    if (!tagTypeId) {
        return null
    }

    const tag = useTag.filter([
        { field: "id", type: "one", value: tagTypeId },
    ])[0]

    const formFields = []
    for (const f of tag.fields) {
        const onChange = (e, v) => {
            const value = v !== null ? v : e.target.value
            const obj = cloneDeep(props.value)
            obj.tagTypeFields[f.key] = value
            props.onChange(e, obj)
        }
        if (f.type === "number" && !props.readOnly) {
            formFields.push(
                <SpinButton
                    key={f.key}
                    label={f.name}
                    name={f.key}
                    value={props.value.tagTypeFields[f.key]}
                    labelPosition={Position.top}
                    onDecrement={(value) => {
                        onChange(null, --value)
                    }}
                    onIncrement={(value) => {
                        onChange(null, ++value)
                    }}
                    onValidate={(value) => {
                        onChange(null, value)
                    }}
                    errorMessage={props.error ? props.error : false}
                    onGetErrorMessage={props.onGetErrorMessage}
                />
            )
        } else {
            formFields.push(
                <TextField
                    key={f.key}
                    label={f.name}
                    name={f.key}
                    value={props.value.tagTypeFields[f.key]}
                    onChange={onChange}
                    errorMessage={props.error ? props.error : false}
                    onGetErrorMessage={props.onGetErrorMessage}
                />
            )
        }
    }

    return (
        <Stack>
            <Separator>Description</Separator>
            {formFields}
            <Separator></Separator>
        </Stack>
    )
}
