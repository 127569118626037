import { GrowthBook } from "@growthbook/growthbook-react"
import config from "../config"
import { useDataDogRum } from "react-datadog"
import { useEntityFromConfig } from "./Entity"
import { infoConfig } from "../components/Infos"
import { useEffect } from "react"

let growthbook = null

export function useGrowthBook() {
    const dataDog = useDataDogRum()
    if (growthbook === null) {
        growthbook = new GrowthBook({
            apiHost: config.growthBook.apiHost,
            clientKey: config.growthBook.clientKey,
            enableDevMode: config.growthBook.devMode,
            trackingCallback: (experiment, result) => {
                dataDog.addAction("Viewed Experiment", {
                    experimentId: experiment.key,
                    variationId: result.key,
                })
            },
        })
        growthbook.loadFeatures({
            autoRefresh: true,
            timeout: 2000,
        })
    }

    return growthbook
}

export function useAuthGrowthBookValues() {
    const useInfo = useEntityFromConfig(infoConfig)
    const gb = useGrowthBook()
    const infos = useInfo.filter([])

    useEffect(() => {
        const info = infos[0] ? infos[0] : {}
        gb.setAttributes({
            userId: info.id,
            deviceId: "web",
            studioId: info.studioId,
            loggedIn: true,
            designer: true,
        })
        // gb.refreshFeatures()
    }, [infos])
}

export function usePublicGrowthBookValues(studioId, projectShareId) {
    const gb = useGrowthBook()

    useEffect(() => {
        gb.setAttributes({
            userId: null,
            deviceId: "web",
            studioId: studioId,
            loggedIn: false,
            designer: false,
        })
        //gb.refreshFeatures()
    }, [studioId])
}
