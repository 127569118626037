import { useEntityFromConfig } from "../hooks/Entity"
import { SpecificationDescription } from "./SpecificationDescription"
import {
    tagFormatter,
    useConfig as useSpecificationConfig,
} from "./Specifications"
import { useTagTypeConfig } from "./TagTypes"
import { useInvoiceConfig } from "./Invoices"
import { SpecCard } from "../layouts/SpecCard"
import { useState } from "react"
import { Callout, DirectionalHint, mergeStyleSets } from "@fluentui/react"

export function SpecificationCard(props) {
    const id = props.id
    let projectId = props.projectId
    const useSpecification = useEntityFromConfig(useSpecificationConfig())
    const useTag = useEntityFromConfig(useTagTypeConfig())
    const useInvoice = useEntityFromConfig(useInvoiceConfig())
    const [showCallout, setShowCallout] = useState(false)

    const styles = mergeStyleSets({
        callout: {
            width: 540,
            height: 300,
            padding: "20px 24px",
        },
        card: {
            width: 540,
        },
    })
    const specs = useSpecification.filter([
        { field: "id", type: "eq", value: id },
        { field: "projectId", type: "eq", value: projectId },
    ])
    if (specs[0]) {
        const spec = specs[0]

        return (
            <div>
                <div
                    id={`spec${spec.id}`}
                    style={{ textDecoration: "underline" }}
                    onMouseOver={() => {
                        setShowCallout(true)
                    }}
                    onMouseOut={() => {
                        setShowCallout(false)
                    }}
                >
                    {tagFormatter(useTag, spec.tagTypeId, spec.tagNumber)}
                </div>
                {showCallout && (
                    <Callout
                        className={styles.callout}
                        role="dialog"
                        gapSpace={0}
                        target={`#spec${spec.id}`}
                        onDismiss={() => {
                            setShowCallout(false)
                        }}
                        setInitialFocus
                        alignTargetEdge
                        directionalHint={DirectionalHint.topRightEdge}
                    >
                        <div className={styles.card}>
                            <SpecCard spec={spec} useTag={useTag} />
                        </div>
                    </Callout>
                )}
            </div>
        )
    } else {
        return null
    }
}
