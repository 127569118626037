import { Toggle as RToggle } from "@fluentui/react"

export default function Toggle(props) {
    let disabled = props.disabled

    if (props.coupledValue === 1) {
        disabled = true
    }
    return (
        <RToggle
            label={props.label}
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            errorMessage={props.error ? props.error : false}
            onGetErrorMessage={props.onGetErrorMessage}
            prefix={props.prefix}
            onText={props.onText}
            offText={props.offText}
            readOnly={props.readOnly}
            disabled={disabled}
        />
    )
}
