import { cloneDeep } from "lodash"
import { useEffect, useCallback, useReducer } from "react"

/* <PasteContainer
  onPaste={files => files.length > 0 && this.props.onFileAccepted(files[0], files[0])}
/> */

// NOTE: `onPaste` should return an array of files that were acceptedFiles
//
// const Image = ({ src }) => <img src={image} alt={`Pasted: ${image}`} />
//
// <PasteContainer
//    onPaste={files => this.method(files)}
//    onError={err => console.error(err)}
// >
//   {({images}) => images.map((image, i) => <Image src={image} key={i} />)}
// </PasteContainer>

const DEFAULT_ACCEPTED_FILES = [
    "image/gif",
    "image/png",
    "image/jpeg",
    "image/bmp",
]
export const GlueActions = {
    SET_FILE: "SET_FILES",
    SET_ERROR: "SET_ERROR",
}

const _transformImageDataToURL = (
    data,
    acceptedFiles = DEFAULT_ACCEPTED_FILES,
    dispatch
) => {
    const isValidFormat = (fileType) => acceptedFiles.includes(fileType)
    // NOTE: This needs to be a for loop, it's a list like object
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].type === "text/plain") {
            data.items[i].getAsString((s) => {
                if (s.startsWith("http")) {
                    dispatch({ type: GlueActions.SET_FILE, s })
                }
            })
        }
        if (!isValidFormat(data.items[i].type)) {
            dispatch({
                type: GlueActions.SET_ERROR,
                error: `Sorry, that's not a format we support ${data.items[i].type}`,
            })
        }
        let blob = data.items[i].getAsFile()

        if (blob) {
            // We shouldn't fire the callback if we can't create `new Blob()`
            let file = window.URL.createObjectURL(blob)
            dispatch({ type: GlueActions.SET_FILE, file })
            //return file
        }
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case GlueActions.SET_FILE:
            return {
                ...state,
                pasted: [action.file, ...state.pasted],
                error: null,
            }
        case GlueActions.SET_ERROR:
            return { ...state, error: action.error }
        default:
            throw new Error("Must specify action type")
    }
}

const useGlueJarReducer = () =>
    useReducer(reducer, {
        pasted: [],
        error: null,
    })

export function usePasteHandler(acceptedFiles = DEFAULT_ACCEPTED_FILES) {
    const [state, dispatch] = useGlueJarReducer()

    const transformImageDataToURL = useCallback(
        (data) => {
            try {
                _transformImageDataToURL(data, acceptedFiles, dispatch)
            } catch (error) {
                dispatch({ type: GlueActions.SET_ERROR, error: error.message })
            }
        },
        [dispatch, acceptedFiles]
    )

    const pasteHandler = useCallback(
        ({ clipboardData }) => {
            if (clipboardData && clipboardData.items.length > 0) {
                transformImageDataToURL(clipboardData)
            } else {
                dispatch({
                    type: GlueActions.SET_ERROR,
                    error: `Sorry, to bother you but there was no image pasted.`,
                })
            }
        },
        [dispatch, transformImageDataToURL]
    )

    return [state, pasteHandler]
}

/**
 * useGlueJar
 * if you don't pass a `ref` to the options it will default to use the document
 * to add an event listener
 * @param options
 * @returns `IGlueJarState`
 */
export function useGlueJar(
    { ref, ...options } = {
        acceptedFiles: DEFAULT_ACCEPTED_FILES,
    }
) {
    const [state, pasteHandler] = usePasteHandler(options.acceptedFiles)

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.addEventListener("paste", pasteHandler)
        } else {
            document.addEventListener("paste", pasteHandler)
        }

        return () => {
            if (ref && ref.current) {
                ref.current.removeEventListener("paste", pasteHandler)
            } else {
                document.removeEventListener("paste", pasteHandler)
            }
        }
    }, [ref, options])

    return state
}
