import enviroment from "./env.js"

const config = {
    main: {
        baseUrl: "https://app.prospecer.com",
    },
    api: {
        baseUrl: enviroment.BASE_URL || "http://localhost:1234",
    },
    auth0: {
        domain: enviroment.AUTH0_DOMAIN || "login.prospecer.com",
        clientId:
            enviroment.AUTH0_CLIENT_ID || "Mt6ObNuc238TsoanijCfL21K2M9lhoR7",
        redirectUri: enviroment.AUTH0_URI || "http://localhost:3000/login",
        afterLoginUrl: enviroment.AUTH0_URI || "/",
        audience: enviroment.AUTH0_AUDIENCE || "https://prospecer.com/",
        scopes: [
            "write:billing read:billing read:clients write:clients read:users write:users read:suppliers write:suppliers read:tagtypes write:tagtypes read:projects write:projects read:specifications write:specifications read:studios write:studios write:issuances read:issuances write:projectshares read:projectshares write:consultants read:consultants",
        ],
    },
    images: {
        baseUrl:
            enviroment.IMAGES_BASEURL ||
            "https://prospecer-dev.nyc3.digitaloceanspaces.com/",
    },
    dataDog: {
        enabled: enviroment.DD_ON || 0,
        applicationId:
            enviroment.DD_APPLICATION_ID ||
            "7d7173dd-4b10-491c-b961-87f44577e0c6",
        clientToken:
            enviroment.DD_CLIENT_TOKEN || "pubb41f7c4680e2172536a2f8a70f82a680",
        service: enviroment.DD_CLIENT_TOKEN || "prospecer",
        sessionReplayRecording: true,
    },
    nonThemeColors: {
        red: "#FAE6F5",
        yellow: "#F5D79B",
        green: "#C3B4E1",
        black: "#3C3C3C",
    },
    pastelAlternatives: {
        red: "#F69C9E",
        green: "#97E3C2",
        blue: "#99C3EC",
        orange: "#FAC898",
        purple: "#C3B1E1",
        grey: "#cfcfc4",
    },
    contacts: {
        suportEmail: "will@prospecer.com",
    },
    reporting: {
        enabled: enviroment.REPORTING || false,
        url: enviroment.REPORTING_URL || "http://localhost:1235",
    },
    features: {
        fullPageSpec: true,
        procurement: true,
    },

    growthBook: {
        endPoint: "https://cdn.growthbook.io/api/features/sdk-sg2zyi9TkGOd8b",
        apiHost: "https://cdn.growthbook.io",
        clientKey: enviroment.PROD
            ? "sdk-sg2zyi9TkGOd8b"
            : "sdk-1hK5sQYLNneawdd",
        devMode: enviroment.PROD ? false : true,
    },
}

export default config
