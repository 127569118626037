import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { v4 as uuidv4 } from "uuid"
import config from "../config"
import { useCalls } from "@prospecer/ffsds/src/fe/entity"
import { useFileConfig, useImageConfig } from "../components/Images"
import {
    Label,
    useTheme,
    Image,
    ImageFit,
    Stack,
    IconButton,
    Icon,
    mergeStyles,
    TextField,
} from "@fluentui/react"
import Modal from "../layouts/Modal"
import { useGlobalState } from "../state"
import { useEntityFromConfig } from "../hooks/Entity"
import { useGlueJar } from "../hooks/GlueJar"
import types from "react-datadog"

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: "5 5px",
    cursor: "pointer",
})

const iconSmallClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: "5 5px",
    cursor: "pointer",
})

export function Images(props) {
    const imagesRepoOld = useEntityFromConfig(useImageConfig())
    const imagesRepoFiles = useEntityFromConfig(useFileConfig())
    const imagesRepo = props.anyFiles ? imagesRepoFiles : imagesRepoOld
    const value = props.value
    const images = []
    const [modal, setModal] = useState([])
    const [edit, setEdit] = useState(false)
    const [editName, setEditName] = useState("")
    const state = useGlobalState()

    if (!value) {
        return null
    }
    for (const i of value) {
        const imagesData = props.nameNotNeeded
            ? []
            : imagesRepo.filter([{ type: "eq", field: "id", value: i }])

        const name = imagesData[0] ? imagesData[0].name : ""

        images.push(
            <Stack horizontal key={i}>
                {!props.anyFiles && (
                    <Image
                        key={i}
                        imageFit={ImageFit.centerContain}
                        width={props.size ? props.size : 100}
                        height={props.size ? props.size : 100}
                        src={config.images.baseUrl + i + "-xs"}
                        onClick={(e) => {
                            if (props.noModal) {
                                return
                            }
                            e.stopPropagation()
                            setModal(
                                <Modal
                                    onClose={() => {
                                        setModal(false)
                                    }}
                                >
                                    <Image
                                        key={i}
                                        width={"49vw"}
                                        height={"70vh"}
                                        imageFit={ImageFit.contain}
                                        src={
                                            config.images.baseUrl +
                                            i +
                                            "-original"
                                        }
                                    />
                                </Modal>
                            )
                            return false
                        }}
                    />
                )}
                {props.anyFiles && (
                    <>
                        <Icon
                            className={iconSmallClass}
                            iconName="DownloadDocument"
                            onClick={() => {
                                window.open(config.images.baseUrl + i)
                            }}
                            size="small"
                        />
                        {edit !== i && (
                            <span
                                onClick={() => {
                                    if (edit !== i && !props.readOnly) {
                                        setEdit(i)
                                        setEditName(name)
                                    }
                                }}
                            >
                                {name}
                            </span>
                        )}

                        {edit === i && (
                            <TextField
                                value={editName}
                                onChange={(e, value) => {
                                    setEditName(value)
                                }}
                                onBlur={() => {
                                    setEdit(false)
                                }}
                                onKeyDown={(ev) => {
                                    if (ev.key === "Enter") {
                                        const imgNew = imagesRepo.filter([
                                            {
                                                type: "one",
                                                field: "id",
                                                value: i,
                                            },
                                        ])[0]
                                        imgNew.name = editName
                                        imagesRepo.dispatch.upsert(imgNew)
                                        setEdit(false)
                                    }
                                }}
                            />
                        )}
                    </>
                )}
                {props.allowDelete && !props.readOnly && (
                    <Stack.Item align="center">
                        <IconButton
                            iconProps={{ iconName: "Delete" }}
                            title="Delete"
                            ariaLabel="Delete"
                            onClick={() => {
                                props.onDelete(i)
                            }}
                        />
                    </Stack.Item>
                )}
                {modal}
            </Stack>
        )
    }
    return <>{images}</>
}

//This is NOT how I invisioned controls working.
// THIS IS FAR to coupled with the backend.
// Hope it's a one off
export default function ImageControl(props) {
    const imagesRepo = useEntityFromConfig(useImageConfig())
    const [showInput, setShowInput] = useState(true)

    const { pasted, pastedError } = useGlueJar()

    useEffect(() => {
        if (props.onlyOne && props.value && props.value.length >= 1) {
            setShowInput(false)
        } else {
            setShowInput(true)
        }
    }, [props.onlyOne, props.value])

    //Low level FFSDS
    const imgCalls = useCalls(config, useImageConfig())
    const fileCalls = useCalls(config, useFileConfig())
    const calls = props.anyFiles ? fileCalls : imgCalls
    const [uploadCount, setUploadCount] = useState(0)
    const theme = useTheme()
    const value = props.value
    const onChange = props.onChange
    const acceptedTypes = ["image/png", "image/gif", "image/jpeg"]
    const onDrop = useCallback(
        async (acceptedFiles) => {
            acceptedFiles.forEach(async (file) => {
                //Really hacky that this is done here! It's terribe
                //This should be moved into the entity somehow
                const form = new FormData()
                const uuid = uuidv4()
                form.set("id", uuid)
                form.set("location", uuid)
                form.set("name", file.name)
                form.set("image", file, uuid)
                setUploadCount(uploadCount + 1)
                await calls.insert(form)
                setUploadCount(uploadCount - 1)
                if (props.onlyOne) {
                    onChange(uuid)
                } else {
                    onChange(value ? value.concat([uuid]) : [uuid])
                }
                imagesRepo.dispatch.refresh([
                    { field: "id", type: "eq", value: uuid },
                ])
            })
        },
        [value, calls, uploadCount, onChange]
    )
    const { getRootProps, getInputProps, isDragActive, isDragReject } =
        useDropzone({
            onDrop,
            accept: props.anyFiles ? null : acceptedTypes.join(", "),
        })

    useEffect(() => {
        if (props.pastable) {
            async function run() {
                if (pasted.length > 0) {
                    let file = await fetch(pasted[0])
                        .then((r) => r.blob())
                        .then((blobFile) => {
                            if (acceptedTypes.includes(blobFile.type)) {
                                return new File([blobFile], "PASTED IMAGE", {
                                    type: blobFile.type,
                                })
                            }
                        })
                    if (file) {
                        onDrop([file])
                    }
                }
            }
            run()
        }
    }, [pasted.length])

    return (
        <div>
            <Label>{props.label}</Label>
            <Images
                readOnly={props.readOnly}
                anyFiles={props.anyFiles}
                value={
                    props.onlyOne
                        ? props.value
                            ? [props.value]
                            : []
                        : props.value
                }
                allowDelete={props.allowDelete}
                onDelete={(item) => {
                    if (props.onlyOne) {
                        props.onChange(null)
                    } else {
                        const i = props.value.indexOf(item)
                        if (i > -1) {
                            props.value.splice(i, 1)
                        }
                        props.onChange(props.value)
                    }
                }}
            />
            {!props.readOnly && showInput && (
                <div
                    style={{
                        backgroundColor: theme.palette.white,
                        color: theme.palette.black,
                        padding: theme.spacing.s1,
                        height: "75px",
                        textAlign: "center",
                        border: "1px solid",
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    {uploadCount > 0 && <p>Uploading ({uploadCount})</p>}

                    {isDragReject && <p>File type not accepted, sorry!</p>}
                    {!isDragReject &&
                        (isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <p>
                                Drag 'n' drop some files here,{" "}
                                {props.pastable ? "paste images, " : ""}or click
                                to select files
                            </p>
                        ))}
                </div>
            )}
        </div>
    )
}
