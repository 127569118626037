import * as yup from "yup"
import YupPassword from "yup-password"

YupPassword(yup)

const uuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

function createContact(noName = false, strictRequired = false) {
    return yup.object().shape({
        name: noName
            ? yup.string().ensure()
            : yup.string().required("name is required"),
        address: yup.string().ensure(),
        postal: yup.string().ensure(),
        city: yup.string().ensure(),
        state: strictRequired ? yup.string().required() : yup.string().ensure(),
        email: strictRequired
            ? yup.string().email().required()
            : yup.string().email().ensure(),
        phone: yup
            .string()
            .ensure()
            .matches(/^((?!_).)*$/, {
                message: "phone must be complete and not contain _",
                excludeEmptyString: true,
            }),
        mobile: yup
            .string()
            .ensure()
            .matches(/^((?!_).)*$/, {
                message: "phone must be complete and not contain _",
                excludeEmptyString: true,
            }),
        country: strictRequired
            ? yup.string().required()
            : yup.string().ensure(),
    })
}
const contact = createContact()

const contactEmailRequired = createContact()
contactEmailRequired.email = yup.string().email().required()

const contactNoRequired = createContact(true)

yup.addMethod(yup.number, "nullEmptyString", function () {
    return this.transform((val) => (val === Number(val) ? val : null))
})

export const info = yup.object().shape({
    userId: yup.string().matches(uuid, "id must be a valid uuid").required(),
    studioId: yup.string().matches(uuid, "id must be a valid uuid").required(),
})

export const client = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    contact: contact,
    secondaryContact: contactNoRequired,
})

export const supplier = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    web: yup.string().ensure(),
    notes: yup.string().ensure(),
    discount: yup.number().nullable(),
    contact: contact,
    secondaryContact: contactNoRequired,
})

export const studio = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    contact: contactEmailRequired,
    password: yup
        .string()
        .nullable()
        .password()
        .minSymbols(0)
        .minUppercase(0)
        .minNumber(0), ///Only used on initial studio creation
    image: yup.string().matches(uuid, "image must be a valid uuid").nullable(),
    color: yup.string().nullable(),
    cloneFrom: yup.string().nullable(),
    paymentMethod: yup.string().nullable(),
    defaultTax: yup
        .array()
        .of(yup.string().matches(uuid, "id must be a valid uuid"))
        .ensure(),
})

export const signup = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    contact: createContact(false, true),
    password: yup.string().password().required(), ///Only used on initial studio creation
    image: yup.string().matches(uuid, "image must be a valid uuid").nullable(),
    color: yup.string().nullable(),
    cloneFrom: yup.string().nullable(),
    paymentMethod: yup.string().nullable(),
})

export const user = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    password: yup.string().nullable().password(), ///Only every something creation or password change
    contact: contactEmailRequired,
    enabled: yup.number().min(0).max(1),
    role: yup.string().ensure().oneOf(["owner", "designer"]),
    prefs: yup
        .object({
            darkMode: yup.string().default("auto").required(),
        })
        .nullable(),
})

export const tagType = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required("name is required"),
    description: yup.string().ensure(),
    type: yup.string().ensure(),
    /*.oneOf([
            "finish",
            "equipment",
            "plumbing",
            "light",
            "hardware",
            "furniture",
            "art",
            "signage",
            "accessories",
        ])*/ fields: yup.array().of(
        yup.object().shape({
            key: yup.string().required(),
            name: yup.string().required("name is required"),
            type: yup.string().oneOf(["string", "number"]),
        })
    ),
})

export const project = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required("name is required"),
    clientId: yup.string().matches(uuid, "id must be a valid uuid").required(),
    primaryUserId: yup
        .string()
        .matches(uuid, "primaryUserId must be a valid uuid")
        .nullable(),
    secondaryUserId: yup
        .string()
        .matches(uuid, "secondaryUserId must be a valid uuid")
        .nullable(),
    fromProjectId: yup
        .string()
        .matches(uuid, "id must be a valid uuid")
        .nullable(),

    note: yup.string().ensure(),
})

export const specification = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    projectId: yup.string().matches(uuid, "id must be a valid uuid").required(),
    tagTypeId: yup.string().matches(uuid, "id must be a valid uuid").required(),
    tagNumber: yup.number(),
    images: yup.array().nullable(),
    sheets: yup.array().nullable(),
    privateFiles: yup.array().nullable(),
    name: yup.string(),
    supplierId: yup
        .string()
        .matches(uuid, "supplierId must be a valid uuid")
        .nullable(),
    location: yup.string().required(),
    meta: yup.object().shape({
        tagTypeFields: yup.mixed(),
        custom: yup.array().of(yup.mixed()),
    }),
    leadtime: yup.string().ensure(),
    price: yup.number().integer().nullable(),
    tradePrice: yup.number().integer().nullable(),
    retailPrice: yup.number().integer().nullable(),
    budget: yup.number().integer().nullable(),
    quantity: yup.number().nullable(),
    procurementStatus: yup
        .string()
        .oneOf([
            "quoted",
            "paid",
            "ordered",
            "arrived",
            "delivered",
            "installed",
            null,
        ])
        .nullable(),
    billingStatus: yup
        .string()
        .oneOf(["quoted", "paid", "approved", "deposit_paid", null])
        .nullable(),
    unit: yup.string().ensure(),
    notes: yup.string().ensure(),
    privateNotes: yup.string().ensure(),
    url: yup.string().ensure(),
    issuanceId: yup
        .string()
        .matches(uuid, "issuanceId must be a valid uuid")
        .nullable(),
    issuedSpecificationId: yup
        .string()
        .matches(uuid, "issuanceId must be a valid uuid")
        .nullable(),
    archived: yup.number().nullable(),
    issuanceMark: yup.number().nullable(),
    taxIds: yup
        .array()
        .of(yup.string().matches(uuid, "id must be a valid uuid"))
        .ensure(),
    invoiceStatus: yup.string().nullable().default("none"),
    marks: yup.array().nullable(),
})

export const image = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required("name is required"),
    location: yup.string().required("location is required"),
    meta: yup
        .object()
        .oneOf(["red", "green", "blue", "purple", "orange", "grey"])
        .nullable(),
})

function projectSharesFactory(type = "") {
    const nr = yup.string().matches(uuid, "id must be a valid uuid").nullable()
    const rr = yup
        .string()
        .matches(uuid, "id must be a valid uuid")
        .nullable()
        .required()

    return yup.object().shape({
        id: yup.string().matches(uuid, "id must be a valid uuid").required(),
        projectId: yup
            .string()
            .matches(uuid, "id must be a valid uuid")
            .required(),
        supplierId: type === "supplier" ? rr : nr,
        consultantId: type === "consultant" ? rr : nr,
        pass: yup.string().nullable(),
        enabled: yup.number().integer().required(),
        pricing: yup.number().integer().required(),
        note: yup.string().nullable(),
        sendEmail: yup.number().integer().required(),
    })
}

export const projectShares = projectSharesFactory()
export const projectSharesConsultant = projectSharesFactory("consultant")
export const projectSharesSupplier = projectSharesFactory("supplier")

export const demoRequest = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    studioName: yup.string().required(),
    contact: contactEmailRequired,
    web: yup.string().ensure(),
})

export const issuances = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    projectId: yup
        .string()
        .matches(uuid, "projectId must be a valid uuid")
        .required(),
    note: yup.string().ensure(),
    name: yup.string().ensure(),
    createdAt: yup.date().required(),
    attachments: yup.array().nullable(),
    sendEmail: yup.number().integer().required(),
    issuedSpecificationIds: yup
        .array()
        .of(yup.string().matches(uuid, "id must be a valid uuid"))
        .ensure(),
})

export const consultants = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    type: yup.string().ensure(),
    notes: yup.string().ensure(),
    contact: contact,
})

export const logins = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    event: yup
        .string()
        .ensure()
        .oneOf(["login", "logout", "pushedout"])
        .required(),
    createdAt: yup.date().required(),
    userId: yup.string().matches(uuid, "id must be a valid uuid").required(),
})

export const refers = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    userId: yup
        .string()
        .matches(uuid, "user_id must be a valid uuid")
        .required(),
    contact: contactEmailRequired,
    studioName: yup.string().required(),
})

export const invoices = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    projectId: yup
        .string()
        .matches(uuid, "projectId must be a valid UUID")
        .required(),
    dueDate: yup.date().required(),
    subTotal: yup.number().integer(),
    taxAmount: yup.number().integer(),
    total: yup.number().integer(),
    paid: yup.number().integer().required(),
    createdAt: yup.date().required(),
    note: yup.string().ensure(),
    status: yup.string().oneOf(["draft", "approved", "sent"]).required(),
})

export const invoiceLineItems = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    invoiceId: yup
        .string()
        .matches(uuid, "invoiceId must be a valid UUID")
        .required(),
    specificationId: yup
        .string()
        .matches(uuid, "specificationId must be a valid UUID")
        .nullable(),
    note: yup.string().ensure(),
    quantity: yup.number().integer().required(),
    price: yup.number().required().integer(),
    subTotal: yup.number().integer(),
    taxAmount: yup.number().integer(),
    total: yup.number().integer(),
    taxIds: yup
        .array()
        .of(yup.string().matches(uuid, "id must be a valid uuid"))
        .ensure(),
})

export const taxes = yup.object().shape({
    id: yup.string().matches(uuid, "id must be a valid uuid").required(),
    name: yup.string().required(),
    rate: yup.number().positive().required(),
    recoverable: yup.number().integer().required(),
    compound: yup.number().integer(),
    number: yup.string().nullable(),
})
