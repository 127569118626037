import React, { useState, useCallback, useMemo, useEffect, useRef } from "react"

import {
    Stack,
    useTheme,
    Pivot,
    PivotItem,
    Text,
    ContextualMenuItemType,
    Image,
    IconButton,
    DefaultButton,
    PrimaryButton,
    PanelType,
    Panel,
    TextField,
    CommandButton,
    Callout,
    Separator,
} from "@fluentui/react"
import { useBoolean, useId } from "@fluentui/react-hooks"

import { Persona, PersonaSize } from "@fluentui/react/lib/Persona"
import { useAuth0 } from "@auth0/auth0-react"
import {
    ContextualMenu,
    DirectionalHint,
} from "@fluentui/react/lib/ContextualMenu"
import config from "../config"
import { useProjectConfig } from "../components/Projects"
import { useGlobalState } from "../state"
import { Icon } from "@fluentui/react/lib/Icon"
import { useHistory, useLocation } from "react-router-dom"
import { useEntity } from "@prospecer/ffsds/src/fe/entity"
import UpsertPanel from "../layouts/UpsertPanel"
import { UserForm, userConfig } from "../components/Users"
import { infoConfig } from "../components/Infos"
import { StudioForm, useStudioConfig } from "../components/Studios"
import { useEntityFromConfig } from "../hooks/Entity"
import { useConfig as useSpecificationConfig } from "../components/Specifications"
import Logo from "../images/Small-Logo.svg"
import LogoImpact from "../images/Icon-Color.svg"

import { useDataDogRum } from "react-datadog"
import { loginsConfig } from "./Logins"
import { v4 as uuidv4 } from "uuid"
import BillingInfo from "./BillingInfo"
import Modal from "../layouts/Modal"
import Calendly from "./Calendly"
import Nav from "./Nav"

import themes from "../themes"

import { useDebounce } from "../hooks/useDebounce"
function useValues(user) {
    const theme = useTheme()

    const stackStyles = {
        root: {
            background: themes.light.themeDark,
            color: theme.palette.white,
            height: 50,
            alignContent: "center",
            lineHeight: 50,
            alignItems: "center",
        },
    }
    const fontStyles = {
        root: {
            color: theme.palette.white,
            paddingLeft: 1,
        },
    }

    const logoStackStyle = {
        root: {
            paddingLeft: 15,
            width: 21,
        },
    }

    const rightStackItmesStyle = {
        root: {
            paddingRight: theme.spacing.s1,
            paddingLeft: theme.spacing.s1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
        },
    }

    let initials = ".."
    if (user) {
        const name = user.contact.name
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu")

        initials = [...name.matchAll(rgx)] || []

        initials = (
            (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase()
    }

    const currentPersona = {
        imageUrl: null,
        imageInitials: initials,
        lineHeight: 40,
        initialsColor: theme.palette.neutralTertiary,
    }

    const currentPersonaStyles = {
        root: { width: 21, cursor: "pointer" },
    }

    return {
        stackStyles,
        fontStyles,
        logoStackStyle,
        rightStackItmesStyle,
        currentPersona,
        currentPersonaStyles,
    }
}

function UserMenu(props) {
    const { logout } = useAuth0()
    const linkRef = React.useRef(null)
    const [modal, setModal] = useState(null)
    const useUsers = useEntity(config, userConfig, useGlobalState)
    const infos = useEntity(config, infoConfig, useGlobalState)
    const dataDog = useDataDogRum()
    const useLogins = useEntityFromConfig(loginsConfig)

    const info = infos.filter([])

    let users = null

    if (info[0]) {
        users = useUsers.filter([
            { type: "one", field: "id", value: info[0].userId },
        ])
    }

    const { currentPersona, currentPersonaStyles } = useValues(
        users ? users[0] : null
    )

    useEffect(() => {
        if (users && config.dataDog.enabled) {
            const user = users[0]
            if (user) {
                dataDog.setUser({
                    id: user.id,
                    name: user.contact.name,
                    email: user.contact.email,
                    studioId: info[0].studioId,
                })
            }
        }
    }, [users, dataDog])

    const [showContextualMenu, setShowContextualMenu] = useState(false)
    const onShowContextualMenu = useCallback((ev) => {
        ev.preventDefault() // don't navigate
        setShowContextualMenu(true)
    }, [])
    const onHideContextualMenu = useCallback(
        () => setShowContextualMenu(false),
        []
    )

    const submitRef = useRef(() => {
        setModal(null)
    })
    const user = users ? users[0] : false

    const menuItems = user
        ? [
              {
                  key: "edit",
                  text: "Edit Profile",
                  iconProps: {
                      iconName: "EditContact",
                  },
                  onClick: () => {
                      if (info) {
                          setModal(
                              <UpsertPanel
                                  buttonLabel="Your Profile"
                                  AddForm={UserForm}
                                  onClose={() => {
                                      setModal(null)
                                  }}
                                  submitRef={submitRef}
                                  values={user}
                              />
                          )
                      }
                  },
              },
              { key: "divider_1", itemType: ContextualMenuItemType.Divider },

              {
                  key: "darkmode_auto",
                  text: "Auto Detect Dark Mode ",
                  onClick: () => {
                      const u = { ...user }
                      if (!u.prefs) {
                          u.prefs = {}
                      }
                      u.prefs.darkMode = "auto"
                      useUsers.dispatch.upsert(u)
                  },
                  canCheck: true,
                  isChecked: user.prefs
                      ? user.prefs.darkMode === "auto" || !user.prefs.darkMode
                      : true,
                  iconProps: {
                      iconName: "Light",
                  },
              },
              {
                  key: "darkmode_dark",
                  text: "Dark Mode ",
                  canCheck: true,
                  isChecked: user.prefs
                      ? user.prefs.darkMode === "dark"
                      : false,
                  onClick: () => {
                      const u = { ...user }
                      if (!u.prefs) {
                          u.prefs = {}
                      }
                      u.prefs.darkMode = "dark"
                      useUsers.dispatch.upsert(u)
                  },
                  iconProps: {
                      iconName: "VideoLightOff",
                  },
              },
              {
                  key: "darkmode_light",
                  text: "Light Mode ",
                  canCheck: true,
                  isChecked: user.prefs
                      ? user.prefs.darkMode === "light"
                      : false,
                  onClick: () => {
                      const u = { ...user }
                      if (!u.prefs) {
                          u.prefs = {}
                      }
                      u.prefs.darkMode = "light"
                      useUsers.dispatch.upsert(u)
                  },
                  iconProps: {
                      iconName: "FlashLight",
                  },
              },
              { key: "divider_2", itemType: ContextualMenuItemType.Divider },

              {
                  key: "logout",
                  text: "Logout",
                  onClick: async () => {
                      await useLogins.dispatch.upsert({
                          id: uuidv4(),
                          userId: user.id,
                          event: "logout",
                          createdAt: new Date(),
                      })
                      logout({ returnTo: config.auth0.redirectUri })
                  },
                  iconProps: {
                      iconName: "SignOut",
                  },
              },
          ]
        : [
              {
                  key: "logout",
                  text: "Logout",
                  onClick: () => logout({ returnTo: config.auth0.redirectUri }),
                  iconProps: {
                      iconName: "SignOut",
                  },
              },
          ]
    return (
        <>
            {modal && <div>{modal}</div>}
            <Persona
                ref={linkRef}
                onClick={onShowContextualMenu}
                styles={currentPersonaStyles}
                {...currentPersona}
                size={PersonaSize.size32}
            />
            <ContextualMenu
                items={menuItems}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </>
    )
}

function Settings(props) {
    const studioConfig = useStudioConfig()
    const studios = useEntity(config, studioConfig, useGlobalState)
    const [modal, setModal] = useState(null)
    const [dismissedPayNotice, setDismissedPayNotice] = useState(false)

    const infos = useEntityFromConfig(infoConfig)
    const theme = useTheme()

    const info = infos.filter([])

    const studio = studios.filter([
        { field: "id", type: "eq", value: info[0] ? info[0].studioId : 0 },
    ])

    const submitRef = useRef(() => {
        setModal(null)
    })

    useEffect(() => {
        ///console.log(info)
        if (info[0] && info[0].paymentMethodStatus === "failed") {
            if (modal === null && !dismissedPayNotice) {
                setModal(
                    <Modal
                        onClose={() => {
                            if (!info[0].lockedOut) {
                                setDismissedPayNotice(true)
                                setModal(null)
                            }
                        }}
                    >
                        <div style={{ textAlign: "center" }}>
                            <h1>Oh no, your payment failed</h1>
                            <p>
                                To correct this issue please click the button
                                below to update payment method
                            </p>
                            {info[0].lockedOut && (
                                <p>
                                    <b>
                                        To keep using Prospecer you must correct
                                        the payment isssue.
                                    </b>
                                </p>
                            )}
                            {!info[0].lockedOut && (
                                <p>
                                    To keep using Prospecer you may need to
                                    update your payment details
                                </p>
                            )}
                            <p>
                                If you believe this is an error please contact
                                support at{" "}
                                <a
                                    href={
                                        "mailto:" + config.contacts.suportEmail
                                    }
                                >
                                    {config.contacts.suportEmail}
                                </a>
                            </p>
                            <br />
                            <PrimaryButton
                                onClick={() => {
                                    setModal(() => {
                                        setModal(<BillingInfo />)
                                    })
                                }}
                            >
                                Update billing info
                            </PrimaryButton>
                            <br />
                        </div>
                    </Modal>
                )
            }
        }
    }, [info])

    const styles = { icon: { color: theme.palette.white } }
    const menuProps = {
        items: [
            {
                key: "studioInfo",
                text: "Studio Info",
                iconProps: { iconName: "Info" },
                onClick: () => {
                    if (studio) {
                        setModal(
                            <UpsertPanel
                                buttonLabel="Studio Info "
                                AddForm={StudioForm}
                                onClose={() => {
                                    setModal(null)
                                }}
                                submitRef={submitRef}
                                values={studio[0]}
                            />
                        )
                    }
                },
            },
            {
                key: "help",
                text: "Schedule a support call",
                iconProps: { iconName: "Telemarketer" },
                onClick: () => {
                    if (studio) {
                        setModal(
                            <Modal
                                onClose={() => {
                                    setModal(null)
                                }}
                            >
                                <Calendly />
                            </Modal>
                        )
                    }
                },
            },
            ...(info[0] && info[0].paymentMethod === "stripe"
                ? [
                      {
                          key: "stripEdit",
                          text: "Billing Info",
                          onClick: () => {
                              setModal(<BillingInfo />)
                          },
                          iconProps: {
                              iconName: "PaymentCard",
                          },
                      },
                  ]
                : []),
        ],
        isBeakVisible: false,
    }
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            {modal && <span>{modal}</span>}
            <IconButton
                styles={styles}
                iconProps={{ iconName: "Settings" }}
                menuProps={menuProps}
                onRenderMenuIcon={() => {
                    return ""
                }}
            />
        </div>
    )
}

function MainSelection(props) {
    const theme = useTheme()
    const location = useLocation()
    const history = useHistory()
    const [openProjects, setOpenProjects] = useState([])
    const useSpecificatoins = useEntityFromConfig(useSpecificationConfig())

    const selectedKey = useMemo(() => {
        if (location.pathname === "/open") {
            return "__open"
        }
        if (location.pathname.startsWith("/schedule/")) {
            const parts = location.pathname.split("/")
            const key = parts[2]
            if (!openProjects.includes(key)) {
                const op = Array.from(openProjects)
                op.push(key)
                setOpenProjects(op)
            }

            return key
        }
        return "__main"
    }, [location.pathname, openProjects])

    useEffect(() => {
        const func = (e) => {
            history.push("/schedule/" + e.detail)
        }

        const closeFunc = (e) => {
            if (openProjects.includes(e.detail)) {
                const op = Array.from(openProjects)
                const index = op.indexOf(e.detail)
                op.splice(index, 1)
                setOpenProjects(op)
                if (index - 1 >= 0) {
                    history.push("/schedule/" + op[index - 1])
                } else {
                    history.push("/")
                }
                //Honestly this sucks
                useSpecificatoins.dispatch.clearSegment(e.detail)
            }
        }
        document.body.addEventListener("closeProject", closeFunc, false)
        document.body.addEventListener("openProject", func, false)

        return () => {
            document.body.removeEventListener("openProject", func, false)
            document.body.removeEventListener("closeProject", closeFunc, false)
        }
    }, [openProjects, history])

    const handleLinkClick = (item) => {
        if (item.props.itemKey === "__open") {
            history.push("/open")
        } else if (item.props.itemKey === "__main") {
            history.push("/")
        } else {
            history.push("/schedule/" + item.props.itemKey)
        }
    }
    const projectsAccess = useEntity(config, useProjectConfig(), useGlobalState)

    const tabs = useMemo(() => {
        const t = []
        for (const p of openProjects) {
            const item = projectsAccess.filter([
                { field: "id", type: "one", value: p },
            ])[0]

            if (item) {
                t.push(
                    <PivotItem
                        key={p}
                        headerText={item.name}
                        itemKey={item.id}
                        itemIcon="Shapes"
                    />
                )
            }
        }
        return t
    }, [openProjects, projectsAccess])
    const pivotStyles = {
        text: {
            color: theme.palette.white,
            fontSize: theme.fonts.large.fontSize,
            selectors: {
                ":hover": {
                    color: theme.palette.neutralSecondaryAlt,
                },
            },
        },

        icon: { color: theme.palette.themeLighterAlt },
        linkIsSelected: {
            selectors: {
                ":before": {
                    height: "4px", // was previously defaulted at 2px
                },
            },
        },

        overflowMenuButton: {
            color: theme.palette.white,
            fontSize: theme.fonts.large.fontSize,
        },
        linkInMenu: {
            backgroundColor: theme.palette.themeDark,
        },
    }

    const stackStyles = {
        root: {
            height: 50,
            width: "70vw",
        },
    }
    return (
        <>
            {props.fullScreen && (
                <FullScreenBar
                    openProjects={openProjects}
                    selectedProject={selectedKey}
                />
            )}
            {!props.fullScreen && (
                <Stack horizontal styles={stackStyles}>
                    <Stack.Item align="end">
                        <div
                            style={{
                                width: "70vw",
                                display: "inline-block",
                                resize: "both",
                            }}
                        >
                            <Pivot
                                styles={pivotStyles}
                                aria-label="Projects and Data"
                                selectedKey={selectedKey}
                                onLinkClick={handleLinkClick}
                                overflowBehavior="menu"
                                overflowAriaLabel="More projects"
                            >
                                <PivotItem
                                    headerText="Prospecer"
                                    itemKey="__main"
                                    itemIcon="CubeShape"
                                    onRenderItemLink={() => {
                                        return (
                                            <Image
                                                styles={{
                                                    root: {
                                                        display: "flex",
                                                        flex: "0 1 100%",
                                                    },
                                                    image: {
                                                        height: "18px",
                                                        fill: "white",
                                                    },
                                                }}
                                                src={Logo}
                                            />
                                        )
                                    }}
                                ></PivotItem>

                                {!props.public && (
                                    <PivotItem
                                        headerText="Open/New"
                                        itemKey="__open"
                                        itemIcon="Add"
                                    />
                                )}
                                {tabs}
                            </Pivot>
                        </div>
                    </Stack.Item>
                </Stack>
            )}
        </>
    )
}

function PopoutNav(props) {
    const isOpen = props.isOpen
    const onDismiss = props.onDismiss
    const theme = useTheme()
    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.customNear}
            customWidth={"200px"}
            isLightDismiss
            onDismiss={onDismiss}
            headerText={
                <Image
                    styles={{
                        root: {
                            display: "flex",
                            flex: "0 1 100%",
                        },
                        image: {
                            height: "18px",
                            fill: "white",
                        },
                    }}
                    src={Logo}
                />
            }
            hasCloseButton={false}
            styles={{
                content: {
                    padding: 0,
                    margin: 0,
                    backgroundColor: theme.palette.neutralLight,
                },
                header: {
                    backgroundColor: theme.palette.neutralLight,
                    paddingTop: "15px",
                    paddingBottom: "16px",
                    background: theme.palette.themeDark,
                },
                commands: {
                    display: "none",
                },
            }}
        >
            <Nav size="large" />
        </Panel>
    )
}

function ProjectSelections(props) {
    const projectIds = props.projectIds
    const history = useHistory()

    const projectConfig = useProjectConfig()
    const useProject = useEntityFromConfig(projectConfig)
    const projects = useProject.filter([
        { field: "id", type: "in", value: projectIds },
    ])

    return (
        <div>
            <Stack>
                <CommandButton
                    iconProps={{ iconName: "OpenFile" }}
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose()
                        }
                        history.push("/open")
                    }}
                >
                    Open/New
                </CommandButton>
                <Separator />
                <Text>Recent Projects:</Text>
                {projects.map((value, index) => {
                    return (
                        <Stack.Item>
                            <CommandButton
                                onClick={() => {
                                    if (props.onClose) {
                                        props.onClose()
                                    }
                                    history.push("/schedule/" + value.id)
                                }}
                            >
                                {value.name}
                            </CommandButton>
                        </Stack.Item>
                    )
                })}
            </Stack>
        </div>
    )
}

function ProjectWidget(props) {
    const openProjects = props.openProjects
    const selectedProject = props.selectedProject
    const theme = useTheme()
    const projectConfig = useProjectConfig()
    const useProject = useEntityFromConfig(projectConfig)
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
        useBoolean(false)
    const buttonId = useId("callout-button")
    const labelId = useId("callout-label")
    const descriptionId = useId("callout-description")
    const [projectEdit, setProjectEdit] = useState(false)
    const submitRef = useRef()

    const current = useProject.filter([
        { field: "id", type: "eq", value: selectedProject },
    ])

    const addIcon = { iconName: "TextDocument" }
    const styles = {
        root: {
            height: "100%",
            marginLeft: "4px",
            ":hover": {
                backgroundColor: theme.palette.themeDarker,
                color: theme.palette.black,
            },
            icon: {
                color: theme.palette.black,
            },
        },
    }

    return (
        <>
            {projectEdit && current[0] && (
                <UpsertPanel
                    buttonLabel="Update Project"
                    AddForm={projectConfig.Form}
                    submitRef={submitRef}
                    values={current[0]}
                    onClose={() => {
                        setProjectEdit(false)
                    }}
                    near={false}
                />
            )}
            <CommandButton
                id={buttonId}
                onClick={toggleIsCalloutVisible}
                styles={styles}
            >
                {current[0]?.name}&nbsp;
                <Icon iconName="ChevronDown" />
            </CommandButton>
            {isCalloutVisible && (
                <Callout
                    className={styles.callout}
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={descriptionId}
                    role="dialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <div style={{ padding: theme.spacing.l1 }}>
                        <Text>Current Project:</Text>
                        <Stack horizontal>
                            <Stack.Item align="center">
                                <Stack.Item>
                                    <div>
                                        <Text
                                            block
                                            variant="large"
                                            className={styles.title}
                                            id={labelId}
                                        >
                                            {current[0]?.name}
                                        </Text>
                                    </div>
                                </Stack.Item>
                            </Stack.Item>
                            <CommandButton
                                onClick={() => {
                                    setProjectEdit(true)
                                }}
                                iconProps={{ iconName: "Edit" }}
                            ></CommandButton>
                        </Stack>
                        <Separator />

                        <ProjectSelections
                            onClose={toggleIsCalloutVisible}
                            projectIds={openProjects.filter((value) => {
                                return value !== selectedProject
                            })}
                        />
                    </div>
                </Callout>
            )}
        </>
    )
}

function FullScreenBar(props) {
    const [showNav, setShowNav] = useState(false)
    const theme = useTheme()
    const openProjects = props.openProjects
    const selectedProject = props.selectedProject

    const stackStyles = {
        root: {
            height: 50,
        },
    }
    useEffect(() => {
        const event = new CustomEvent("globalSearch", {
            detail: "",
        })
        window.document.dispatchEvent(event)
    }, [])
    return (
        <>
            <Stack horizontal styles={stackStyles} enableScopedSelectors>
                <Stack.Item grow={0} align="end">
                    <Image
                        styles={{
                            root: {
                                display: "flex",
                                flexDirection: "column",
                                flex: "0 1 100%",
                                height: "50px",
                                justifyContent: "center",
                                marginLeft: "7px",
                            },
                            image: {
                                // height: "18px",
                                fill: "white",
                                width: "40px",
                                cursor: "pointer",
                            },
                        }}
                        src={LogoImpact}
                        onClick={() => {
                            setShowNav(true)
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow={0}>
                    <ProjectWidget
                        openProjects={openProjects}
                        selectedProject={selectedProject}
                    />
                </Stack.Item>
                <Stack.Item grow={1}>&nbsp;</Stack.Item>

                <Stack.Item grow={3}>
                    <TextField
                        onChange={(e, value) => {
                            const event = new CustomEvent("globalSearch", {
                                detail: value,
                            })
                            new Promise(() => {
                                window.document.dispatchEvent(event)
                            }).then()
                        }}
                        styles={{
                            root: {
                                display: "flex",
                                height: "50px",

                                flexDirection: "column",
                                justifyContent: "center",
                            },
                            fieldGroup: {
                                backgroundColor: theme.palette.themeLight,
                                borderRadius: "5px",
                            },
                            field: {
                                textAlign: "center",
                            },
                        }}
                        placeholder="Search"
                    ></TextField>
                </Stack.Item>
                <Stack.Item grow={2}>&nbsp;</Stack.Item>
            </Stack>
            <div>
                <PopoutNav
                    isOpen={showNav}
                    onDismiss={() => setShowNav(false)}
                />
            </div>
        </>
    )
}

export default function Header(props) {
    const { stackStyles, rightStackItmesStyle } = useValues()
    return (
        <div className="no-print">
            <Stack horizontal styles={stackStyles}>
                <Stack.Item grow={1}>
                    <MainSelection fullScreen={props.fullScreen} />
                </Stack.Item>
                <Stack.Item align={"center"} styles={rightStackItmesStyle}>
                    <Settings />
                </Stack.Item>
                <Stack.Item styles={rightStackItmesStyle}>
                    <UserMenu />
                </Stack.Item>
            </Stack>
        </div>
    )
}

export function PublicHeader(props) {
    const { stackStyles, logoStackStyle } = useValues()
    const theme = useTheme()

    const textStyles = {
        root: {
            color: theme.palette.white,
            fontSize: theme.fonts.large.fontSize,
        },
    }

    return (
        <div className="no-print">
            <Stack horizontal styles={stackStyles}>
                <Stack.Item grow={1}>
                    <Image
                        styles={{
                            root: {
                                display: "flex",
                                flex: "0 1 100%",
                                paddingLeft: 8,
                            },
                            image: {
                                height: "18px",
                                fill: "white",
                            },
                        }}
                        src={Logo}
                    />
                </Stack.Item>
            </Stack>
        </div>
    )
}
