import { Stack, Text, useTheme } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { useEntityFromConfig } from "../hooks/Entity"
import { EntityList } from "./Entities"

import { usePublicProjectSharesConfig as useProjectSharesConfig } from "./ProjectShares"
import {
    schedulesFilter,
    SpecificationsPublicForm,
    usePublicConfig,
} from "./Specifications"
import { usePublicProjectsConfig } from "./Projects"
import { usePublicIssuanceConfig } from "./Issuances"
import { usePublicImageConfig } from "./Images"
import config from "../config"
import { usePublicTagTypes } from "./TagTypes"
import { cloneDeep } from "lodash"
export default function PublicSpecifications(props) {
    const [to, setTo] = useState(false)

    const projectShares = useEntityFromConfig(
        useProjectSharesConfig(props.studioId, props.projectShareId)
    )
    //Limited by config
    const ps = projectShares.filter([])
    const theme = useTheme()

    useEffect(() => {
        const key = setTimeout(setTimeout(() => setTo(true), 9000))
        return () => {
            clearTimeout(key)
        }
    })

    if (ps.length !== 1) {
        return (
            <Text
                styles={{
                    root: {
                        paddingTop: theme.spacing.l2,
                        paddingLeft: theme.spacing.l2,
                    },
                }}
                variant="xxLarge"
            >
                {to && (
                    <span>
                        Cannot load data. This could be because of poor internet
                        connection or you don't have permission to view this
                        page
                    </span>
                )}
                {!to && <span>loading...</span>}
            </Text>
        )
    }

    return (
        <PublicSpecificationsReady
            studioId={props.studioId}
            projectShare={ps[0]}
        />
    )
}

function PublicSpecificationsReady(props) {
    const projectShare = props.projectShare
    const [printRows, setPrintRows] = useState(null)

    const filter = []

    const specificationConfig = usePublicConfig(
        props.studioId,
        projectShare.id,
        { filter: filter }
    )
    specificationConfig.additionalButtons = []
    const useProjects = useEntityFromConfig(
        usePublicProjectsConfig(props.studioId, projectShare.id)
    )

    const theme = useTheme()
    const useIssuance = useEntityFromConfig(
        usePublicIssuanceConfig(props.studioId, projectShare.id)
    )
    const useImages = useEntityFromConfig(
        usePublicImageConfig(props.studioId, projectShare.id)
    )
    const [issuanceId, setIssuanceId] = useState(null)
    const [checkedFilter, setCheckFilter] = useState(null)
    const [checkedFitlerName, setCheckFilterName] = useState(null)

    const project = useProjects.filter([
        { field: "id", type: "one", value: projectShare.projectId },
    ])
    const useTag = useEntityFromConfig(
        usePublicTagTypes(props.studioId, projectShare.id)
    )

    const sortedTagIds = useTag
        .filter([{ type: "_sort", key: "name" }])
        .map((t) => t.id)

    filter.push({
        type: "_sort",
        field: "tagNumber",
        numeric: true,
    })
    filter.push({
        type: "listSort",
        value: sortedTagIds,
        field: "tagTypeId",
    })

    if (projectShare.pricing !== 1) {
        specificationConfig.formFields = cloneDeep(
            specificationConfig.formFields.filter(function (obj) {
                return obj.isPricing !== true
            })
        )
    }

    if (projectShare.showSupplier !== 1) {
        specificationConfig.formFields = cloneDeep(
            specificationConfig.formFields.filter(function (obj) {
                return obj.key !== "supplierId"
            })
        )

        specificationConfig.listColumns = cloneDeep(
            specificationConfig.listColumns.filter(function (obj) {
                return obj.key !== "supplierId"
            })
        )
    }
    if (!projectShare.supplierId) {
        specificationConfig.formFields = cloneDeep(
            specificationConfig.formFields.filter(function (obj) {
                return obj.publicHide !== true
            })
        )
    }
    if (projectShare.supplierId) {
        specificationConfig.formFields = cloneDeep(
            specificationConfig.formFields.filter(function (obj) {
                return obj.supplierHide !== true
            })
        )
    }
    if (projectShare.urls !== 1) {
        specificationConfig.formFields = cloneDeep(
            specificationConfig.formFields.filter(function (obj) {
                return obj.key !== "url"
            })
        )
    }

    if (!projectShare.supplierId) {
        // Server filtered
        const issuances = useIssuance.filter([])
        const currentIssuance = useIssuance.filter([
            { field: "id", type: "eq", value: issuanceId },
        ])

        if (issuances) {
            const items = []
            if (issuanceId === null && issuances.length > 0) {
                setIssuanceId(issuances[issuances.length - 1].id)
            } else {
                filter.push({
                    field: "projectId",
                    type: "eq",
                    value: issuanceId,
                })
            }

            for (const i of issuances) {
                items.push({
                    key: i.id,
                    name:
                        new Date(i.createdAt).toLocaleDateString() +
                        " | " +
                        i.name,
                    value: i.id,
                })
            }

            specificationConfig.additionalButtons.push({
                key: "issuances",
                text: currentIssuance[0]
                    ? new Date(
                          currentIssuance[0].createdAt
                      ).toLocaleDateString() +
                      " | " +
                      currentIssuance[0].name
                    : "Issuances",
                doNotHide: true,
                iconProps: { iconName: "ClipboardList" },
                subMenuProps: {
                    onItemClick: (e, item) => {
                        const issuance = useIssuance.filter([
                            { field: "id", type: "eq", value: item.key },
                        ])
                        setIssuanceId(issuance[0].id)
                    },
                    items: items,
                },
            })
            specificationConfig.additionalButtons.push(
                schedulesFilter(
                    checkedFilter,
                    setCheckFilter,
                    checkedFitlerName,
                    setCheckFilterName,
                    { noGroup: true }
                )
            )

            if (checkedFilter && checkedFilter !== "__group") {
                const tags = useTag.filter([
                    { type: "eq", field: "type", value: checkedFilter },
                ])

                filter.push({
                    type: "in",
                    field: "tagTypeId",
                    value: tags.map((a) => a.id),
                })
            }

            const attachmentItems = []
            if (issuanceId) {
                const i = useIssuance.filter([
                    { field: "id", type: "eq", value: issuanceId },
                ])[0]

                if (i.attachments && i.attachments.length > 0) {
                    const imgIds = i.attachments
                    const documents = []
                    for (const f of imgIds) {
                        const img = useImages.filter([
                            { field: "id", type: "eq", value: f },
                        ])
                        if (img && img[0]) {
                            documents.push({
                                key: img[0].id,
                                name: img[0].name,
                                value: img[0].id,
                            })
                        }
                    }

                    specificationConfig.additionalButtons.push({
                        key: "attachments",
                        text: "Attachments",
                        doNotHide: true,
                        iconProps: { iconName: "DownloadDocument" },
                        subMenuProps: {
                            items: documents,
                            onItemClick: (e, item) => {
                                window.open(config.images.baseUrl + item.value)
                            },
                        },
                    })
                }
            }
        }
    }

    specificationConfig.Form = (props) => {
        return (
            <SpecificationsPublicForm
                studioId={props.studioId}
                projectSharesId={projectShare.id}
                customConfig={specificationConfig}
                {...props}
            />
        )
    }
    specificationConfig.name = project[0]?.name

    return (
        <Stack>
            <EntityList
                styles={{}}
                config={specificationConfig}
                doNotHidePrint={true}
                noSelection={true}
                disablePrint
            />
        </Stack>
    )
}
