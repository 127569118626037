import React, { useState } from "react"
import Panel from "./Panel"

export default function UpsertPanel(props) {
    const submitRef = props.submitRef
    const AddForm = props.AddForm
    const [hideButtons, setHideButtons] = useState(props.hideButtons)
    return (
        <Panel
            isOpen={props.isOpen}
            title={props.buttonLabel}
            hideButtons={hideButtons}
            onClose={() => {
                props.onClose(false)
            }}
            lightDismiss={props.lightDismiss}
            onSave={async () => {
                if (props.readOnly) {
                    props.onClose(true)
                    return
                }
                setHideButtons(true)
                const result = await submitRef.current()
                if (result) {
                    props.onClose(true)
                } else {
                    console.error("Failed upsert")
                }
                setHideButtons(false)
            }}
            near={props.near}
            large={props.large}
            isBlocking={props.isBlocking}
            noAnimation={props.noAnimation}
            showHideButton={props.showHideButton}
        >
            <AddForm
                readOnly={props.readOnly}
                values={props.values}
                submitRef={submitRef}
                {...props.formProps}
            />
        </Panel>
    )
}
