import { logins as loginsValidator } from "@prospecer/schema"
import config from "../config"

export const loginsConfig = {
    baseUrl: config.api.baseUrl + "/logins",
    type: "LOGINS",
    stateKey: "logins",
    name: "Logins",
    nameSingle: "Login",
    readScope: "read:users",
    defaultSortKey: "createdAt",
    validator: loginsValidator,
}
