import { useCalls } from "@prospecer/ffsds/src/fe/entity"
import { useEffect, useMemo, useState } from "react"
import config from "../config"

export default function BillingInfo(props) {
    const calls = useCalls(config, {
        baseUrl: config.api.baseUrl + "/stripe",
        readScope: "write:billing",
    })

    const [url, setUrl] = useState()

    useEffect(() => {
        async function f() {
            const url = await calls.fetch([])
            setUrl(url.data.results[0].object.billingUrl)
        }
        f()
    }, [])

    if (url) {
        window.location = url
    }

    return <span></span>
}
