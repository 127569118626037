import React from "react"

import { consultants as consultantValadator } from "@prospecer/schema"
import config from "../config"

import { EntityList, EntityForm } from "./Entities"

export const consultantTypes = [
    { key: "gc", text: "GC" },
    { key: "architect", text: "Architect" },
    { key: "plumbing", text: "Plumbing" },
    { key: "electrical", text: "Electrical" },
    { key: "legal", text: "Legal/Permitting" },
    { key: "other", text: "Other" },
]
export const consultantsConfig = {
    baseUrl: config.api.baseUrl + "/consultants",
    type: "CONSULTANTS",
    stateKey: "consultants",
    name: "Consultants",
    nameSingle: "Consultant",
    readScope: "read:consultants",
    writeScope: "write:consultants",
    defaultSortKey: "name",
    listColumns: [
        {
            key: "name",
            title: "Name",
        },
        {
            key: "contact.name",
            title: "Supplier name",
        },
        {
            key: "contact.phone",
            title: "Phone Number",
        },
        {
            key: "type",
            title: "Type",
        },
        {
            key: "notes",
            title: "Notes",
        },
    ],
    formFields: [
        {
            label: "Consultant Name",
            key: "name",
            type: "text",
        },
        {
            label: "Type",
            key: "type",
            type: "dropdown",
            options: consultantTypes,
        },
        {
            label: "Address",
            key: "contact.address",
            type: "text",
            option: "multiline",
        },
        {
            label: "Country",
            key: "contact.country",
            type: "country",
        },
        {
            label: "City",
            key: "contact.city",
            type: "text",
        },
        {
            label: "State",
            key: "contact.state",
            type: "state",
            coupledValue: "contact.country",
        },
        {
            label: "Zip/Postal",
            key: "contact.postal",
            type: "text",
        },
        {
            label: "Primary Contact",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Name",
            key: "contact.name",
            type: "text",
        },
        {
            label: "Phone",
            key: "contact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },
        {
            label: "Mobile",
            key: "contact.mobile",
            type: "tel",
            coupledValue: "contact.country",
            extension: false,
        },

        {
            label: "Email",
            key: "contact.email",
            type: "email",
        },
        {
            label: "",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Notes",
            key: "notes",
            type: "text",
            option: "multiline",
        },
    ],
    emptyFormValue: { contact: {} },
    validator: consultantValadator,
    Form: ConsultantForm,
    allowDelete: true,
}

export function ConsultantList(props) {
    return <EntityList config={consultantsConfig} />
}

export function ConsultantForm(props) {
    return <EntityForm {...props} config={consultantsConfig} />
}
