import React, { useEffect, useMemo, useRef, useState } from "react"

import { invoices as invoiceValidator, studio } from "@prospecer/schema"
import config from "../config"

import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { useProjectConfig, usePublicProjectsConfig } from "./Projects"
import { DefaultButton, Icon, PrimaryButton, Stack } from "@fluentui/react"
import { InvoicePanel, InvoiceLayout } from "../layouts/InvoiceLayout"
import { clientConfig, usePublicClientConfig } from "./Clients"
import { v4 as uuidv4 } from "uuid"
import {
    InvoiceLineItemsList,
    useInvoiceLineItmesConfig,
    usePublicInvoiceLineItmesConfig,
} from "./InvoiceLineItems"
import { moneyFormatter } from "../utils"
import UpsertPanel from "../layouts/UpsertPanel"
import { useIssuanceConfig } from "./Issuances"
import { usePublicProjectSharesConfig } from "./ProjectShares"
import axios from "axios"
import fileDownload from "js-file-download"
import { infoConfig } from "./Infos"
import { usePublicStudioConfig, useStudioConfig } from "./Studios"
import { useEntity } from "@prospecer/ffsds/src/fe/entity"
import { cloneDeep } from "lodash"
import { supplierConfig, usePublicSupplier } from "./Suppliers"
import UpsertModal from "../layouts/UpsertModal"

function useInvoiceColumns(po) {
    const useSupplier = useEntityFromConfig(supplierConfig)

    return [
        {
            key: "status",
            title: "Status",
            formatter: (value) => {
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
        },
        ...(po
            ? [
                  {
                      key: "supplierId",
                      title: "Supplier",
                      formatter: (value) => {
                          const supplier = useSupplier.filter([
                              { field: "id", type: "eq", value: value },
                          ])
                          return supplier[0]?.name
                      },
                  },
              ]
            : []),
        {
            key: "createdAt",
            title: "Created",
            formatter: (value) => {
                return new Date(value).toLocaleDateString()
            },
        },
        {
            key: "total",
            title: "Total",

            formatter: (value) => {
                var formatter = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                })
                return formatter.format(value / 100)
            },
        },

        {
            key: "dueDate",
            title: "Due",
            formatter: (value) => {
                return new Date(value).toLocaleDateString()
            },
        },
    ]
}

function useInvoiceFormFields(po) {
    return [
        {
            label: po ? "Fulfill date" : "Due Date",
            key: "dueDate",
            type: "date",
        },
        {
            label: po ? "Purchase Order Date" : "Invoice Date",
            key: "createdAt",
            type: "date",
        },
        {
            label: "Total",
            key: "total",
            type: "money",
            readOnly: true,
            liveUpdate: true,
        },

        {
            label: "Notes/Terms",
            key: "note",
            type: "text",
            option: "multiline",
        },
        {
            label: "Status",
            key: "status",
            type: "info",
            liveUpdate: true,
            readOnly: true,
            valueFunction: (values) => {
                return values.status ? values.status.toUpperCase() : ""
            },
        },
    ]
}

export function useInvoiceConfig(projectId, po = false) {
    const useProject = useEntityFromConfig(useProjectConfig())
    const useClient = useEntityFromConfig(clientConfig)

    const listColumns = useInvoiceColumns(po)
    const formFields = useInvoiceFormFields(po)
    const project =
        useProject.filter([{ field: "id", type: "eq", value: projectId }])[0] ??
        {}

    return {
        baseUrl: config.api.baseUrl + "/invoices",
        type: "INVOICES",
        stateKey: "invoices",
        name: "Invoices",
        nameSingle: "Invoice",
        readScope: "read:billing",
        writeScope: "write:billing",
        defaultSortKey: "name",
        partitionField: "projectId",
        partitionKey: projectId,
        filter: [{ field: "projectId", type: "eq", value: projectId }],
        listColumns: listColumns,
        formFields: formFields,
        emptyFormValue: () => {
            return {
                dueDate: new Date(),
                createdAt: new Date(),
                total: 0,
                projectId: projectId,
                id: uuidv4(),
                paid: 0,
                status: "draft",
            }
        },
        validator: invoiceValidator,
        Form: (props) => {
            return <FullInvoiceForm {...props} projectId={projectId} po={po} />
        },
        allowDelete: true,
    }
}

export function usePublicInvoiceConfig(projectSharesId, studioId, po = false) {
    const columns = useInvoiceColumns()
    const formFields = useInvoiceFormFields()
    const issuanceConfig = {
        baseUrl:
            config.api.baseUrl +
            (po ? "/public/pos?id=" : "/public/invoices?id=") +
            projectSharesId +
            "&studioId=" +
            studioId,
        type: "INVOICES",
        stateKey: "invoices",
        name: po ? "Purchase Orders" : "Invoices",
        nameSingle: po ? "Purcahse Orders" : "Invoice",
        readScope: "read:billing",
        writeScope: "write:billing",
        defaultSortKey: "name",
        listColumns: columns,
        insecure: true,
        formFields: formFields,
        readOnly: true,
        Form: (props) => {
            return (
                <PublicFullInvoiceForm
                    {...props}
                    projectSharesId={projectSharesId}
                    studioId={studioId}
                    po={po}
                />
            )
        },
    }

    return issuanceConfig
}

export function PublicInvoices(props) {
    const projectShareId = props.projectShareId
    const studioId = props.studioId

    const publicConfig = usePublicInvoiceConfig(
        projectShareId,
        studioId,
        props.po
    )

    return (
        <Stack>
            <EntityList
                config={publicConfig}
                doNotHidePrint={false}
                noSelection={true}
                disablePrint
                largeUpsertPanel
                hideUpsertPanelButtons
            />
        </Stack>
    )
}

export function InvoiceList(props) {
    const invoiceConfig = useInvoiceConfig(props.projectId, props.po)
    invoiceConfig.filter.push({
        field: "kind",
        type: "eq",
        value: props.po ? "po" : "invoice",
    })
    if (props.po) {
        invoiceConfig.name = "Purcahse Orders"
        invoiceConfig.nameSingle = "Purchase Order"
    }
    const useInvoice = useEntityFromConfig(invoiceConfig)

    const [addModal, setAddModal] = useState(null)
    const submitRef = useRef()
    return (
        <>
            {addModal}
            <EntityList
                hideUpsertPanelButtons
                largeUpsertPanel
                config={invoiceConfig}
                onNew={async () => {
                    const values = invoiceConfig.emptyFormValue()
                    await useInvoice.dispatch.upsert(values)
                    setAddModal(
                        <UpsertPanel
                            large={true}
                            buttonLabel={"Save"}
                            submitRef={submitRef}
                            AddForm={invoiceConfig.Form}
                            onClose={() => {
                                setAddModal(null)
                            }}
                            values={values}
                            hideButtons={true}
                        />
                    )
                }}
            />
        </>
    )
}

export function PublicInvoiceForm(props) {
    const invoiceConfig = usePublicInvoiceConfig(
        props.projectId,
        props.studioId
    )
    const valuesRef = useRef(props.values)
    const setValuesRef = useRef((values) => {
        valuesRef.current = values
    })

    return (
        <>
            <EntityForm
                hideButtons
                {...props}
                config={invoiceConfig}
                valuesRef={valuesRef}
                setValuesRef={setValuesRef}
                readOnly
            />
        </>
    )
}

export function InvoiceForm(props) {
    const invoiceConfig = useInvoiceConfig(props.projectId, props.po)
    const valuesRef = useRef(props.values)
    const setValuesRef = useRef((values) => {
        valuesRef.current = values
    })

    const additionalButtons = []
    if (props.values.status === "draft") {
        additionalButtons.push(
            <DefaultButton key="publish">Publish</DefaultButton>
        )
    }
    return (
        <>
            <EntityForm
                showButtons
                {...props}
                config={invoiceConfig}
                valuesRef={valuesRef}
                setValuesRef={setValuesRef}
            />
        </>
    )
}

export function useInvoicePrintButton(invoiceId, projectId, po = false) {
    const infos = useEntityFromConfig(infoConfig)
    const info = infos.filter([])
    const useStudio = useEntityFromConfig(useStudioConfig())
    let studios = []

    if (info[0]) {
        studios = useStudio.filter([
            { type: "one", field: "id", value: info[0].studioId },
        ])
    }
    const privateAccess = {
        useInvoice: useEntityFromConfig(useInvoiceConfig()),
        useClient: useEntityFromConfig(clientConfig),
        useProject: useEntityFromConfig(useProjectConfig()),
        useInvoiceLineItems: useEntityFromConfig(useInvoiceLineItmesConfig()),
        useSupplier: useEntityFromConfig(supplierConfig),
    }
    return useInvoicePrintButtonBase(
        invoiceId,
        privateAccess.useInvoice,
        po ? privateAccess.useSupplier : privateAccess.useClient,
        privateAccess.useProject,
        privateAccess.useInvoiceLineItems,
        studios[0],
        projectId,
        po
    )
}

function usePublicInvoicePrintButton(
    invoiceId,
    projectSharesId,
    studioId,
    po = false
) {
    const useStudio = useEntityFromConfig(
        usePublicStudioConfig(projectSharesId, studioId)
    )

    const _studio = useStudio.filter([])
    const studio = _studio[0]

    const useClient = useEntityFromConfig(
        usePublicClientConfig(projectSharesId, studioId)
    )
    const useSupplier = useEntityFromConfig(
        usePublicSupplier(projectSharesId, studioId)
    )

    const publicAccess = {
        useInvoice: useEntityFromConfig(
            usePublicInvoiceConfig(projectSharesId, studioId)
        ),
        useClient: po ? useSupplier : useClient,
        useProject: useEntityFromConfig(
            usePublicProjectsConfig(projectSharesId, studioId)
        ),
        useInvoiceLineItems: useEntityFromConfig(
            usePublicInvoiceLineItmesConfig(
                projectSharesId,
                studioId,
                invoiceId,
                po
            )
        ),
    }
    return useInvoicePrintButtonBase(
        invoiceId,
        publicAccess.useInvoice,
        publicAccess.useClient,
        publicAccess.useProject,
        publicAccess.useInvoiceLineItems,
        studio
    )
}

function useInvoicePrintButtonBase(
    invoiceId,
    useInvoice,
    useClient,
    useProject,
    useInvoiceLineItems,
    studio,
    projectId,
    po
) {
    const [blob, setBlob] = useState(null)

    const ifilter = [{ field: "id", type: "eq", value: invoiceId }]
    if (projectId) {
        ifilter.push({ field: "projectId", type: "eq", value: projectId })
    }
    const invoice = useInvoice.filter(ifilter)
    invoice[0] = { ...invoice[0] }

    const invoiceLineItems = useInvoiceLineItems.filter([
        { field: "invoiceId", type: "eq", value: invoiceId },
    ])

    const project = useProject.filter([
        {
            field: "id",
            type: "eq",
            value: invoice[0]?.projectId,
        },
    ])

    console.log(invoice[0])

    let client

    if (po) {
        client = useClient.filter([
            {
                field: "id",
                type: "eq",
                value: invoice[0]?.supplierId,
            },
        ])
    } else {
        client = useClient.filter([
            {
                field: "id",
                type: "eq",
                value: project[0]?.clientId,
            },
        ])
    }

    async function print() {
        const url = config.reporting.url + "/invoice"
        if (studio.image) {
            studio.imageUrl = config.images.baseUrl + studio.image + "-xs"
        }
        invoice[0].total = moneyFormatter(invoice[0].total)
        for (const i in invoiceLineItems) {
            invoiceLineItems[i] = { ...invoiceLineItems[i] }
            invoiceLineItems[i].price = moneyFormatter(
                invoiceLineItems[i].price
            )
            invoiceLineItems[i].subTotal = moneyFormatter(
                invoiceLineItems[i].subTotal
            )
            invoiceLineItems[i].taxAmount = moneyFormatter(
                invoiceLineItems[i].taxAmount
            )
            invoiceLineItems[i].total = moneyFormatter(
                invoiceLineItems[i].total
            )
        }
        const obj = {
            client: client[0],
            project: project[0],
            invoice: invoice[0],
            invoiceLineItems: invoiceLineItems,
            studio: studio,
            po: po,
        }

        const response = await axios.post(url, obj, {
            responseType: "blob",
            headers: { "content-type": "application/json" },
        })
        fileDownload(response.data, "invoice.pdf")
        setBlob(response.data)
    }

    return () => (
        <PrimaryButton
            disabled={
                !(invoice[0] && invoiceLineItems[0] && client[0] && project[0])
            }
            iconProps={{ iconName: "Print" }}
            onClick={() => {
                print()
            }}
        >
            Print
        </PrimaryButton>
    )
}

export function PublicFullInvoiceForm(props) {
    const invoiceConfig = usePublicInvoiceConfig(
        props.projectSharesId,
        props.studioId,
        props.AddFormpo
    )
    const useStudio = useEntityFromConfig(
        usePublicStudioConfig(props.projectSharesId, props.studioId)
    )

    const invoiceId = props.values.id

    const invoiceLineItemsConfig = usePublicInvoiceLineItmesConfig(
        props.projectSharesId,
        props.studioId,
        invoiceId,
        props.po
    )
    const useProjectShares = useEntityFromConfig(
        usePublicProjectSharesConfig(props.studioId, props.projectSharesId)
    )

    const PrintButton = usePublicInvoicePrintButton(
        invoiceId,
        props.projectSharesId,
        props.studioId,
        props.po
    )
    const _studio = useStudio.filter([])
    const imageId = _studio[0]?.image

    const _projectShare = useProjectShares.filter([])
    const projectId = _projectShare[0]?.projectId

    return (
        <InvoiceLayout
            onClose={props.onClose}
            imageId={imageId}
            Form={
                <PublicInvoiceForm
                    ensureId={invoiceId}
                    {...props}
                    config={invoiceConfig}
                    readOnly
                />
            }
            to={
                props.po ? (
                    <SupplierToInfoPublic
                        projectSharesId={props.projectSharesId}
                        projectId={projectId}
                        studioId={props.studioId}
                    />
                ) : (
                    <ToInfoPublic
                        projectSharesId={props.projectSharesId}
                        projectId={projectId}
                        studioId={props.studioId}
                    />
                )
            }
            from={
                <FromInfoPublic
                    studioId={props.studioId}
                    projectSharesId={props.projectSharesId}
                />
            }
            list={
                <EntityList
                    {...props}
                    config={invoiceLineItemsConfig}
                    hideButtons
                    readOnly
                    noSelection
                />
            }
            //total={moneyFormatter(total)}
            {...props}
            actionButtons={[<PrintButton />]}
        />
    )
}

export function FullInvoiceForm(props) {
    const invoiceConfig = useInvoiceConfig(props.projectId, props.po)
    const useInvoice = useEntityFromConfig(invoiceConfig)
    const invoiceId = props.values.id
    const values = useInvoice.filter([
        { field: "id", type: "eq", value: invoiceId },
        { field: "projectId", type: "eq", value: props.projectId },
    ])[0]

    const useInfo = useEntityFromConfig(infoConfig)
    const useStudio = useEntityFromConfig(useStudioConfig())
    const infos = useInfo.filter([])
    const useInvoiceLineItems = useEntityFromConfig(
        useInvoiceLineItmesConfig(invoiceId, props.projectId)
    )
    console.log(props.po)
    const PrintButton = useInvoicePrintButton(
        invoiceId,
        props.projectId,
        props.po
    )

    const studios = useStudio.filter([
        { field: "id", type: "eq", value: infos[0]?.studioId },
    ])
    const invoiceLineItems = useInvoiceLineItems.filter([
        { field: "invoiceId", type: "eq", value: invoiceId },
    ])

    let total = 0
    for (const item of invoiceLineItems) {
        total = total + item.total
    }

    const actionButtons = []
    actionButtons.push(<PrintButton />)
    if (values.status === "draft" || !values.status) {
        actionButtons.push(
            <DefaultButton
                onClick={() => {
                    useInvoice.dispatch.upsert({
                        ...values,
                        status: "approved",
                    })
                }}
            >
                Approve and Publish
            </DefaultButton>
        )
    }

    return (
        <InvoiceLayout
            onClose={props.onClose}
            Form={
                <InvoiceForm
                    ensureId={invoiceId}
                    {...props}
                    config={invoiceConfig}
                    po={props.po}
                />
            }
            actionButtons={actionButtons}
            to={
                values.kind === "po" ? (
                    <SupplierToInfo supplierId={values.supplierId} />
                ) : (
                    <ToInfo projectId={props.projectId} />
                )
            }
            from={<FromInfo />}
            imageId={studios[0] ? studios[0].image : null}
            list={
                <InvoiceLineItemsList
                    invoiceId={invoiceId}
                    styles={{ listContainer: { height: "auto" } }}
                    noScroll={true}
                    projectId={props.projectId}
                />
            }
            total={moneyFormatter(total)}
            {...props}
            po={values.kind === "po"}
        />
    )
}

function FromInfo(props) {
    const useInfo = useEntityFromConfig(infoConfig)
    const useStudio = useEntityFromConfig(useStudioConfig())
    const infos = useInfo.filter([])
    if (infos[0]) {
        const studios = useStudio.filter([
            { field: "id", type: "one", value: infos[0].studioId },
        ])
        if (studios[0]) {
            return <FromInfoBase studio={studios[0]} />
        } else {
            return null
        }
    } else {
        return null
    }
}

function FromInfoPublic(props) {
    const useStudio = useEntityFromConfig(
        usePublicStudioConfig(props.projectSharesId, props.studioId)
    )
    const studios = useStudio.filter([])

    if (studios[0]) {
        return <FromInfoBase studio={studios[0]} />
    } else {
        return null
    }
}

function FromInfoBase(props) {
    const studio = props.studio

    return (
        <Stack>
            <b>{studio?.name}</b>
            <span>{studio.contact?.name}</span>
            <span>{studio.contact?.address}</span>
            <span>
                {studio.contact?.city} {studio.contact?.state},{" "}
                {studio.contact?.zip}
            </span>
            <span>{studio.contact?.email}</span>
        </Stack>
    )
}

function SupplierToInfo(props) {
    const useSupplier = useEntityFromConfig(supplierConfig)

    const suppliers = useSupplier.filter([
        { field: "id", type: "eq", value: props.supplierId },
    ])
    const supplier = suppliers[0]

    return <SupplierToInfoBase {...props} supplier={supplier} />
}

function SupplierToInfoPublic(props) {
    const useSupplier = useEntityFromConfig(
        usePublicSupplier(props.projectSharesId, props.studioId)
    )

    const suppliers = useSupplier.filter([])
    const supplier = suppliers[0]

    return <SupplierToInfoBase {...props} supplier={supplier} readOnly />
}

function SupplierToInfoBase(props) {
    const [showEditForm, setShowEditFrom] = useState(false)
    const submitRef = useRef()

    const supplier = props.supplier
    return (
        <>
            {showEditForm && (
                <UpsertModal
                    buttonLabel={props.addButtonLabel}
                    submitRef={submitRef}
                    AddForm={supplierConfig.Form}
                    values={supplier}
                    onClose={(values) => {
                        setShowEditFrom(false)
                    }}
                />
            )}
            <Stack horizontal>
                <Stack>
                    <b>{supplier?.name}</b>
                    <span>{supplier?.contact?.name}</span>
                    <span>{supplier?.contact?.address}</span>
                    <span>
                        {supplier?.contact?.city} {supplier?.contact?.state},{" "}
                        {supplier?.contact?.zip}
                    </span>
                    <span>{supplier?.contact?.email}</span>
                </Stack>
                {!props.readOnly && (
                    <Icon
                        iconName="Edit"
                        onClick={() => {
                            setShowEditFrom(true)
                        }}
                        styles={{ root: { cursor: "pointer" } }}
                    />
                )}
            </Stack>
        </>
    )
}

function ToInfo(props) {
    const useClient = useEntityFromConfig(clientConfig)
    const useProject = useEntityFromConfig(useProjectConfig())
    return (
        <ToInfoBase {...props} useClient={useClient} useProject={useProject} />
    )
}

function ToInfoPublic(props) {
    const useClient = useEntityFromConfig(
        usePublicClientConfig(props.projectSharesId, props.studioId)
    )
    const useProject = useEntityFromConfig(
        usePublicProjectsConfig(props.projectSharesId, props.studioId)
    )
    const projects = useProject.filter([])
    return (
        <ToInfoBase {...props} useClient={useClient} useProject={useProject} />
    )
}
function ToInfoBase(props) {
    const useClient = props.useClient
    const useProject = props.useProject
    const projectId = props.projectId
    const projects = useProject.filter([
        { field: "id", type: "eq", value: projectId },
    ])

    const project = projects ? projects[0] : {}

    const clients = project
        ? useClient.filter([
              { field: "id", type: "eq", value: project.clientId },
          ])
        : []
    const client = clients[0] ? clients[0] : {}

    return (
        <Stack>
            <b>{client?.name}</b>
            <span>{client.contact?.name}</span>
            <span>{client.contact?.address}</span>
            <span>
                {client.contact?.city} {client.contact?.state},{" "}
                {client.contact?.zip}
            </span>
            <span>{client.contact?.email}</span>
        </Stack>
    )
}

export function InvoiceFormPanel(props) {
    const invoiceConfig = useInvoiceConfig(props.projectId)
    const useInvoices = useEntityFromConfig(invoiceConfig)
    const invoiceValues = props.invoiceId
        ? useInvoices.filter([
              { field: "id", type: "eq", value: props.invoiceId },
              { field: "projectId", type: "eq", value: props.projectId },
          ])
        : null
    const invoiceId = invoiceValues[0] ? invoiceValues[0].id : props.values

    return (
        <>
            {invoiceValues[0] && (
                <InvoicePanel
                    title="Invoice from Marked"
                    hasLayout
                    onClose={props.onClose}
                >
                    <FullInvoiceForm
                        values={
                            invoiceValues[0] ? invoiceValues[0] : props.values
                        }
                        projectId={props.projectId}
                        {...props}
                    />
                </InvoicePanel>
            )}
        </>
    )
}

export function useInvoiceAutoGenerator(projectId, isPo) {
    const invoiceConfig = useInvoiceConfig(projectId, isPo)

    const useInvoices = useEntityFromConfig({
        ...invoiceConfig,
        baseUrl: isPo
            ? config.api.baseUrl + "/autoGenPos"
            : config.api.baseUrl + "/autoGenInvoices",
    })
    return async () => {
        const id = uuidv4()
        await useInvoices.dispatch.upsert({
            id: id,
            projectId: projectId,
            dueDate: Date(),
            subTotal: 0,
            taxAmount: 0,
            total: 0,
            paid: 0,
            createdAt: Date(),
            note: "",
            status: "draft",
            kind: isPo ? "po" : "invoice",
        })
        return id
    }
}
