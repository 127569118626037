import React from "react"

import { client as clientValdator } from "@prospecer/schema"
import config from "../config"

import { EntityList, EntityForm } from "./Entities"

export const clientConfig = {
    baseUrl: config.api.baseUrl + "/clients",
    type: "CLIENTS",
    stateKey: "clients",
    name: "Clients",
    nameSingle: "Client",
    readScope: "read:clients",
    writeScope: "write:clients",
    defaultSortKey: "name",
    listColumns: [
        {
            key: "name",
            title: "Client",
        },
        {
            key: "contact.name",
            title: "Contact name",
        },
        {
            key: "contact.phone",
            title: "Phone Number",
        },
    ],
    formFields: [
        {
            label: "Client",
            key: "name",
            type: "text",
        },

        {
            label: "Address",
            key: "contact.address",
            type: "text",
            option: "multiline",
        },
        {
            label: "Country",
            key: "contact.country",
            type: "country",
        },
        {
            label: "City",
            key: "contact.city",
            type: "text",
        },
        {
            label: "State",
            key: "contact.state",
            type: "state",
            coupledValue: "contact.country",
        },
        {
            label: "Zip/Postal",
            key: "contact.postal",
            type: "text",
        },
        {
            label: "Primary Contact",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Name",
            key: "contact.name",
            type: "text",
        },
        {
            label: "Phone",
            key: "contact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },
        {
            label: "Mobile",
            key: "contact.mobile",
            extension: false,
            type: "tel",
            coupledValue: "contact.country",
        },
        {
            label: "Email",
            key: "contact.email",
            type: "email",
        },
        {
            label: "Secondary Contact",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Name",
            key: "secondaryContact.name",
            type: "text",
        },
        {
            label: "Phone",
            key: "secondaryContact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },
        {
            label: "Mobile",
            key: "secondaryContact.mobile",
            extension: false,
            type: "tel",
            coupledValue: "contact.country",
        },
        {
            label: "Email",
            key: "secondaryContact.email",
            type: "email",
        },
    ],
    emptyFormValue: { contact: {}, secondaryContact: {} },
    validator: clientValdator,
    Form: ClientForm,
    allowDelete: true,
}

export function usePublicClientConfig(projectSharesId, studioId) {
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/clients?id=" +
            projectSharesId +
            "&studioId=" +
            studioId,
        type: "CLIENTS",
        stateKey: "clients",
        name: "Clients",
        nameSingle: "Client",
        readScope: "read:clients",
        writeScope: "write:clients",
        defaultSortKey: "name",
        insecure: true,
    }
}

export function ClientsList(props) {
    return <EntityList config={clientConfig} />
}

export function ClientForm(props) {
    return <EntityForm {...props} config={clientConfig} />
}
