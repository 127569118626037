import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import DataDog from "react-datadog"
import config from "./config"
import { ThemeProvider } from "@fluentui/react"
import themes from "./themes"

ReactDOM.render(
    <DataDog
        applicationId={config.dataDog.applicationId}
        clientToken={config.dataDog.clientToken}
        service={config.dataDog.service}
        enabled={!!config.dataDog.enabled}
        sessionReplayRecording
    >
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </DataDog>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
