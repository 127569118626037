import React, { useEffect, useMemo, useRef, useState } from "react"
import { specification as specificationValidator } from "@prospecer/schema"
import config from "../config"
import { useEntityFromConfig } from "../hooks/Entity"
import { useProjectConfig } from "./Projects"
import { useConfig as useProjectSharesConfig } from "./ProjectShares"

import { EntityList, EntityForm } from "./Entities"

import { usePublicTagTypes, useTagTypeConfig } from "./TagTypes"
import { supplierConfig, SupplierForm, usePublicSupplier } from "./Suppliers"
import { calPercnt, padNumber } from "../utils"
import {
    ContextualMenuItemType,
    DefaultButton,
    Icon,
    ImageFit,
    Pivot,
    PivotItem,
    PrimaryButton,
    ResponsiveMode,
    Stack,
    Text,
    useResponsiveMode,
    useTheme,
} from "@fluentui/react"
import JSZip from "jszip"

import { v4 as uuidv4 } from "uuid"

import { Images } from "../controls/Image"
import { tagTypeTypes as _tagTypeTypes } from "./TagTypes"
import Panel from "../layouts/Panel"
import { StudioForm, useStudioConfig } from "./Studios"
import { clientConfig } from "./Clients"
import { userConfig } from "./Users"
import UpsertPanel from "../layouts/UpsertPanel"
import { useDataDogRum } from "react-datadog"
import { IssuancesList, useIssuanceConfig } from "./Issuances"
import { useHistory } from "react-router-dom"
import MiniList from "../layouts/MiniList"
import { infoConfig } from "./Infos"
import UpsertModal from "../layouts/UpsertModal"
import Form from "../layouts/Form"
import fileDownload from "js-file-download"
import axios from "axios"
import Download from "./Download"
import ProPill from "./ProPill"
import {
    TaxesForm,
    taxesConfig,
    useTaxCalculator,
    useTaxControl,
} from "./Taxes"
import TagTypeWraper from "./TagTypeWrapper"
import { useNotification } from "../hooks/Notifications"
import { SpecificationDescription } from "./SpecificationDescription"
import { SpecCard } from "../layouts/SpecCard"
import BudgetPanel from "./BudgetPanel"
import { useDebounce } from "../hooks/useDebounce"
import { debounce } from "lodash"

export function tagFormatter(useTag, tagId, number) {
    const r = useTag.filter([{ field: "id", type: "one", value: tagId }])
    if (!r[0]) {
        return null
    }
    return r[0].name + "." + padNumber(number, 2)
}

export const procurementStatusTypes = [
    { key: "__EMPTY", text: "Empty", filterOnly: true },
    { key: null, text: "", noFilter: true },

    { key: "ordered", text: "Ordered" },
    { key: "arrived", text: "Arrived" },
    { key: "delivered", text: "Delivered" },
    { key: "installed", text: "Installed" },
]
export const billingStatusTypes = [
    { key: "__EMPTYB", text: "Empty", filterOnly: true },
    { key: null, text: "", noFilter: true },
    { key: "quoted", text: "Quoted" },
    { key: "approved", text: "Approved" },
    { key: "deposit_paid", text: "Deposit Paid" },

    { key: "paid", text: "Paid" },
]

export function columnMobileTagAndImage(useTag, tagFields, setTagFilter) {
    return {
        key: "tagTypeId",
        title: "Tag",
        maxWidth: 100,
        additionalKeys: [
            "tagNumber",
            "invoiceStatus",
            "marks",
            "poStatus",
            "images",
            "name",
        ],
        formatter(value, fields) {
            return (
                <Stack>
                    <div>
                        <b>{tagFormatter(useTag, value, fields[0])}</b>
                    </div>
                    <div>{fields[5]}</div>
                    <Images
                        imageFit={ImageFit.cover}
                        value={fields[4]}
                        nameNotNeeded
                        noModal
                    />
                </Stack>
            )
        },
    }
}

export function columnMobileMeta(useTag) {
    return {
        key: "meta",
        title: "Description",
        additionalKeys: ["tagTypeId", "archived", "location"],

        formatter: (value, i) => {
            const tagFields = value.tagTypeFields
            const tagTypeId = i[0]
            const archived = i[1]
            return (
                <Stack>
                    <b>Location</b>
                    <div>{i[2]}</div>
                    <br />
                    <SpecificationDescription
                        tagFields={tagFields}
                        tagTypeId={tagTypeId}
                        archived={archived}
                        value={value}
                    />
                </Stack>
            )
        },
    }
}

export function columnItem(sort = {}, setSort = () => {}) {
    return {
        key: "name",
        title: "Item",
        maxWidth: 100,
        isSorted: sort.type === "name",
        isSortedDescending: sort.direction === "DSC",
        filterValues: (fullRows) => {
            return [
                {
                    key: "divider_2",
                    text: "Sort",
                    itemType: ContextualMenuItemType.Header,
                },
                {
                    key: "__asc",
                    text: "Ascending",
                    canCheck: true,
                    isChecked: sort.type === "name" && sort.direction === "ASC",
                    iconProps: { iconName: "SortUp" },
                    onClick: () => {
                        setSort({ type: "name", direction: "ASC" })
                    },
                },
                {
                    key: "__asc",
                    text: "Descending",
                    canCheck: true,
                    isChecked: sort.type === "name" && sort.direction === "DSC",
                    iconProps: { iconName: "SortDown" },
                    onClick: () => {
                        setSort({ type: "name", direction: "DSC" })
                    },
                },
            ]
        },
    }
}

export function columnTagType(
    useTag,
    tagFilter,
    setTagFilter,
    sort = {},
    setSort = () => {}
) {
    return {
        key: "tagTypeId",
        title: "Tag",
        maxWidth: 50,
        isFiltered: tagFilter ? tagFilter.length > 0 : false,
        isSorted: sort.type === "tag" && sort.direction !== "ASC",
        isSortedDescending: sort.direction === "DSC",
        additionalKeys: [
            "tagNumber",
            "invoiceStatus",
            "marks",
            "poStatus",
            "price",
            "budget",
        ],
        formatter: (value, fields) => {
            const marks = []
            if (fields[2]) {
                for (const mark of fields[2]) {
                    marks.push(
                        <ProPill
                            key={mark}
                            circle
                            color={config.pastelAlternatives[mark]}
                            label={mark.charAt(0).toUpperCase() + mark.slice(1)}
                        />
                    )
                }
            }
            return (
                <Stack>
                    <div>{tagFormatter(useTag, value, fields[0])}</div>
                    {fields[1] === "invoiced" &&
                        config.features.procurement && (
                            <>
                                <ProPill
                                    color={config.nonThemeColors.green}
                                    label="Invoiced"
                                ></ProPill>
                            </>
                        )}
                    {fields[1] === "pending" && config.features.procurement && (
                        <>
                            <ProPill
                                color={config.nonThemeColors.yellow}
                                label="Marked for invoice"
                            ></ProPill>
                        </>
                    )}

                    {fields[3] === "ordered" && config.features.procurement && (
                        <>
                            <ProPill
                                color={config.nonThemeColors.green}
                                label="PO Sent"
                            ></ProPill>
                        </>
                    )}
                    {fields[3] === "pending" && config.features.procurement && (
                        <>
                            <ProPill
                                color={config.nonThemeColors.black}
                                label="Marked for PO"
                            ></ProPill>
                        </>
                    )}
                    {fields[4] && fields[5] && fields[4] > fields[5] && (
                        <>
                            <ProPill
                                color={config.nonThemeColors.red}
                                label="Over Budget"
                            >
                                <Icon
                                    styles={{
                                        root: {
                                            color: config.nonThemeColors.yellow,
                                            width: "20px",
                                        },
                                    }}
                                    iconName="pOverBudget"
                                />
                            </ProPill>
                        </>
                    )}
                    <Stack horizontal>{marks}</Stack>
                </Stack>
            )
        },
        data: (value, fields) => {
            return tagFormatter(useTag, value, fields[0])
        },

        filterValues: (fullRows) => {
            if (tagFilter === undefined) {
                return []
            }
            const v = []
            const v2 = []
            v.push({
                key: "divider_2",
                text: "Sort",
                iconProps: { iconName: "Sort" },
                isChecked: sort.type === "tag",
                subMenuProps: {
                    items: [
                        {
                            key: "__asc",
                            text: "Ascending",
                            canCheck: true,
                            isChecked:
                                sort.type === "tag" && sort.direction === "ASC",
                            iconProps: { iconName: "SortUp" },
                            onClick: () => {
                                setSort({ type: "tag", direction: "ASC" })
                            },
                        },
                        {
                            key: "__asc",
                            text: "Descending",
                            canCheck: true,
                            isChecked:
                                sort.type === "tag" && sort.direction === "DSC",
                            iconProps: { iconName: "SortDown" },
                            onClick: () => {
                                setSort({ type: "tag", direction: "DSC" })
                            },
                        },
                    ],
                },
            })

            v2.push({
                key: "divider_2",
                itemType: ContextualMenuItemType.Header,
                text: "Select Multiple Items",
            })
            v2.push({
                key: "__all",
                text: "Clear",
                canCheck: true,
                iconProps: { iconName: "ClearFilter" },
                onClick: () => {
                    setTagFilter([])
                },
                isChecked: tagFilter ? false : true,
            })

            const tags = useTag.filter([])
            for (const tag of tags) {
                if (fullRows.findIndex((e) => e.tagTypeId == tag.id) !== -1) {
                    v2.push({
                        key: "tag-" + tag.id,
                        text: tag.name,
                        canCheck: true,
                        onClick: () => {
                            const i = tagFilter.indexOf(tag.id)
                            if (i !== -1) {
                                tagFilter.splice(i, 1)
                                setTagFilter(tagFilter)
                            } else {
                                setTagFilter(tagFilter.concat([tag.id]))
                            }
                        },
                        isChecked: tagFilter.indexOf(tag.id) !== -1,
                    })
                }
            }
            v.push({
                key: "filtering",
                text: "Filtering",
                iconProps: { iconName: "Filter" },
                canCheck: true,
                isChecked: tagFilter.length > 0,
                subMenuProps: {
                    items: v2,
                },
            })

            return v
        },
    }
}

export function columnImages() {
    return {
        key: "images",
        title: "Image(s)",
        exportable: false,
        formatter: (value, fields) => {
            return (
                <Images imageFit={ImageFit.cover} value={value} nameNotNeeded />
            )
        },
        data: (value, field) => {
            if (!value) {
                return []
            }
            const images = []

            for (const i of value) {
                images.push(config.images.baseUrl + i)
            }
            return images
        },
        maxWidth: 100,
    }
}

export function columnMeta(useTag) {
    return {
        key: "meta",
        title: "Description",
        additionalKeys: ["tagTypeId", "archived"],
        //I don't even want to talk about how dumb this is (FLUENT!!!!!)
        minWidth: 350,
        maxWidth: 350,

        data: (value, i) => {
            const tagFields = value.tagTypeFields
            const tagTypeId = i[0]
            const archived = i[1]
            const tag = useTag.filter([
                { field: "id", type: "one", value: tagTypeId },
            ])[0]
            if (!tag) {
                return []
            }

            let output = []
            for (const field of tag.fields) {
                const value = tagFields[field.key]
                if (value) {
                    output.push({ name: field.name, value: value })
                }
            }
            for (const [key, v] of Object.entries(value.custom)) {
                output.push({
                    key: "tag2-" + key,
                    name: v.name,
                    value: v.value,
                })
            }
            return output
        },
        exportFormatter: (value, values) => {
            const tagFields = value.tagTypeFields
            const tagTypeId = values.tagTypeId
            const archived = values.archived

            const tag = useTag.filter([
                { field: "id", type: "one", value: tagTypeId },
            ])[0]

            let output = ""
            for (const field of tag.fields) {
                const value = tagFields[field.key]
                if (value) {
                    output = output + field.name + ": " + value + "\n"
                }
            }

            return output
        },

        formatter: (value, i) => {
            const tagFields = value.tagTypeFields
            const tagTypeId = i[0]
            const archived = i[1]
            return (
                <SpecificationDescription
                    tagFields={tagFields}
                    tagTypeId={tagTypeId}
                    archived={archived}
                    value={value}
                />
            )
        },
    }
}

export function columnNotes() {
    return {
        key: "notes",
        title: "Notes",
        additionalKeys: ["privateNotes"],
        formatter: (value, values) => {
            return (
                <div style={{ whiteSpace: "break-spaces" }}>
                    {value}
                    {values[0] && (
                        <div style={{ whiteSpace: "break-spaces" }}>
                            <div
                                style={{
                                    paddingTop: "5px",
                                }}
                            >
                                <b>INTERNAL:</b>
                            </div>
                            {values[0]}
                        </div>
                    )}
                </div>
            )
        },
        exportFormatter: (value) => {
            return value
        },
        data: (value) => {
            return value
        },
        minWidth: 200,
    }
}

export function columnLeadTime(
    isPublic = false,
    statusFilter,
    setStatusFilter,
    printPrice,
    studio,
    billingFilter,
    setBillingFilter,
    taxCalculator,
    budgetFilter,
    setBudgetFilter
) {
    return {
        key: "leadtime",
        printTitle: "Leadtime",
        title: isPublic ? "Leadtime" : "Pricing/Leadtime",
        additionalKeys: [
            "price",
            "unit",
            "quantity",
            "procurementStatus",
            "billingStatus",
            "budget",
        ],
        isFiltered: statusFilter || billingFilter ? true : false,

        filterValues: isPublic
            ? false
            : (fullRows, rows) => {
                  if (isPublic) {
                      return []
                  }

                  const v = []

                  if (budgetFilter && setBillingFilter) {
                      v.push({
                          key: "__over_budget",
                          text: "Over Budget Items",
                          canCheck: true,
                          onClick: () => {
                              setStatusFilter(
                                  budgetFilter === "over" ? null : "over"
                              )
                          },
                          isChecked: budgetFilter === "over" ? true : false,
                      })
                  }
                  v.push({
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Header,
                      text: "Filter by Procurement Status",
                  })
                  v.push({
                      key: "__all_status",
                      text: "All",
                      canCheck: true,
                      onClick: () => {
                          setStatusFilter(null)
                      },
                      isChecked: statusFilter === null ? true : false,
                  })
                  for (const st of procurementStatusTypes) {
                      if (st.noFilter) {
                          continue
                      }
                      v.push({
                          key: "pst-" + st.key,
                          text: st.text,
                          canCheck: true,
                          onClick: () => {
                              setStatusFilter(st.key)
                          },
                          isChecked: statusFilter === st.key,
                      })
                  }

                  v.push({
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Header,
                      text: "Filter by Biling Status",
                  })
                  v.push({
                      key: "__all_status",
                      text: "All",
                      canCheck: true,
                      onClick: () => {
                          setBillingFilter(null)
                      },
                      isChecked: billingFilter === null ? true : false,
                  })
                  for (const st of billingStatusTypes) {
                      if (st.noFilter) {
                          continue
                      }
                      v.push({
                          key: "pst-" + st.key,
                          text: st.text,
                          canCheck: true,
                          onClick: () => {
                              setBillingFilter(st.key)
                          },
                          isChecked: billingFilter === st.key,
                      })
                  }

                  return v
              },
        exportFormatter: (value, values) => {
            let output = ""
            if (value) {
                output = output + "Leadtime: " + value + "\n"
            }
            if (values.price) {
                output =
                    output +
                    "Price: " +
                    (values.price / 100).toFixed(2) +
                    (values.unit ? " / " + values.unit : "") +
                    "\n"
            }
            if (values.quantity) {
                output =
                    output + "Quantity: " + values.quantity + " " + values.unit
            }

            return output
        },
        data: (value, values) => {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            })
            const price = values[0]
            const unit = values[1]
            const quantity = values[2]
            const procurementStatus = values[3]
            const billingStatus = values[4]
            let output = []
            if (value) {
                output.push({
                    name: "Leadtime",
                    value: value,
                    isLeadtime: true,
                })
            }
            if (values.price) {
                output.push({
                    name: "Price",
                    value:
                        (price / 100).toFixed(2) + (unit ? " / " + unit : ""),
                })
            }
            if (values.quantity) {
                output.push({ name: "Quantity", value: quantity, isInfo: true })
            }

            if (values[0]) {
                output.push({
                    name: "Price",
                    value:
                        formatter.format((values[0] / 100).toFixed(2)) +
                        (values[1] ? " / " : " ") +
                        values[1],
                    isPrice: true,
                })
            }
            if (values[0] > 0 && values[2]) {
                output.push({
                    name: "Total",
                    value: formatter.format(
                        ((values[0] * values[2]) / 100).toFixed(2)
                    ),
                    isPrice: true,
                })
            }
            output.push({
                name: "Quantity",
                value: values[2] ? values[2] + " " + values[1] : "",
                isPrice: false,
                isInfo: true,
            })

            return output
        },
        printFormatter(value, values) {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            })
            if (printPrice) {
                return (
                    <>
                        {value ? "Leadtime: " + value : ""}
                        <br />
                        {values[0]
                            ? "Price: " +
                              formatter.format((values[0] / 100).toFixed(2)) +
                              " / " +
                              values[1]
                            : ""}
                        <br />
                        {values[0] > 0 && values[2] && (
                            <>
                                Total:
                                {formatter.format(
                                    ((values[0] * values[2]) / 100).toFixed(2)
                                )}
                                <br />
                            </>
                        )}

                        {values[2] ? "Quantity: " + values[2] : ""}
                        <br />
                    </>
                )
            }
            if (value) {
                return "Leadtime: " + value
            } else {
                return ""
            }
        },
        formatter: (value, values) => {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            })
            const columns = [
                {
                    key: "name",
                    name: "Name",
                    fieldName: "name",
                    maxWidth: 70,
                    minWidth: 70,
                    isMultiline: false,
                },
                {
                    key: "value",
                    name: "Value",
                    fieldName: "value",
                    isMultiline: false,
                },
            ]
            const items = []
            if (value) {
                items.push({ key: 1, name: "Leadtime", value: value })
            }
            if (values[0]) {
                const v =
                    (values[0] / 100).toFixed(2) + values[1]
                        ? (values[0] / 100).toFixed(2) + " / " + values[1]
                        : ""

                items.push({
                    name: "Price",
                    value:
                        formatter.format((values[0] / 100).toFixed(2)) +
                        values[1]
                            ? formatter.format((values[0] / 100).toFixed(2)) +
                              " / " +
                              values[1]
                            : "",
                })
            }
            if (values[2]) {
                items.push({
                    name: "Quantity",
                    value: values[2],
                })
            }
            if (values[0] && values[2]) {
                items.push({
                    name: "Total",
                    value: formatter.format(
                        ((values[0] * values[2]) / 100).toFixed(2)
                    ),
                })
            }
            if (values[3]) {
                let pstValue = ""

                for (const pct of procurementStatusTypes) {
                    if (pct.key === values[3]) {
                        pstValue = pct.text
                    }
                }
                items.push({
                    name: "Status",
                    value: pstValue,
                })
            }
            if (values[4]) {
                let bstValue = ""

                for (const pct of billingStatusTypes) {
                    if (pct.key === values[4]) {
                        bstValue = pct.text
                    }
                }
                items.push({
                    name: "Billing",
                    value: bstValue,
                })
            }
            /*
            if (values[5]) {
                items.push({
                    name: "$",
                    value: formatter.format(
                        ((values[5] - value[0]) / 100).toFixed(2)
                    ),
                })
            }
            */

            return <MiniList items={items} columns={columns} />
        },
    }
}

export function columnSupplier(useSupplier, supplierFilter, setSupplierFilter) {
    return {
        key: "supplierId",
        title: "Supplier",
        filterValues: (fullRows) => {
            const v = [
                {
                    key: "__all",
                    text: "All",
                    canCheck: true,
                    onClick: () => {
                        setSupplierFilter(null)
                    },
                    isChecked: supplierFilter ? false : true,
                },
            ]
            let ids = [...new Set(fullRows.map((r) => r.supplierId))]
            ids = ids.filter(Boolean)

            const suppliers = useSupplier.filter([
                { type: "any", field: "id", value: ids },
            ])
            for (const f of suppliers) {
                v.push({
                    key: f.id,
                    text: f.name,
                    canCheck: true,
                    onClick: () => {
                        setSupplierFilter(f.id)
                    },
                    isChecked: supplierFilter === f.id,
                })
            }
            return v
        },
        isFiltered: supplierFilter ? true : false,
        data: (value, values) => {
            const r = useSupplier.filter([
                { field: "id", type: "one", value: value },
            ])
            if (!r[0]) {
                return ""
            }
            function bn(value) {
                if (value === null) {
                    return ""
                }
                return value
            }
            return (
                bn(r[0].name) +
                "\n" +
                bn(r[0].contact.name) +
                "\n" +
                bn(r[0].contact.email) +
                "\n" +
                bn(r[0].contact.phone) +
                "\n" +
                bn(r[0].secondaryContact.name) +
                "\n" +
                bn(r[0].secondaryContact.email) +
                "\n" +
                bn(r[0].secondaryContact.phone)
            )
        },
        exportFormatter: (value, values) => {
            const r = useSupplier.filter([
                { field: "id", type: "one", value: value },
            ])
            if (!r[0]) {
                return ""
            }
            function bn(value) {
                if (value === null) {
                    return ""
                }
                return value
            }
            return (
                bn(r[0].name) +
                "\n" +
                bn(r[0].contact.name) +
                "\n" +
                bn(r[0].contact.email) +
                "\n" +
                bn(r[0].contact.phone) +
                "\n" +
                bn(r[0].secondaryContact.name) +
                "\n" +
                bn(r[0].secondaryContact.email) +
                "\n" +
                bn(r[0].secondaryContact.phone)
            )
        },
        formatter: (value, fields) => {
            const r = useSupplier.filter([
                { field: "id", type: "one", value: value },
            ])
            if (!r[0]) {
                return null
            }

            return (
                <>
                    <Text variant="small">{r[0].name}</Text>
                    <br />
                    {r[0].contact.name && (
                        <>
                            <Text variant="small">{r[0].contact.name}</Text>
                            <br />
                        </>
                    )}
                    {r[0].contact.email && (
                        <>
                            <Text variant="small">{r[0].contact.email}</Text>
                            <br />
                        </>
                    )}
                    {r[0].contact.phone && (
                        <>
                            <Text
                                styles={{ root: { whiteSpace: "nowrap" } }}
                                variant="small"
                            >
                                {r[0].contact.phone}
                            </Text>
                            <br />
                        </>
                    )}
                    {r[0].secondaryContact.name && (
                        <>
                            <Text variant="small">
                                {r[0].secondaryContact.name}
                            </Text>
                            <br />
                        </>
                    )}
                    {r[0].secondaryContact.email && (
                        <>
                            <Text variant="small">
                                {r[0].secondaryContact.email}
                            </Text>
                            <br />
                        </>
                    )}
                    {r[0].secondaryContact.phone && (
                        <>
                            <Text
                                styles={{ root: { whiteSpace: "nowrap" } }}
                                variant="small"
                            >
                                {r[0].secondaryContact.phone}
                            </Text>
                            <br />
                        </>
                    )}
                </>
            )
        },
    }
}

export function columnLocation(
    locationFilter,
    setLocationFilter,
    isMultiFilter = false
) {
    return {
        key: "location",
        title: "Location",
        maxWidth: 100,
        isFiltered: isMultiFilter
            ? locationFilter.length > 0
            : locationFilter
            ? true
            : false,
        formatter: (value) => {
            if (!value) {
                return ""
            }
            const values = value.split(",")
            return (
                <Stack>
                    {values.map((v) => {
                        return <span>{v}</span>
                    })}
                </Stack>
            )
        },
        data: (value, allValues) => {
            return value
        },
        exportFormatter: (value, allValues) => {
            return value
        },
        filterValues: (fullRows) => {
            const v = []
            const found = []

            if (isMultiFilter) {
                v.push({
                    key: "divider_2",
                    itemType: ContextualMenuItemType.Header,
                    text: "Select Multiple Items",
                })
            }
            v.push({
                key: "__all",
                text: isMultiFilter ? "Clear" : "ALL",
                canCheck: true,
                onClick: (ev) => {
                    setLocationFilter(isMultiFilter ? [] : "")
                },
                isChecked: isMultiFilter ? false : locationFilter === "",
            })

            const _list = {}
            for (const f1 of fullRows) {
                for (const f of f1.location.split(",").map((e) => e.trim())) {
                    _list[f.toUpperCase()] = f.toUpperCase()
                }
            }
            const list = Object.values(_list).sort()

            for (const f of list) {
                if (found.includes(f.toUpperCase())) {
                    continue
                }
                if (f.trim() === "") {
                    continue
                }
                found.push(f.toUpperCase())
                v.push({
                    key: f.toUpperCase(),
                    text: f.toUpperCase(),
                    canCheck: true,
                    onClick: (ev) => {
                        if (isMultiFilter) {
                            const i = locationFilter.indexOf(f.toUpperCase())
                            if (i !== -1) {
                                locationFilter.splice(i, 1)
                                setLocationFilter(locationFilter)
                            } else {
                                setLocationFilter(
                                    locationFilter.concat([f.toUpperCase()])
                                )
                            }
                        } else {
                            setLocationFilter(f.toUpperCase())
                        }
                    },
                    isChecked: isMultiFilter
                        ? locationFilter.indexOf(f.toUpperCase()) !== -1
                        : f.toUpperCase() === locationFilter.toUpperCase(),
                })
            }

            return v
        },
    }
}

export function useFormFiels(pub = false) {
    let location = {
        label: "Location",
        key: "location",
        type: "text",
    }
    const taxControl = useTaxControl("taxIds", "Tax", pub)
    return (
        useTag,
        useSupplier,
        supplierConfig,
        useSpecification = null,
        projectId = null,
        studio = null,
        pub = false,
        plUpfront = false
    ) => {
        const pl = [
            {
                label: "Budget",
                type: "money",
                key: "budget",
                publicHide: true,
                isPricing: true,
            },
            {
                label: "Price/Leadtime",
                type: "separator",
                displayOnly: true,
            },

            {
                label: "Leadtime",
                type: "text",
                key: "leadtime",
            },
            {
                ...(pub
                    ? {
                          type: "group",
                          isPricing: true,
                          publicHide: true,
                          fields: [
                              {
                                  label: "Trade Price",
                                  type: "money",
                                  key: "tradePrice",
                                  publicHide: true,
                                  isPricing: true,
                              },
                              {
                                  label: "Unit",
                                  type: "text",
                                  key: "unit",
                                  prefix: "per",
                                  isPricing: true,
                              },
                          ],
                      }
                    : {
                          label: "Trade Price",
                          type: "money",
                          key: "tradePrice",
                          publicHide: true,
                          isPricing: true,
                      }),
            },
            {
                ...(pub
                    ? {
                          type: "group",
                          isPricing: true,
                          fields: [
                              {
                                  label: "Retail Price",
                                  type: "money",
                                  key: "retailPrice",
                                  publicHide: true,
                                  isPricing: true,
                              },
                              {
                                  label: "Unit",
                                  type: "text",
                                  key: "unit",
                                  prefix: "per",
                                  isPricing: true,
                              },
                          ],
                      }
                    : {
                          label: "Retail Price",
                          type: "money",
                          key: "retailPrice",
                          isPricing: true,
                      }),
            },

            {
                type: "group",
                isPricing: true,
                supplierHide: true,
                fields: [
                    {
                        label: "Client Price",
                        type: "money",
                        key: "price",
                        isPricing: true,
                        supplierHide: true,
                    },

                    {
                        label: "Unit",
                        type: "text",
                        key: "unit",
                        prefix: "per",
                        isPricing: true,
                    },
                ],
            },
            {
                ...taxControl,
            },
            {
                label: "Quantity",
                type: "number",
                key: "quantity",
                allowFloat: true,
            },
            {
                label: "Procurement Status",
                type: "dropdown",
                options: procurementStatusTypes.filter((e) => {
                    return !e.filterOnly
                }),
                key: "procurementStatus",
            },
            {
                label: "Billing Status",
                type: "dropdown",
                options: billingStatusTypes.filter((e) => {
                    return !e.filterOnly
                }),
                key: "billingStatus",
                isPricing: true,
            },
            {
                label: "",
                type: "separator",
                displayOnly: true,
            },
        ]

        if (useSpecification && projectId) {
            location = {
                label: "Location",
                key: "location",
                type: "picker",
                descr: "Locations",
                tags: () => {
                    const allSpecs = useSpecification.filter([
                        { field: "projectId", type: "eq", value: projectId },
                    ])
                    const parts = []

                    for (const s of allSpecs) {
                        parts.push(
                            ...s.location
                                .replace(", and ", ",")
                                .replace(" and ", ",")
                                .split(",")
                        )
                    }

                    return [
                        ...new Set(
                            parts.map((e) => {
                                return e.trim().toUpperCase()
                            })
                        ),
                    ]
                },
            }
        }
        return [
            {
                type: "component",
                Component: TagTypeWraper,
                props: { projectId: projectId },
                fields: [
                    {
                        label: "Tag Type",
                        key: "tagTypeId",
                        type: "entity",
                        descrKeyPath: "name",
                        detailsKeyPath: "description",
                        valueKeyPath: "id",
                        options: useTag.filter([]),
                        disabledOnUpdate: true,
                    },

                    {
                        label: "Tag Number",
                        key: "tagNumber",
                        type: "number",
                        autoincriment: true,
                        min: 1,
                        groupKey: "tagTypeId",
                    },
                ],
            },
            ...(plUpfront ? pl : []),
            {
                label: "Item",
                key: "name",
                type: "string",
            },
            {
                label: "Images",
                key: "images",
                type: "image",
                pastable: true,
                allowDelete: true,
            },

            ...(useSupplier && supplierConfig
                ? [
                      {
                          label: "Supplier",
                          key: "supplierId",
                          type: "entity",
                          nullable: true,
                          descrKeyPath: "name",
                          valueKeyPath: "id",
                          options: useSupplier.filter([
                              { type: "_sort", key: "name" },
                          ]),
                          Form: (props) => {
                              return (
                                  <SupplierForm
                                      {...props}
                                      config={supplierConfig}
                                  />
                              )
                          },
                      },
                  ]
                : []),
            {
                ...location,
            },

            {
                label: "Description",
                key: "meta",
                type: "meta",
                coupledValue: "tagTypeId",
            },
            {
                label: "Custom Fields",
                addLabel: "Add Field",
                key: "meta.custom",
                type: "array",
                emptyFormValue: () => {
                    return { key: uuidv4() }
                },
                fields: [
                    {
                        label: "Name",
                        key: "name",
                        type: "text",
                    },
                    {
                        label: "Value",
                        key: "value",
                        type: "text",
                    },
                ],
            },
            ...(!plUpfront ? pl : []),
            {
                label: "URL",
                key: "url",
                type: "info",
                clickableUrl: true,
            },
            {
                label: "Spec Sheets",
                key: "sheets",
                type: "image",
                anyFiles: true,
                allowDelete: true,
            },
            {
                label: "Notes",
                key: "notes",
                type: "text",
                option: "multiline",
            },
            {
                label: "Not Shared",
                type: "separator",
                displayOnly: true,
                publicHide: true,
                supplierHide: true,
            },
            {
                label: "Internal Files",
                key: "privateFiles",
                type: "image",
                anyFiles: true,
                publicHide: true,
                supplierHide: true,
                allowDelete: true,
            },
            {
                label: "Internal Notes",
                key: "privateNotes",
                type: "text",
                option: "multiline",
                publicHide: true,
                supplierHide: true,
            },
        ]
    }
}

export function schedulesFilter(
    checkedFilter,
    setCheckFilter,
    checkedFitlerName,
    setCheckFilterName,
    options
) {
    const tagTypeTypes = [
        {
            key: "all",
            text: "All Schedules",
            canCheck: true,
            checked: checkedFilter === null,
        },
    ]
    for (const _i of _tagTypeTypes) {
        const i = { ..._i }
        if (i.key === checkedFilter) {
            i.checked = true
        }
        i.canCheck = true
        tagTypeTypes.push(i)
    }
    return {
        key: "filterTagType",
        doNotHide: true,
        text: checkedFilter ? checkedFitlerName : "All Schedules",
        iconProps: { iconName: "Filter" },
        ariaLabel: "Filter",

        subMenuProps: {
            onItemClick: (e, item) => {
                if (item.key === "all") {
                    setCheckFilter(null)
                    setCheckFilterName(null)
                } else {
                    setCheckFilterName(item.text)
                    setCheckFilter(item.key)
                }
                if (item.key === "__group" && options.onGroupSchedules) {
                    options.onGroupSchedules(true)
                } else if (options.onGroupSchedules) {
                    options.onGroupSchedules(false)
                }
            },
            items: options.noGroup
                ? [...tagTypeTypes]
                : [
                      ...tagTypeTypes,
                      {
                          key: "divider_1",
                          itemType: ContextualMenuItemType.Divider,
                      },
                      {
                          key: "__group",
                          text: "Group By Schedules",
                          canCheck: true,
                          checked: checkedFilter === "__group",
                      },
                  ],
        },
    }
}

export function useConfig(projectId, options = {}) {
    const useTag = useEntityFromConfig(useTagTypeConfig())
    const useSupplier = useEntityFromConfig(supplierConfig)
    const useProject = useEntityFromConfig(useProjectConfig())
    const [checkedFilter, setCheckFilter] = useState(null)
    const [checkedFitlerName, setCheckFilterName] = useState(null)
    const [search, setSearch] = useState("")
    const [supplierFilter, setSupplierFilter] = useState("")
    const [locationFilter, setLocationFilter] = useState([])
    const [statusFilter, setStatusFilter] = useState(null)
    const [billingFilter, setBillingFilter] = useState(null)
    const [budgetFilter, setBudgetFilter] = useState(null)
    const [sort, setSort] = useState({ type: "tag", direction: "ASC" })
    const currentResponsiveMode = useResponsiveMode(window.document)

    const [tagFilter, setTagFilter] = useState([])
    const studioConfig = useStudioConfig()
    const useStudio = useEntityFromConfig(studioConfig)
    const useInfo = useEntityFromConfig(infoConfig)
    const info = useInfo.filter([])[0]
    const formFields = useFormFiels()
    const taxCalculator = useTaxCalculator()

    useEffect(() => {
        const func = (e) => {
            debounce(() => {
                setSearch(e.detail)
            }, 200)()
        }
        window.document.addEventListener("globalSearch", func, false)

        return () => {
            window.document.removeEventListener(global, func)
        }
    }, [])
    const studio = info
        ? useStudio.filter([
              { field: "id", type: "eq", value: info.studioId },
          ])[0]
        : { boo: true }

    const issueConfig = useIssuanceConfig(projectId)
    const useIssuances = useEntityFromConfig(issueConfig)
    const issuances = useIssuances.filter([
        { field: "projectId", type: "eq", value: projectId },
        { type: "_sort", key: "createdAt", direction: "ASC" },
    ])

    const projects = useProject.filter([
        { field: "id", type: "one", value: projectId },
    ])
    const project = projects[0] ? projects[0] : {}

    const [listFilter, fullSetFilter] = useMemo(() => {
        const filter = projectId
            ? [
                  {
                      type: "eq",
                      field: "projectId",
                      value: options.issuanceId
                          ? options.issuanceId
                          : projectId,
                  },
              ]
            : []
        const sortedTagIds = useTag
            .filter([
                { type: "_sort", key: "name" },
                ...(checkedFilter === "__group"
                    ? [{ type: "_sort", key: "type" }]
                    : []),
            ])
            .map((t) => t.id)

        //Sort by the seconday sort first (opposite of sql)
        if (sort.type === "tag") {
            filter.push({
                type: "sort",
                field: "tagNumber",
                numeric: true,
                direction: sort.direction === "ASC" ? "ASC" : "DSC",
            })
            filter.push({
                type: "listSort",
                value:
                    sort.direction === "ASC"
                        ? [...sortedTagIds]
                        : [...sortedTagIds].reverse(),
                field: "tagTypeId",
            })
        } else if ((sort.type = "name")) {
            filter.push({
                type: "sort",
                field: "name",
                direction: sort.direction === "ASC" ? "ASC" : "DSC",
            })
        }
        const fullSetFilter = [...filter]

        if (checkedFilter && checkedFilter !== "__group") {
            const tags = useTag.filter([
                { type: "streq", field: "type", value: checkedFilter },
            ])

            filter.push({
                type: "in",
                field: "tagTypeId",
                value: tags.map((a) => a.id),
            })
        }
        if (search) {
            const supplierResults = useSupplier.filter([
                {
                    type: "or",
                    value: [
                        {
                            type: "ilike",
                            field: "name",
                            value: search,
                        },
                        {
                            type: "ilike",
                            field: "contact.name",
                            value: search,
                        },
                        {
                            type: "ilike",
                            field: "contact.email",
                            value: search,
                        },
                        {
                            type: "ilike",
                            field: "contact.phone",
                            value: search,
                        },
                    ],
                },
            ])

            const searchSupplierIeds = supplierResults.map((value) => {
                return value.id
            })

            let tagFilter = []

            if (search.includes(".")) {
                const parts = search.split(".")
                if (parts.length >= 2) {
                    const tags = useTag.filter([
                        { field: "name", type: "streq", value: parts[0] },
                    ])

                    if (tags[0]) {
                        tagFilter = [
                            {
                                type: "and",
                                value: [
                                    {
                                        type: "eq",
                                        field: "tagTypeId",
                                        value: tags[0]?.id,
                                    },
                                    {
                                        field: "tagNumber",
                                        type: "eq",
                                        value: parts[1],
                                    },
                                ],
                            },
                        ]
                    }
                }
            }

            const tagMatch = useTag.filter([
                { field: "name", type: "ilike", value: search },
            ])
            filter.push({
                type: "or",

                value: [
                    {
                        type: "ilike",
                        options: ["ignorecase"],
                        field: "name",
                        value: search,
                    },
                    { type: "ilike", field: "notes", value: search },
                    { type: "ilike", field: "location", value: search },
                    {
                        type: "objectLike",
                        field: "meta.tagTypeFields",
                        value: search,
                    },
                    {
                        type: "objectLike",
                        field: "meta.custom",
                        value: search,
                    },
                    {
                        type: "in",
                        field: "supplierId",
                        value: searchSupplierIeds,
                    },
                    {
                        type: "eq",
                        field: "tagTypeId",
                        value: tagMatch[0]?.id,
                    },
                    ...tagFilter,
                ],
            })
        }

        if (supplierFilter) {
            filter.push({
                type: "eq",
                field: "supplierId",
                value: supplierFilter,
            })
        }
        if (locationFilter.length > 0) {
            filter.push({
                type: "or",
                value: locationFilter.map((i) => {
                    return { type: "ilike", field: "location", value: i }
                }),
            })
        }

        if (tagFilter.length > 0) {
            filter.push({
                type: "or",
                value: tagFilter.map((i) => {
                    return { type: "eq", field: "tagTypeId", value: i }
                }),
            })
        }

        if (statusFilter !== null) {
            if (statusFilter === "__EMPTY") {
                filter.push({
                    type: "eq",
                    field: "procurementStatus",
                    value: null,
                })
            } else {
                filter.push({
                    type: "eq",
                    field: "procurementStatus",
                    value: statusFilter,
                })
            }
        }

        if (billingFilter !== null) {
            if (billingFilter === "__EMPTYB") {
                filter.push({
                    type: "eq",
                    field: "billingStatus",
                    value: null,
                })
            } else {
                filter.push({
                    type: "eq",
                    field: "billingStatus",
                    value: billingFilter,
                })
            }
        }

        return [filter, fullSetFilter]
    }, [
        checkedFilter,
        projectId,
        useTag,
        search,
        supplierFilter,
        locationFilter,
        options,
        statusFilter,
    ])

    const tagTypeTypes = useMemo(() => {
        const items = [
            {
                key: "all",
                text: "All Schedules",
                canCheck: true,
                checked: checkedFilter === null,
            },
        ]
        for (const _i of _tagTypeTypes) {
            const i = { ..._i }
            if (i.key === checkedFilter) {
                i.checked = true
            }
            i.canCheck = true
            items.push(i)
        }
        return items
    }, [checkedFilter])

    const listColumns = [
        columnTagType(useTag, tagFilter, setTagFilter, sort, setSort),
        columnItem(sort, setSort),
        columnImages(),
        columnLocation(locationFilter, setLocationFilter, true),
        columnMeta(useTag),
        columnSupplier(useSupplier, supplierFilter, setSupplierFilter),
        columnNotes(),
        columnLeadTime(
            false,
            statusFilter,
            setStatusFilter,
            options.printPrice,
            studio,
            billingFilter,
            setBillingFilter,
            taxCalculator
        ),
    ]
    const mobileListColumns = [
        columnMobileTagAndImage(useTag, tagFilter, setTagFilter),
        columnMobileMeta(useTag),
    ]
    return {
        baseUrl: config.api.baseUrl + "/specifications",
        type: "SPECIFICATIONS",
        stateKey: "specifications",
        partitionField: "projectId",
        partitionKey: projectId,
        name: null,
        printTitle: project.name,
        printFooter: studio ? studio.name : "",

        nameSingle: "Specification",
        readScope: "read:specifications",
        writeScope: "write:specifications",
        defaultSortKey: "tag",
        filter: listFilter,
        fullSetFilter: fullSetFilter,
        showEventsOnForm: true,

        PrintCover: () => {
            const useClient = useEntityFromConfig(clientConfig)
            const clients = useClient.filter([
                { field: "id", type: "one", value: project.clientId },
            ])
            return (
                <PrintCover
                    project={project}
                    studio={studio}
                    client={clients[0]}
                    issuanceId={options.issuanceId}
                />
            )
        },
        printTableFooter: (rows, columns, fullRows, filteredRows) => {
            if (options.printPrice !== 2) {
                return {}
            }
            let total = 0
            for (const r of filteredRows) {
                if (r.price > 0) {
                    total =
                        total + r.price * (r.quantity === null ? 1 : r.quantity)
                }
            }

            return { leadtime: "Total: $" + (total / 100).toFixed(2) }
        },

        additionalButtons: [
            schedulesFilter(
                checkedFilter,
                setCheckFilter,
                checkedFitlerName,
                setCheckFilterName,
                options
            ),
        ],

        listColumns:
            currentResponsiveMode <= ResponsiveMode.medium
                ? mobileListColumns
                : listColumns,
        mobile: currentResponsiveMode <= ResponsiveMode.medium,
        formFields: formFields(useTag, useSupplier, supplierConfig),

        emptyFormValue: () => {
            return {
                meta: { tagTypeFields: {}, custom: [] },
                projectId: projectId,
                taxIds: [],
                marks: [],
                __new: true,
            }
        },
        validator: specificationValidator,
        Form: (props) => {
            return <SpecificationsForm projectId={projectId} {...props} />
        },
        allowDelete: true,
        disableSort: true,
        groupCallback:
            checkedFilter === "__group"
                ? (values) => {
                      const tags = useTag.filter([
                          { field: "id", type: "eq", value: values.tagTypeId },
                      ])
                      if (tags[0]) {
                          return _tagTypeTypes.filter(
                              (tt) => tt.key === tags[0].type
                          )[0].text
                      } else {
                          return "unknown"
                      }
                  }
                : null,
    }
}

export function usePublicConfig(studioId, projectSharesId, options = {}) {
    const [supplierFilter, setSupplierFilter] = useState("")
    const currentResponsiveMode = useResponsiveMode(window.document)

    const formFields = useFormFiels(true)

    const useTag = useEntityFromConfig(
        usePublicTagTypes(studioId, projectSharesId)
    )
    const useSupplier = useEntityFromConfig(
        usePublicSupplier(studioId, projectSharesId)
    )
    const [locationFilter, setLocationFilter] = useState("")

    const filter = options.filter
        ? options.filter
        : [
              {
                  type: "sort",
                  field: "tagNumber",
                  numeric: true,
              },
          ]

    if (locationFilter) {
        filter.push({
            type: "ilike",
            field: "location",
            value: locationFilter,
        })
    }
    if (supplierFilter) {
        filter.push({
            type: "eq",
            field: "supplierId",
            value: supplierFilter,
        })
    }
    const listColumns = [
        columnTagType(useTag),
        {
            key: "name",
            title: "Item",
            maxWidth: 100,
        },
        columnImages(),
        columnLocation(locationFilter, setLocationFilter),
        columnMeta(useTag),
        columnSupplier(useSupplier, supplierFilter, setSupplierFilter),
        columnNotes(),
        columnLeadTime(true),
    ]
    const mobileListColumns = [
        columnMobileTagAndImage(useTag),
        columnMobileMeta(useTag),
    ]
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/specifications?id=" +
            projectSharesId +
            "&studioId=" +
            studioId,
        type: "SPECIFICATIONS",
        stateKey: "specifications",

        name: null,
        printTitle: "PROSPECER",
        nameSingle: "Specification",
        readScope: "read:specifications",
        writeScope: "write:specifications",
        defaultSortKey: "tagNumber",
        filter: filter,
        fullSetFilter: [],
        insecure: true,
        disableSort: true,

        listColumns:
            currentResponsiveMode <= ResponsiveMode.medium
                ? mobileListColumns
                : listColumns,
        mobile: currentResponsiveMode <= ResponsiveMode.medium,
        formFields: formFields(useTag, null, null, null, null, null, true),
        Form: (props) => {
            return (
                <SpecificationsPublicForm
                    studioId={studioId}
                    projectSharesId={projectSharesId}
                    {...props}
                />
            )
        },
        readOnly: true,
    }
}

function markMenu(selectedItems, selected) {
    const iconMenu = []
    for (const i of ["red", "green", "blue", "purple", "orange", "grey"]) {
        iconMenu.push({
            key: i,
            text: i.charAt(0).toUpperCase() + i.slice(1),
            disabled: selectedItems.length === 0,
            iconProps: {
                iconName: selected === i ? "SkypeCircleCheck" : "CircleFill",
                styles: {
                    root: {
                        color: config.pastelAlternatives[i],
                        opacity: selectedItems.length === 0 ? 0.5 : 1,
                    },
                },
            },
        })
    }
    return iconMenu
}

function useMarkButtons(selectedItems, projectId, setMarkFilter, markFilter) {
    const iconMenu = markMenu(selectedItems)
    const filterMarkMenu = markMenu(["bogus"], markFilter)
    const { status } = useNotification()
    const [processing, setProcessing] = useState(false)
    const useSpecifications = useEntityFromConfig(useConfig())

    function setProgress(obj) {
        if (obj === false) {
            return
        }
        status("useMarkButtons-marking-dialog", obj)
    }

    async function mark(color) {
        let count = 1
        setProcessing(true)
        let cont = false
        for (const s of selectedItems) {
            if (cont) {
                break
            }
            const id = s._id
            const spec = useSpecifications.filter([
                { field: "id", type: "eq", value: id },
                { field: "projectId", type: "eq", value: projectId },
            ])
            if (spec[0]) {
                const sp = spec[0]
                setProgress({
                    title: "Marking Specs",
                    description:
                        "(" +
                        count +
                        "/" +
                        selectedItems.length +
                        ") " +
                        sp.name,
                    percentComplete: count / selectedItems.length,
                })
                if (color === "clear") {
                    sp.marks = []
                } else {
                    if (sp.marks.includes(color)) {
                        count++
                        continue
                    }
                    sp.marks.push(color)
                }

                await useSpecifications.dispatch.upsert(sp)
            }
            count++
        }
        setProgress(false)
        setProcessing(false)
    }

    return {
        key: "marksMain",
        text: "Mark",
        iconProps: {
            iconName: markFilter ? "SkypeCircleCheck" : "CircleFill",
            styles: {
                root: {
                    color: markFilter,
                },
            },
        },

        subMenuProps: {
            onItemClick: (_, key) => {
                mark(key.key)
            },
            items: [
                {
                    key: "markFilter",
                    text: "Filter By Mark",
                    iconProps: { iconName: "Filter" },

                    subMenuProps: {
                        onItemClick: (_, item) => {
                            if (item.key === "clear") {
                                setMarkFilter(null)
                            } else {
                                setMarkFilter(item.key)
                            }
                        },
                        items: [
                            ...filterMarkMenu,
                            { key: "clear", text: "Clear" },
                        ],
                    },
                },
                {
                    key: "divider_1",
                    itemType: ContextualMenuItemType.Divider,
                },
                ...[...iconMenu, { key: "clear", text: "Clear" }],
            ],
        },
    }
}

export function SpecificationsList(props) {
    const [sharingVisable, setSharingVisable] = useState(false)
    const [projectEdit, setProjectEdit] = useState(false)
    const [printPrice, setPrintPrice] = useState(false)
    const [showBudget, setShowBudget] = useState(false)

    const [printRows, setPrintRows] = useState(null)

    const [showIssuances, setShowIssuances] = useState(
        window.location.search === "?issuanceOpen"
    )
    const useTagTypes = useEntityFromConfig(useTagTypeConfig())

    const [importProject, setImportProject] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [markFilter, setMarkFilter] = useState(null)

    const projectConfig = useProjectConfig()
    const useProject = useEntityFromConfig(projectConfig)

    const submitRef = useRef()
    const dataDog = useDataDogRum()
    const history = useHistory()

    const pFilter = useProject.filter([
        { field: "id", type: "one", value: props.projectId },
    ])
    const project = pFilter ? pFilter[0] : null

    const specificationConfig = useConfig(props.projectId, {
        printPrice: printPrice,
    })

    const useSpecification = useEntityFromConfig(specificationConfig)
    const markButton = useMarkButtons(
        selectedItems,
        props.projectId,
        setMarkFilter,
        markFilter
    )

    if (markFilter) {
        specificationConfig.filter.push({
            field: "marks",
            type: "like",
            value: markFilter,
        })
    }

    specificationConfig.additionalButtons.push({
        key: "sharingMain",
        text: "Sharing",
        iconProps: { iconName: "Share" },

        subMenuProps: {
            items: [
                {
                    key: "issuances",
                    text: "Issuances",
                    iconProps: { iconName: "ClipboardList" },
                    onClick: () => {
                        setShowIssuances(true)
                        history.push(
                            "/schedule/" + props.projectId + "?issuanceOpen"
                        )
                    },
                },
                {
                    key: "sharing",
                    text: "Sharing",
                    iconProps: { iconName: "Share" },
                    onClick: () => {
                        setSharingVisable(true)
                    },
                },
            ],
        },
    })

    specificationConfig.additionalButtons.push(markButton)
    specificationConfig.additionalButtons.push({
        key: "budget",
        text: "Budget",

        iconProps: { iconName: "Money" },
        onClick: () => {
            setShowBudget(!showBudget)
        },
    })

    specificationConfig.additionalButtons.push({
        key: "editProjectMain",
        text: "Project",
        iconProps: { iconName: "ColumnLeftTwoThirdsEdit" },
        onClick: () => {
            setProjectEdit(true)
        },
        subMenuProps: {
            items: [
                {
                    key: "editProject",
                    text: "Edit Project",
                    iconProps: { iconName: "ColumnLeftTwoThirdsEdit" },
                    onClick: () => {
                        setProjectEdit(true)
                    },
                },
                {
                    key: "close",
                    text: "Close Project Tab",
                    iconProps: { iconName: "ChromeClose" },
                    onClick: () => {
                        const event = new CustomEvent("closeProject", {
                            detail: props.projectId,
                        })
                        document.body.dispatchEvent(event)
                    },
                },
            ],
        },
    })

    specificationConfig.subMenus = {
        export: {
            subMenuProps: {
                items: [
                    {
                        key: "newItemFrom",
                        text: "Export Images from Selected",
                        iconProps: { iconName: "FileImage" },
                        disabled: selectedItems.length === 0,

                        onClick: async (e, item) => {
                            document.body.style.cursor = "progress"

                            const zip = new JSZip()

                            for (const s of selectedItems) {
                                const id = s._id
                                const specs = useSpecification.filter([
                                    { field: "id", type: "eq", value: id },
                                    {
                                        field: "projectId",
                                        type: "eq",
                                        value: props.projectId,
                                    },
                                ])

                                let count = 0
                                //We can assme it's loaded here
                                if (!specs[0].images) {
                                    continue
                                }
                                for (const i of specs[0].images) {
                                    count++
                                    const res = await fetch(
                                        config.images.baseUrl + i
                                    )
                                    const imageBlob = await res.blob()
                                    const type = imageBlob.type

                                    let extension = "png"
                                    if (type === "image/jpeg") {
                                        extension = "jpg"
                                    } else if (type === "image/gif") {
                                        extension = "gif"
                                    }
                                    zip.file(
                                        tagFormatter(
                                            useTagTypes,
                                            specs[0].tagTypeId,
                                            specs[0].tagNumber
                                        ) +
                                            "_" +
                                            count +
                                            "." +
                                            extension,
                                        await imageBlob.arrayBuffer(),
                                        { binary: true }
                                    )
                                }
                            }

                            zip.generateAsync({ type: "blob" }).then(function (
                                content
                            ) {
                                const link = document.createElement("a")
                                link.href = window.URL.createObjectURL(content)
                                link.download = `images-${+new Date()}.zip`
                                link.click()
                            })
                            document.body.style.cursor = "default"
                        },
                    },
                ],
            },
        },
        print: {
            subMenuProps: {
                items: [
                    {
                        key: "printWithPrice",
                        text: "Print With Price",
                        iconProps: { iconName: "Money" },
                        onBeforePrint: () => {
                            setPrintPrice(true)
                        },
                        onAfterPrint: () => {
                            setPrintPrice(false)
                        },
                    },
                    {
                        key: "printWithPriceAndTotal",
                        text: "Print With Price And Total",
                        iconProps: { iconName: "Money" },
                        onBeforePrint: () => {
                            setPrintPrice(2)
                        },
                        onAfterPrint: () => {
                            setPrintPrice(false)
                        },
                    },
                ],
            },
        },
        newItem: {
            subMenuProps: {
                items: [
                    {
                        key: "newItemFrom",
                        text: "Copy Project Spec",
                        iconProps: { iconName: "DependencyAdd" },
                        subMenuProps: {
                            onItemClick: (e, item) => {
                                dataDog.addAction("newItemFrom", {
                                    newItemFromToProject: props.projectId,
                                    newItemFromFromProject: item.key,
                                })
                                setImportProject(item.key)
                            },
                            items: useProject.filter([]).map((i) => {
                                return {
                                    key: i.id,
                                    text: i.name,
                                }
                            }),
                        },
                    },
                ],
            },
        },
    }

    return (
        <>
            {printRows && (
                <PrintDialog
                    onClose={() => {
                        setPrintRows(null)
                    }}
                    filter={specificationConfig.filter}
                    projectId={props.projectId}
                    rows={printRows}
                />
            )}

            <BudgetPanel
                projectId={props.projectId}
                filter={specificationConfig.filter}
                isOpen={showBudget}
                onDismiss={() => {
                    setShowBudget(false)
                }}
            />

            {importProject && (
                <ImportSpecPanel
                    projectId={importProject}
                    toProjectId={props.projectId}
                    onClose={() => {
                        setImportProject(false)
                    }}
                />
            )}
            {sharingVisable && project && (
                <SharingPanel
                    project={project}
                    onClose={() => setSharingVisable(false)}
                />
            )}
            {showIssuances && project && (
                <IssuancesPanel
                    project={project}
                    onClose={() => {
                        setShowIssuances(false)
                        history.push("/schedule/" + props.projectId)
                    }}
                />
            )}
            {projectEdit && project && (
                <UpsertPanel
                    buttonLabel="Update Project"
                    AddForm={projectConfig.Form}
                    submitRef={submitRef}
                    values={project}
                    onClose={() => {
                        setProjectEdit(false)
                    }}
                    near={false}
                />
            )}
            <EntityList
                onSelectionChanged={(selection) => {
                    setSelectedItems(selection)
                }}
                multiSelection
                config={specificationConfig}
                printFunction={
                    config.reporting.enabled
                        ? (rows) => {
                              setPrintRows(rows)
                          }
                        : null
                }
            />
        </>
    )
}

export function PrintDialog(props) {
    const submitRef = useRef()

    const [blob, setBlob] = useState(null)
    const projectId = props.projectId
    const filter = props.filter

    const useSpecifications = useEntityFromConfig(useConfig(projectId))
    const rawFilteredSpecifications = useSpecifications.filter(filter)

    const useInfo = useEntityFromConfig(infoConfig)
    const info = useInfo.filter([])[0]
    const studioConfig = useStudioConfig()

    const useStudios = useEntityFromConfig(studioConfig)
    const studios = useStudios.filter([
        { field: "id", type: "eq", value: info.studioId },
    ])
    const studio = studios[0] ? studios[0] : {}

    const useProjects = useEntityFromConfig(useProjectConfig())
    const projects = useProjects.filter([
        { field: "id", type: "eq", value: props.projectId },
    ])
    const project = projects[0] ? projects[0] : {}

    const useUsers = useEntityFromConfig(userConfig)
    const users = useUsers.filter([
        {
            field: "id",
            type: "eq",
            value: project.primaryUserId,
        },
    ])
    const designers = []
    if (users[0]) designers.push(users[0])

    const usersSecondary = useUsers.filter([
        {
            field: "id",
            type: "eq",
            value: project.secondaryUserId,
        },
    ])
    if (usersSecondary[0]) designers.push(usersSecondary[0])

    const useClients = useEntityFromConfig(clientConfig)

    const clients = useClients.filter([
        {
            field: "id",
            type: "eq",
            value: project.clientId ? project.clientId : null,
        },
    ])
    const client = clients[0] ? clients[0] : {}

    const useIssuances = useEntityFromConfig(useIssuanceConfig(props.projectId))
    const issuances = useIssuances.filter([
        { field: "projectId", type: "eq", value: props.projectId },
    ])

    const pformConfig = {
        title: "Generate PDF",
        fields: [
            {
                label: "Report Title",
                key: "title",
                type: "text",
            },
            ...(config.features.fullPageSpec
                ? [
                      {
                          label: "Full Page Sheet",
                          key: "fullPage",
                          type: "toggle",
                      },
                  ]
                : []),
            {
                label: "Include Cover",
                key: "cover",
                type: "toggle",
                coupledValue: config.features.fullPageSpec ? "fullPage" : "",
            },

            {
                type: "group",
                fields: [
                    {
                        label: <>Include Price &nbsp;</>,
                        key: "price",
                        type: "toggle",
                    },
                    {
                        label: "Leadtime",
                        key: "leadtime",
                        type: "toggle",
                    },
                ],
            },
            {
                label: "Include Total",
                key: "total",
                type: "toggle",
            },
            {
                label: "Include Supplier",
                key: "supplier",
                type: "toggle",
            },
            {
                label: "Include Links",
                key: "links",
                type: "toggle",
            },
        ],
        values: {
            fullPage: 0,
            cover: 1,
            price: 0,
            leadtime: 1,
            total: 0,
            title: "PROJECT SCHEDULE",
            supplier: 1,
            links: 0,
        },
        onSubmit: async (values) => {
            let gtotal = 0
            for (const r of rawFilteredSpecifications) {
                if (r.price > 0) {
                    gtotal =
                        gtotal +
                        r.price * (r.quantity === null ? 1 : r.quantity)
                }
            }
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            })

            for (const row of props.rows) {
                const projectFilter = filter.find(
                    (e) => e?.field === "projectId"
                )

                const specs = useSpecifications.filter([
                    { field: "id", type: "eq", value: row._id },
                    projectFilter,
                ])

                row.url = specs[0] ? specs[0].url : ""
            }
            gtotal = formatter.format(gtotal / 100)
            const obj = {
                studio: {
                    imageUrl: studio.image
                        ? config.images.baseUrl + studio.image + "-original"
                        : null,
                    ...studio,
                },
                designers: designers,
                client: client,
                issuances: issuances,
                project: project,
                specifications: props.rows,
                config: values,
                grandTotal: gtotal,
                title: values.title,
            }

            const url = values.fullPage
                ? config.reporting.url + "/specification-fullpage"
                : config.reporting.url + "/specification"

            const response = await axios.post(url, obj, {
                responseType: "blob",
                headers: { "content-type": "application/json" },
            })

            fileDownload(response.data, "ProspecerReport_a.pdf")
            setBlob(response.data)
            props.onClose()
        },
        submitRef: submitRef,
        filter: [],
        entity: { name: "" },
        validate: (v) => {
            return true
        },
        validateAt: (key, v) => {
            return true
        },
        showEvents: false,

        //For modal
        showButtons: true,
        onCancel: props.onClose,
        readOnly: false,
        actionText: "Generate PDF",
    }
    function F(props) {
        return <Form {...pformConfig} {...props} />
    }
    return (
        <>
            {blob && <Download blob={blob} />}
            <UpsertModal
                actionText={"Generate a PDF"}
                submitRef={submitRef}
                AddForm={F}
                values={pformConfig.values}
                onClose={(values) => {
                    props.onClose()
                }}
                spinnerMessage="Generating PDF"
            />
        </>
    )
}

export function SpecificationsPublicForm(props) {
    const specificationConfig = usePublicConfig(
        props.studioId,
        props.projectSharesId
    )
    return (
        <EntityForm
            {...props}
            readOnly={true}
            config={
                props.customConfig ? props.customConfig : specificationConfig
            }
        />
    )
}

export function SpecificationsForm(props) {
    const specificationConfig = useConfig(props.projectId)
    const useTag = useEntityFromConfig(useTagTypeConfig())
    const useSupplier = useEntityFromConfig(supplierConfig)
    const useSpecification = useEntityFromConfig(specificationConfig)
    const studioConfig = useStudioConfig()

    const formFields = useFormFiels()

    const useStudio = useEntityFromConfig(studioConfig)
    const useInfo = useEntityFromConfig(infoConfig)

    const config = useInfo.filter([])[0] ? useInfo.filter([])[0] : null
    const studio = config
        ? useStudio.filter([
              { field: "id", type: "eq", value: config.studioId },
          ])[0]
        : null
    specificationConfig.formFields = formFields(
        useTag,
        useSupplier,
        supplierConfig,
        useSpecification,
        props.projectId,
        studio,
        false,
        props.pricingFirst
    )
    return (
        <EntityForm
            {...props}
            readOnly={
                props.readOnly ? true : props.values && !!props.values.archived
            }
            config={specificationConfig}
        />
    )
}

function ImportSpecPanel(props) {
    const specConfig = useConfig(props.projectId)
    const useProject = useEntityFromConfig(useProjectConfig())
    const [importValues, setImportValues] = useState(false)
    const parentSubmitRef = useRef(() => {
        props.onClose()
    })

    const projects = useProject.filter([
        { type: "one", field: "id", value: props.projectId },
    ])
    if (!projects) {
        return null
    }
    const project = projects[0]

    //We don't want to load it's form
    specConfig.Form = null

    return (
        <>
            <Panel
                title={`Import From ${project.name}`}
                hideButtons
                onClose={props.onClose}
                large
                isOpen={!importValues}
            >
                <EntityList
                    onRowClick={(v) => {
                        const values = { ...v }
                        values["__new"] = true
                        values.tagNumber = null
                        values.id = uuidv4()
                        values.projectId = props.toProjectId
                        values.issuanceId = null
                        values.issuedSpecificationId = null
                        values.archived = null
                        values.invoiceStatus = "none"
                        setImportValues(values)
                    }}
                    hideButtons
                    config={specConfig}
                />
            </Panel>
            <UpsertPanel
                isOpen={!!importValues}
                submitRef={parentSubmitRef}
                buttonLabel={"Import Into Project"}
                AddForm={(p) => {
                    return (
                        <SpecificationsForm
                            projectId={props.toProjectId}
                            {...p}
                        />
                    )
                }}
                onClose={(success) => {
                    setImportValues(false)

                    if (success) {
                        props.onClose()
                    }
                }}
                values={importValues}
            />
        </>
    )
}
function SharingPanel(props) {
    const projectSharesConfig = useProjectSharesConfig(props.project.id)

    return (
        <Panel
            title={`Sharing for ${props.project.name}`}
            hideButtons
            onClose={props.onClose}
            large
        >
            <EntityList config={projectSharesConfig} />
        </Panel>
    )
}

function IssuancesPanel(props) {
    return (
        <Panel
            title={`Issuances for ${props.project.name}`}
            hideButtons
            onClose={props.onClose}
            extraLarge
        >
            <IssuancesList projectId={props.project.id} />
        </Panel>
    )
}

function PrintData(props) {
    return (
        <tr style={{ border: "none", padding: 0 }}>
            <td
                style={{
                    verticalAlign: "middle",
                    textAlign: "right",
                    padding: 0,
                    paddingBottom: props.padded ? "10px" : "0",
                }}
            >
                <Text variant={"small"}>{props.label}</Text>
            </td>
            <td
                style={{
                    padding: 0,
                    paddingBottom: props.padded ? "10px" : "0",
                    paddingLeft: "10px",
                }}
            >
                <Text variant={props.fontSize ? props.fontSize : "small"}>
                    {props.data}
                </Text>
            </td>
        </tr>
    )
}

function PrintCover(props) {
    const project = props.project
    const client = props.client
    const studio = props.studio
    const useUsers = useEntityFromConfig(userConfig)
    const useIssuance = useEntityFromConfig(useIssuanceConfig(project.id))
    const useSpecification = useEntityFromConfig(useConfig(project.id))
    const specs = useSpecification.filter([
        { field: "projectId", type: "eq", value: project.id },
    ])

    const issuances = useIssuance.filter([
        { field: "projectId", type: "eq", value: project.id },
    ])

    let primaryDesigner,
        secondaryDesigner = []
    if (studio) {
        primaryDesigner = useUsers.filter([
            { field: "id", type: "eq", value: project.primaryUserId },
        ])
        secondaryDesigner = useUsers.filter([
            { field: "id", type: "eq", value: project.secondaryUserId },
        ])
    }

    const testTemp = {
        studio: studio,
        designers: [primaryDesigner[0], secondaryDesigner[0]],
        client: client,
        issuances: issuances,
        project: project,
        specifications: specs,
    }

    const rIssuance = []

    for (const i of issuances) {
        const thisIssuance = props.issuanceId === i.id
        rIssuance.push(
            <div key={"issuance-" + i.id}>
                {new Date(i.createdAt).toLocaleDateString()} | {i.name}
                {thisIssuance && <span> (this issuance)</span>}
            </div>
        )
    }

    return (
        <>
            {studio && client && project && (
                <div className="fullPage">
                    <div
                        style={{
                            position: "absolute",
                            maxHeight: "125px",
                            right: 0,
                            top: 0,
                        }}
                    >
                        <Images
                            height={150}
                            width={500}
                            imageFit={ImageFit.contain}
                            value={[studio.image]}
                        />
                    </div>
                    <div
                        style={{
                            height: "60pt",
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        <Text variant={"xxLarge"}>PROJECT SCHEDULE</Text>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "10pt",
                            backgroundColor: studio.color,
                            webkitPrintColorAdjust: "exact",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", paddingLeft: "20pt" }}>
                            <table
                                style={{
                                    border: "none",
                                    borderCollapse: "collapse",
                                    padding: "none",
                                }}
                            >
                                <tbody>
                                    <PrintData
                                        padded
                                        label="PROJECT:"
                                        data={project.name}
                                        fontSize="xLarge"
                                    />
                                    <PrintData
                                        padded
                                        label="ADDRESS:"
                                        data={
                                            client.contact.address +
                                            " " +
                                            client.contact.city +
                                            ", " +
                                            client.contact.state +
                                            "  " +
                                            client.contact.postal
                                        }
                                        fontSize="large"
                                    />
                                    <PrintData
                                        padded
                                        label="CLIENT:"
                                        data={client.name}
                                        fontSize="large"
                                    />
                                    <PrintData
                                        label="CONTACT:"
                                        data={client.contact.name}
                                    />
                                    <PrintData
                                        label="EMAIL:"
                                        data={client.contact.email}
                                    />
                                    <PrintData
                                        label="PHONE:"
                                        data={client.contact.phone}
                                    />
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table
                                style={{
                                    border: "none",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tbody>
                                    <PrintData
                                        padded
                                        label="INTERIOR DESIGNER:"
                                        data={studio.name}
                                        fontSize="xLarge"
                                    />
                                    {primaryDesigner[0] && (
                                        <>
                                            <PrintData
                                                label="DESIGNER:"
                                                data={
                                                    primaryDesigner[0].contact
                                                        .name
                                                }
                                            />
                                            <PrintData
                                                label="EMAIL:"
                                                data={
                                                    primaryDesigner[0].contact
                                                        .email
                                                }
                                            />
                                            <PrintData
                                                label="PHONE:"
                                                data={
                                                    primaryDesigner[0].contact
                                                        .phone
                                                }
                                            />
                                        </>
                                    )}
                                    {secondaryDesigner[0] && (
                                        <>
                                            <PrintData
                                                label="DESIGNER:"
                                                data={
                                                    secondaryDesigner[0].contact
                                                        .name
                                                }
                                            />
                                            <PrintData
                                                label="EMAIL:"
                                                data={
                                                    secondaryDesigner[0].contact
                                                        .email
                                                }
                                            />
                                            <PrintData
                                                label="PHONE:"
                                                data={
                                                    secondaryDesigner[0].contact
                                                        .phone
                                                }
                                            />
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "10pt",
                            backgroundColor: studio.color,
                            webkitPrintColorAdjust: "exact",
                        }}
                    >
                        {" "}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                width: "calc(50vw - 2px)",
                                marginTop: "6pt",
                            }}
                        >
                            ISSUANCES:
                            {rIssuance}
                        </div>
                        <div
                            style={{
                                width: "calc(50% - 2px)",
                                border: "1px solid " + studio.color,
                                marginTop: "6pt",
                            }}
                        >
                            <Text variant="small"> NOTES: </Text>
                            <Text variant="small">
                                <div style={{ padding: "3pt" }}>
                                    <pre>{project.note}</pre>
                                </div>
                            </Text>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
