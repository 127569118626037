import React, { useState, useMemo } from "react"

import {
    setValueInKeyPath,
    valueInKeyPath,
    deleteKeyPath,
} from "@prospecer/ffsds/src/shared/keypath"
import { TextField } from "@fluentui/react/lib/TextField"
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button"
import { Label } from "@fluentui/react/lib/Label"
import {
    Callout,
    ColorPicker,
    ConstrainMode,
    DatePicker,
    DelayedRender,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    Position,
    SelectionMode,
    SpinButton,
    TooltipHost,
    useTheme,
} from "@fluentui/react"
import { ActionButton } from "@fluentui/react/lib/Button"
import { padNumber as pad } from "../utils"
import {
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
} from "@fluentui/react"
import InfoField from "../controls/InfoField"
import { Separator } from "@fluentui/react/lib/Separator"
import { cloneDeep } from "lodash"
import Country from "../controls/Country"
import State from "../controls/State"
import Phone from "../controls/Phone"
import Password from "../controls/Password"
import { Dropdown } from "@fluentui/react/lib/Dropdown"
import Entity from "../controls/Entity"
import Meta from "../controls/Meta"
import Image from "../controls/Image"
import Money from "../controls/Money"
import config from "../config"
import { useEntityFromConfig } from "../hooks/Entity"
import { userConfig } from "../components/Users"
import Color from "../controls/Color"
import Picker from "../controls/Picker"
import Toggle from "../controls/Toggle"
import NumberControl from "../controls/Number"

function fetchError(validationError, keypath) {
    if (!validationError) {
        return null
    }
    const errors = validationError.fields

    if (!errors) {
        return null
    }
    for (const error of errors) {
        if (error.key === keypath) {
            return error.message
        }
    }

    return null
}

export function ArrayInput(props) {
    const fields = props.fields
    const field = props.field
    const value = props.value
    const onChange = props.onChange
    const error = props.error
    const errors = props.errors
    const addIcon = { iconName: "Add" }
    const deleteIcon = { iconName: "Delete" }
    const key = props.fieldKey
    const values = valueInKeyPath(key, props.values)

    const formItems = useMemo(() => {
        let separate = false
        if (!values) {
            return [<b>{props.field.placeholder} </b>]
        }
        return values.map(function (vo, vi) {
            const fies = props.readOnly ? (
                <TextField
                    label={vo.name}
                    name={vo.name}
                    value={vo.value}
                    readOnly={true}
                />
            ) : field ? (
                <FormInput
                    {...field}
                    value={vo}
                    onChange={(e, voo) => {
                        const v = cloneDeep(values)
                        v[vi] = voo
                        onChange(e, v)
                    }}
                    error={fetchError(
                        errors,
                        props.fieldKey + "[" + vi + "]." + field.key
                    )}
                    errors={errors}
                    values={vo}
                    fieldKey={field.key}
                />
            ) : (
                fields.map(function (ro, ri) {
                    return (
                        <FormInput
                            {...ro}
                            value={
                                ro.displayOnly ? "" : valueInKeyPath(ro.key, vo)
                            }
                            onChange={(e, voo) => {
                                if (
                                    ro.nullable &&
                                    valueInKeyPath(ro.key, vo) === null
                                ) {
                                    deleteKeyPath(ro.key, values)
                                } else {
                                    const a = cloneDeep(vo)
                                    const v = cloneDeep(values)
                                    a[ro.key] = voo

                                    const ii = values.findIndex(
                                        (e) => e.key === a.key
                                    )
                                    v[ii] = a
                                    //
                                    onChange(e, v)
                                    //validateField(ro.key)
                                }
                            }}
                            error={fetchError(
                                errors,
                                props.fieldKey + "[" + vi + "]." + ro.key
                            )}
                            errors={errors}
                            values={vo}
                            fieldKey={ro.key}
                        />
                    )
                })
            )
            const r = (
                <div>
                    {separate && <Separator />}

                    {fies}
                    {!props.readOnly && (
                        <ActionButton
                            onClick={() => {
                                const a = [...value]
                                a.splice(vi, 1)
                                onChange(null, a)
                            }}
                            iconProps={deleteIcon}
                            title="Delete"
                            ariaLabel="Delete"
                            text="Delete"
                        />
                    )}
                </div>
            )
            separate = true
            return r
        })
    }, [values, fields, errors, error])

    return (
        <>
            <Separator>
                <Text>{props.label}</Text>
            </Separator>
            <Stack>{formItems}</Stack>
            <TooltipHost
                content={props.addLabel}
                styles={{ root: { textAlign: "right" } }}
            >
                {!props.readOnly && (
                    <ActionButton
                        onClick={() => {
                            const a = value === null ? [] : [...value]
                            a.push(
                                props.emptyFormValue instanceof Function
                                    ? props.emptyFormValue()
                                    : { ...props.emptyFormValue }
                            )
                            onChange(null, a)
                        }}
                        iconProps={addIcon}
                        title={!values ? "Edit" : "Add"}
                        ariaLabel={!values ? "Edit" : "Add"}
                        text={!values ? "Edit" : "Add"}
                    />
                )}
            </TooltipHost>
            <Separator />
        </>
    )
}

function FormInput(props) {
    const [lastGroupKeyValue, _setLastGroupKeyValue] = useState()
    function setLastGroupKeyValue(value) {
        _setLastGroupKeyValue(value)
    }
    if (props.type === "separator") {
        return (
            <>
                <br />
                <Separator>
                    <Text>{props.label}</Text>
                </Separator>
            </>
        )
    }
    if (props.type === "info") {
        return <InfoField {...props} />
    }
    if (props.type === "array") {
        return <ArrayInput {...props} />
    }
    if (props.type === "country") {
        return (
            <Country
                key={props.name}
                label={props.label}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                readOnly={props.readOnly}
            />
        )
    }
    if (props.type === "state") {
        return (
            <State
                label={props.label}
                key={props.name}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                coupledValue={props.coupledValue}
                readOnly={props.readOnly}
            />
        )
    }
    if (props.type === "entity") {
        return (
            <Entity
                key={props.name}
                label={props.label}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                coupledValue={props.coupledValue}
                descrKeyPath={props.descrKeyPath}
                detailsKeyPath={props.detailsKeyPath}
                valueKeyPath={props.valueKeyPath}
                options={props.options}
                Form={props.Form}
                disabled={
                    props.disabled
                        ? props.disabled
                        : props.disabledOnUpdate && !props.values.__new
                }
                nullable={props.nullable}
                readOnly={props.readOnly}
                required={props.required}
                placeholder={props.placeholder}
            />
        )
    }
    if (props.type === "meta") {
        return (
            <Meta
                key={props.name}
                label={props.label}
                name={props.name}
                type={props.type}
                value={props.value}
                values={props.values}
                onChange={props.onChange}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                coupledValue={props.coupledValue}
                readOnly={props.readOnly}
            />
        )
    }
    if (props.type === "dropdown") {
        return (
            <Dropdown
                key={props.name}
                label={props.label}
                name={props.name}
                selectedKey={props.value}
                onChange={(e, option) => {
                    props.onChange(e, option.key)
                }}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                coupledValue={props.coupledValue}
                options={props.options}
                readOnly={props.readOnly}
                required={props.required}
                placeholder={props.required ? "Required" : null}
            />
        )
    }
    if (props.type === "tel") {
        return (
            <Phone
                key={props.name}
                label={props.label}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                coupledValue={props.coupledValue}
                readOnly={props.readOnly}
                extension={props.extension}
            />
        )
    }
    if (props.type === "password") {
        return (
            <Password
                key={props.name}
                label={props.label}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                coupledValue={props.coupledValue}
                readOnly={props.readOnly}
                disabled={props.disabled}
            />
        )
    }
    if (props.type === "number") {
        return <NumberControl {...props} />
    }
    if (props.type === "image") {
        return (
            <Image
                key={props.name}
                label={props.label}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={(e) => {
                    props.onChange(null, e)
                }}
                allowDelete={props.allowDelete}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                anyFiles={props.anyFiles}
                readOnly={props.readOnly}
                onlyOne={props.onlyOne}
                pastable={props.pastable}
            />
        )
    }

    if (props.type === "picker") {
        return (
            <Picker
                key={props.name}
                label={props.label}
                name={props.name}
                value={props.value}
                onChange={(value) => {
                    props.onChange(null, value)
                }}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                multiline={props.option === "multiline"}
                readOnly={props.readOnly}
                disabled={props.disabled}
                tags={props.tags}
                descr={props.descr}
            />
        )
    }

    if (props.type === "money") {
        return (
            <Money
                key={props.name}
                label={props.label}
                name={props.name}
                type={props.subtype ? props.subtype : props.type}
                value={props.value}
                onChange={(value) => {
                    props.onChange(null, value)
                }}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                multiline={props.option === "multiline"}
                prefix={props.prefix}
                readOnly={props.readOnly}
                disabled={props.disabled}
            />
        )
    }
    if (props.type === "toggle") {
        return (
            <Toggle
                key={props.name}
                label={props.label}
                name={props.name}
                checked={props.value === 0 ? false : true}
                onChange={(ev, value) => {
                    props.onChange(ev, Number(value))
                }}
                errorMessage={props.error ? props.error : false}
                onGetErrorMessage={props.onGetErrorMessage}
                multiline={props.option === "multiline"}
                prefix={props.prefix}
                onText={props.onText ? props.onText : "On"}
                offText={props.offText ? props.offText : "Off"}
                readOnly={props.readOnly}
                disabled={
                    props.disabled
                        ? props.disabled
                        : props.disabledOnUpdate && !props.values.__new
                }
                coupledValue={props.coupledValue}
            />
        )
    }
    if (props.type === "color") {
        return (
            <Color
                key={props.name}
                label={props.label}
                value={props.value}
                onChange={(ev, value) => {
                    props.onChange(ev, value)
                }}
                showPreview={true}
            />
        )
    }

    if (props.type === "date") {
        if (props.readOnly) {
            return (
                <TextField
                    key={props.name}
                    label={props.label}
                    value={new Date(props.value).toLocaleDateString()}
                    readOnly={true}
                />
            )
        }
        return (
            <DatePicker
                key={props.name}
                label={props.label}
                value={new Date(props.value)}
                onSelectDate={(value) => {
                    props.onChange(null, value)
                }}
            />
        )
    }
    return (
        <TextField
            key={props.name}
            label={props.label}
            name={props.name}
            type={props.subtype ? props.subtype : props.type}
            value={props.value}
            onChange={(e) => {
                props.onChange(e, e.target.value)
            }}
            errorMessage={props.error ? props.error : false}
            onGetErrorMessage={props.onGetErrorMessage}
            multiline={props.option === "multiline"}
            prefix={props.prefix}
            readOnly={props.readOnly}
            disabled={props.disabled}
            placeholder={props.placeholder}
        />
    )
}

export default function Form(props) {
    const theme = useTheme()
    const [values, _setValues] = useState(cloneDeep(props.values))
    const [errors, setErrors] = useState({ fields: [] })
    const [spinning, setSpinning] = useState(false)
    function setValues(values) {
        _setValues(cloneDeep(values))
    }
    if (props.setValuesRef) {
        props.setValuesRef.current = setValues
    }
    if (props.valuesRef) {
        props.valuesRef.current = values
    }

    function setErrorForField(key, message) {
        const err = cloneDeep(errors)
        const fields = err.fields
        for (const e of fields) {
            if (e.key === key) {
                e.message = message
                return err
            }
        }
        fields.push({ key: key, message: message })
        return err
    }

    async function validateField(key) {
        try {
            await props.validateAt(key, values, { abortEarly: true })
            setErrors(setErrorForField(key, null))
        } catch (error) {
            //console.error(error)
            setErrors(setErrorForField(key, error.message))
            return false
        }
    }

    async function submitFunciton() {
        try {
            setSpinning(true)
            const result = await props.validate(values)
            return await props.onSubmit(values)
        } catch (error) {
            const fs = []
            if (error.inner) {
                for (const i of error.inner) {
                    fs.push({ key: i.path, message: i.message })
                }
                setErrors({
                    message:
                        "Could not save. There are errors on this form. Please check the inputs.",
                    fields: fs,
                })
                console.error(error)
            } else {
                setErrors({
                    message: "Error: " + error,
                    fields: fs,
                })
            }

            return false
        } finally {
            setSpinning(false)
        }
    }
    if (!props.showButtons) {
        props.submitRef.current = submitFunciton
    }
    const formItems = useMemo(() => {
        function genFields(pfields) {
            const fields = pfields.map(function (ro, ri) {
                const error = errors.fields.find(
                    (element) => element.key == ro.key
                )

                const coupledValue = ro.coupledValue
                    ? valueInKeyPath(ro.coupledValue, values)
                    : null

                if (ro.type === "group") {
                    return (
                        <Stack key={"st_" + ro.name} horizontal>
                            {genFields(ro.fields)}
                        </Stack>
                    )
                }
                if (ro.type === "component") {
                    return (
                        <ro.Component
                            key={"co_" + ro.name}
                            setValues={setValues}
                            {...ro.props}
                            config={ro}
                            values={values}
                            readOnly={props.readOnly}
                        >
                            {genFields(ro.fields)}
                        </ro.Component>
                    )
                }

                return (
                    <>
                        <FormInput
                            readOnly={props.readOnly}
                            key={"fo_" + ro.name}
                            {...ro}
                            value={
                                ro.displayOnly
                                    ? ""
                                    : valueInKeyPath(ro.key, values)
                            }
                            onChange={(e, value) => {
                                if (props.readOnly) {
                                    return
                                }
                                if (ro.nullable && value === null) {
                                    //deleteKeyPath(ro.key, values)
                                    setValues(
                                        setValueInKeyPath(ro.key, values, value)
                                    )
                                    setValues({ ...values })
                                    validateField(ro.key)
                                } else {
                                    setValues(
                                        setValueInKeyPath(ro.key, values, value)
                                    )

                                    validateField(ro.key)
                                }
                            }}
                            coupledValue={coupledValue}
                            error={error ? error.message : null}
                            errors={errors}
                            values={values}
                            fieldKey={ro.key}
                            entity={props.entity}
                            filter={props.filter}
                        />
                    </>
                )
            })
            return fields
        }
        return genFields(props.fields)
    }, [
        props.fields,
        props.readOnly,
        props.entity,
        props.filter,
        errors,
        validateField,
    ])

    const spinnerStackTokens = {
        childrenGap: 11,
    }

    return (
        <form
            onSubmit={async (event) => {
                if (props.showButtons) {
                    const value = await submitFunciton()
                    return value
                }
                event.preventDefault()
            }}
        >
            <Stack key="formStack">
                {errors.message && (
                    <MessageBar
                        delayedRender={false}
                        // Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
                        messageBarType={MessageBarType.error}
                        // Or you could set the role manually, IF an alert role is appropriate for the message
                        // role="alert"
                    >
                        {errors.message}
                    </MessageBar>
                )}
                {formItems}
                {props.showEvents && <Events entity={props.values} />}
                {spinning && (
                    <Stack
                        styles={{ root: { height: 50 } }}
                        horizontal
                        verticalAlign="center"
                        tokens={spinnerStackTokens}
                    >
                        <Stack.Item align="center">
                            <Spinner size={SpinnerSize.large} />
                        </Stack.Item>
                        <Stack.Item align="center">
                            <Label>
                                {props.spinnerMessage
                                    ? props.spinnerMessage
                                    : "Saving"}
                            </Label>
                        </Stack.Item>
                    </Stack>
                )}
                {!spinning && (
                    <>
                        {props.showButtons && (
                            <Stack
                                styles={{
                                    root: { paddingTop: theme.spacing.m },
                                }}
                                horizontal
                                tokens={{ childrenGap: theme.spacing.s1 }}
                            >
                                <PrimaryButton
                                    onClick={async function () {
                                        await submitFunciton()
                                    }}
                                    text={
                                        props.actionText
                                            ? props.actionText
                                            : "Save"
                                    }
                                />

                                {props.onCancel && (
                                    <DefaultButton
                                        onClick={() => {
                                            props.onCancel()
                                        }}
                                        text="Cancel"
                                    />
                                )}
                            </Stack>
                        )}
                    </>
                )}
            </Stack>
        </form>
    )
}

export function Events(props) {
    const entity = props.entity
    const events = entity[Symbol.for("events")]
        ? entity[Symbol.for("events")]
        : []

    const theme = useTheme()
    const users = useEntityFromConfig(userConfig)
    const columns = [
        {
            key: "userId",
            name: "User",
            flexGrow: 2,
            isMultiline: true,
            minWidth: 100,
        },
        {
            key: "at",
            name: "At",
            flexGrow: 2,
            minWidth: 70,
            isMultiline: true,
        },
        {
            key: "type",
            name: "Action",
            flexGrow: 1,
            minWidth: 60,
        },
    ]
    return (
        <div
            style={{
                paddingTop: theme.spacing.l2,
            }}
        >
            <Text variant="large">Events</Text>
            <DetailsList
                columns={columns}
                items={events}
                compact
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                isHeaderVisible={true}
                onRenderItemColumn={(item, index, column) => {
                    if (column.key === "userId") {
                        const user = users.filter([
                            {
                                field: "id",
                                type: "eq",
                                value: item[column.key],
                            },
                        ])
                        if (user.length > 0) {
                            return user[0].contact.name
                        } else if (item["type"] === "view") {
                            return <span>share</span>
                        } else {
                            return "system"
                        }
                    }
                    if (column.key === "at") {
                        const date = new Date(item[column.key])
                        return date.toLocaleString()
                    }
                    return item[column.key]
                }}
            ></DetailsList>
        </div>
    )
}
