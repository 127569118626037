export function padNumber(n, width, z) {
    z = z || "0"
    n = n + ""
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export function calPercnt(num, percentage) {
    const result = num * (percentage / 100)
    return parseFloat(result.toFixed(2))
}

export function moneyFormatter(value) {
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    })
    return formatter.format(value / 100)
}
