import React, { useMemo, useState, useRef } from "react"
import ActionList from "../layouts/ActionList"
import CenterLayout from "../layouts/CenterLayout"
import { ProjectForm, useProjectConfig } from "./Projects"
import { clientConfig } from "./Clients"
import UpsertPanel from "../layouts/UpsertPanel"
import config from "../config"
import { useGlobalState } from "../state"
import { useEntity } from "@prospecer/ffsds/src/fe/entity"
import { ActionButton, Stack, Toggle } from "@fluentui/react"
import { v4 as uuidv4 } from "uuid"
import { useBoolean } from "@fluentui/react-hooks"

export default function ProjectOpener(props) {
    const [addModal, setAddModal] = useState(null)
    const submitRef = useRef()
    const uuid = useMemo(() => {
        return uuidv4()
    }, [])
    const [showArchived, { toggle: toggleArchived }] = useBoolean(false)

    const projectsAccess = useEntity(config, useProjectConfig(), useGlobalState)
    const clientAccess = useEntity(config, clientConfig, useGlobalState)
    const projects = projectsAccess.filter([
        ...(!showArchived
            ? [{ field: "archive", type: "eq", value: "0" }]
            : []),
    ])

    const i = []
    for (const p of projects) {
        const client = clientAccess.filter([
            { field: "id", type: "eq", value: p.clientId },
        ])

        if (client.length > 0) {
            i.push({
                clickData: p.id,
                name: p.name,
                description: client[0].name,
            })
        }
    }
    const items = i

    return (
        <CenterLayout>
            {addModal}
            <Stack horizontal>
                <ActionButton
                    iconProps={{ iconName: "Add" }}
                    onClick={() => {
                        setAddModal(
                            <UpsertPanel
                                buttonLabel={"Add and Select Project"}
                                submitRef={submitRef}
                                AddForm={(props) => {
                                    return (
                                        <ProjectForm
                                            ensureId={uuid}
                                            {...props}
                                        />
                                    )
                                }}
                                onClose={(saved) => {
                                    if (saved) {
                                        const event = new CustomEvent(
                                            "openProject",
                                            {
                                                detail: uuid,
                                            }
                                        )
                                        document.body.dispatchEvent(event)
                                    }
                                    setAddModal(null)
                                }}
                            />
                        )
                    }}
                >
                    Add Project
                </ActionButton>
                <Stack.Item grow="9">
                    <div> </div>
                </Stack.Item>
                <Stack.Item align="end">
                    <Toggle
                        label="Archived"
                        onText="Show"
                        offText="Hide"
                        onChange={toggleArchived}
                    />
                </Stack.Item>
            </Stack>
            <ActionList
                items={items}
                itemClick={(id) => {
                    const event = new CustomEvent("openProject", {
                        detail: id,
                    })
                    document.body.dispatchEvent(event)
                }}
            />
        </CenterLayout>
    )
}
