import { Stack } from "@fluentui/react"
import React from "react"

export default function CenterLayout(props) {
    return (
        <Stack styles={{ root: { overflow: "auto" } }} horizontal>
            <Stack.Item grow={1}>&nbsp;</Stack.Item>
            <Stack.Item grow={2}>{props.children}</Stack.Item>
            <Stack.Item grow={1}>&nbsp;</Stack.Item>
        </Stack>
    )
}
