import React from "react"

import { user as userValadator } from "@prospecer/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { infoConfig } from "./Infos"
import { useLiveEvents } from "@prospecer/ffsds/src/fe/entity"

export const userConfig = {
    baseUrl: config.api.baseUrl + "/users",
    type: "USERS",
    stateKey: "users",
    name: "Users",
    nameSingle: "User",
    readScope: "read:users",
    writeScope: "write:users",
    defaultSortKey: "contact.email",
    listColumns: [
        {
            key: "contact.email",
            title: "Email",
            additionalKeys: ["enabled"],
            formatter: (value, values) => {
                let v = value
                if (!values[0]) {
                    v = v + " (disabled)"
                }
                return v
            },
        },
        {
            key: "contact.name",
            title: "Name",
        },
        {
            key: "contact.phone",
            title: "Phone Number",
        },
    ],
    formFields: [
        {
            label: "Name",
            key: "contact.name",
            type: "text",
        },
        {
            label: "Email",
            key: "contact.email",
            type: "email",
        },
        {
            label: "Role",
            key: "role",
            type: "dropdown",
            options: [
                { key: "designer", text: "Designer" },
                { key: "owner", text: "Owner" },
            ],
        },
        {
            label: "Login",
            key: "enabled",
            type: "dropdown",
            options: [
                { key: 1, text: "Enabled" },
                { key: 0, text: "Disabled" },
            ],
        },
        {
            label: "Password",
            key: "password",
            type: "password",
            nullable: true,
        },
        {
            label: "Address",
            key: "contact.address",
            type: "text",
            option: "multiline",
        },
        {
            label: "Country",
            key: "contact.country",
            type: "country",
        },
        {
            label: "City",
            key: "contact.city",
            type: "text",
        },
        {
            label: "State",
            key: "contact.state",
            type: "state",
            coupledValue: "contact.country",
        },

        {
            label: "Zip/Postal",
            key: "contact.postal",
            type: "text",
        },
        {
            label: "Phone",
            key: "contact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },
    ],
    emptyFormValue: { contact: {} },
    validator: userValadator,
    Form: UserForm,
}

function useCanUpsert(props) {
    const useInfo = useEntityFromConfig(infoConfig)
    const useUsers = useEntityFromConfig(userConfig)

    const infos = useInfo.filter([])
    const userId = infos[0] ? infos[0].userId : null
    let readOnly = true
    if (infos[0]?.isGod) {
        readOnly = false
    } else if (userId) {
        const users = useUsers.filter([
            { field: "id", type: "eq", value: userId },
        ])

        if (props.values && props.values.id === userId) {
            readOnly = false
        }

        if (users[0] && users[0].role === "owner") {
            readOnly = false
        }
    }
    return !readOnly
}

export function UsersList(props) {
    const readOnly = !useCanUpsert(props)

    return <EntityList config={userConfig} hideNew={readOnly} />
}

export function UserForm(props) {
    const readOnly = !useCanUpsert(props)

    return <EntityForm {...props} config={userConfig} readOnly={readOnly} />
}
