import React, { useEffect, useRef } from "react"

import {
    studio as studioValidator,
    signup as signupValidator,
    signup,
} from "@prospecer/schema"
import config from "../config"
import { EntityForm, EntityList } from "./Entities"
import { Icon } from "@fluentui/react"
import { useCalls } from "@prospecer/ffsds/src/fe/entity"
import { useEntityFromConfig } from "../hooks/Entity"
import { cloneDeep } from "lodash"
import { TaxesForm, taxesConfig, useTaxControl } from "./Taxes"

function GodButton(props) {
    const godcall = useCalls(config, {
        baseUrl: config.api.baseUrl + "/godmode",
    })
    return (
        <Icon
            styles={{ root: { cursor: "pointer" } }}
            iconName="TriggerUser"
            onClick={async () => {
                await godcall.insert({ studioId: props.studioId })
                document.location.reload()
            }}
        />
    )
}

export function useStudioConfig() {
    return {
        baseUrl: config.api.baseUrl + "/studios",
        type: "STUDIOS",
        stateKey: "studios",
        name: "Studios",
        nameSingle: "Studio",
        readScope: "read:studio",
        defaultSortKey: "id",
        validator: studioValidator,
        listColumns: [
            {
                key: "name",
                title: "Full",
            },
            {
                key: "god",
                title: "Become",
                additionalKeys: ["id"],
                formatter: (v, values) => {
                    return <GodButton studioId={values[0]} />
                },
            },
            {
                key: "contact.name",
                title: "Studio Contact Name",
            },
            {
                key: "activeUsers",
                title: "Active Users",
            },
            {
                key: "contact.phone",
                title: "Phone Number",
            },

            {
                key: "contact.email",
                title: "Studio Email",
            },
        ],
        formFields: [],
        emptyFormValue: { __new: true, contact: {} },
        Form: StudioForm,
    }
}

export function usePublicStudioConfig(projectSharesId, studioId) {
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/studios?id=" +
            projectSharesId +
            "&studioId=" +
            studioId,
        type: "STUDIOS",
        stateKey: "studios",
        name: "Studios",
        nameSingle: "Studio",
        readScope: "read:studio",
        defaultSortKey: "id",
        validator: studioValidator,
        insecure: true,
    }
}

function removeFormInput(config, key) {
    const index = config.formFields.findIndex((i) => i.key === key)
    if (index > -1) {
        config.formFields.splice(index, 1)
    }
}

export function useStudioFormFields() {
    const taxControl = useTaxControl("defaultTaxIds", "Default Tax")
    return [
        {
            label: "Studio Name",
            key: "name",
            type: "text",
        },
        {
            label: "Logo",
            key: "image",
            type: "image",
            onlyOne: true,
            allowDelete: true,
        },
        {
            label: "Brand Color",
            key: "color",
            type: "color",
        },
        {
            ...taxControl,
        },
        {
            label: "Address",
            key: "contact.address",
            type: "text",
            option: "multiline",
        },
        {
            label: "Country",
            key: "contact.country",
            type: "country",
        },
        {
            label: "City",
            key: "contact.city",
            type: "text",
        },
        {
            label: "State/Province",
            key: "contact.state",
            type: "state",
            coupledValue: "contact.country",
        },
        {
            label: "Zip/Postal",
            key: "contact.postal",
            type: "text",
        },
        {
            label: "Primary Contact",
            type: "separator",
            key: "pcSep",
            displayOnly: true,
        },
        {
            label: "Full Name",
            key: "contact.name",
            type: "text",
        },
        {
            label: "Phone",
            key: "contact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },

        {
            label: "Email",
            key: "contact.email",
            type: "email",
        },
    ]
}

export function SignupStudioForm(props) {
    const signupConfig = cloneDeep(useStudioConfig())
    signupConfig.baseUrl = config.api.baseUrl + "/signup"
    signupConfig.validator = signupValidator
    signupConfig.insecure = true
    signupConfig.formFields = useStudioFormFields()
    removeFormInput(signupConfig, "image")
    removeFormInput(signupConfig, "color")
    removeFormInput(signupConfig, "defaultTax")
    removeFormInput(signupConfig, "contact.address")
    removeFormInput(signupConfig, "contact.postal")
    removeFormInput(signupConfig, "contact.city")
    removeFormInput(signupConfig, "contact.phone")
    removeFormInput(signupConfig, "defaultTaxIds")
    signupConfig.formFields.push({
        label: "Password",
        key: "password",
        type: "password",
    })

    return <EntityForm {...props} config={signupConfig} />
}

export function StudioForm(props) {
    const studioConfig = useStudioConfig()
    const useStudio = useEntityFromConfig(useStudioConfig())
    studioConfig.formFields = useStudioFormFields()

    const clone = {
        label: "Clone From",
        key: "cloneFrom",
        type: "entity",
        descrKeyPath: "name",
        valueKeyPath: "id",
        options: useStudio.filter([]),
    }

    const paymentMethod = {
        label: "Payment Method",
        key: "paymentMethod",
        type: "dropdown",
        options: [
            { key: "stripe", text: "Stripe" },
            { key: "invoiced", text: "Invoiced" },
        ],
    }

    const appendedConfig = !props.values
        ? {
              ...studioConfig,
              formFields: [...studioConfig.formFields, clone, paymentMethod],
          }
        : studioConfig
    return <EntityForm {...props} config={appendedConfig} />
}

export function StudioList(props) {
    const config = { ...useStudioConfig() }

    return <EntityList config={config} />
}
