import {
    projectShares as projectSharesValadator,
    projectSharesConsultant as projectSharesConsultantValadator,
    projectSharesSupplier as projectSharesSupplierValadator,
} from "@prospecer/schema"
import config from "../config"
import { useEntityFromConfig } from "../hooks/Entity"
import { supplierConfig } from "./Suppliers"
import { useConfig as useSpecificationsConfig } from "./Specifications"
import { useProjectConfig } from "./Projects"
import { EntityForm, EntityList } from "./Entities"
import { useStudioConfig } from "./Studios"
import { infoConfig } from "./Infos"
import { ConsultantForm, consultantsConfig } from "./Consultants"
import { cloneDeep } from "lodash"
import { clientConfig } from "./Clients"

export function useConfig(projectId, forPublic = false) {
    const useSupplier = useEntityFromConfig(supplierConfig)
    const useConsultants = useEntityFromConfig(consultantsConfig)
    const useSpecifications = useEntityFromConfig(useSpecificationsConfig())
    const studioConfig = useStudioConfig()

    const useStudios = useEntityFromConfig(studioConfig)
    const useInfos = useEntityFromConfig(infoConfig)
    const useClient = useEntityFromConfig(clientConfig)
    const useProject = useEntityFromConfig(useProjectConfig())

    const specFilter = forPublic
        ? false
        : useSpecifications.filter([
              { field: "projectId", type: "eq", value: projectId },
          ])

    const suppliers = specFilter ? specFilter.map((a) => a.supplierId) : []

    return {
        baseUrl: config.api.baseUrl + "/projectshares",
        type: "PROJECT_SHARES",
        stateKey: "project_shares",
        name: "Project Shares",
        nameSingle: "Project Share",
        readScope: "read:projects",
        writeScope: "write:projects",
        defaultSortKey: "enabled",
        validator: projectSharesValadator,
        allowDelete: true,
        partitionField: "projectId",
        partitionKey: projectId,
        disablePrint: true,
        showEventsOnForm: true,
        filter: [{ field: "projectId", type: "eq", value: projectId }],

        listColumns: [
            {
                key: "type",
                title: "Type",
                additionalKeys: ["consultantId", "supplierId"],

                formatter: (value, values) => {
                    if (values[0]) {
                        return "Consultant"
                    } else if (values[1]) {
                        return "Supplier"
                    }
                    return "Client"
                },
            },

            {
                key: "Who",
                title: "Who",
                additionalKeys: ["consultantId", "supplierId"],
                formatter: (value, values) => {
                    if (values[0]) {
                        const c = useConsultants.filter([
                            { field: "id", type: "one", value: values[0] },
                        ])

                        if (!c[0]) {
                            return null
                        }
                        return c[0].name
                    } else if (values[1]) {
                        const r = useSupplier.filter([
                            { field: "id", type: "one", value: values[1] },
                        ])
                        if (!r[0]) {
                            return null
                        }
                        return r[0].name
                    } else {
                        return clientName(projectId, useClient, useProject)
                    }
                },
            },
            {
                key: "pricing",
                title: "Pricing",
                formatter: (value) => {
                    if (value) {
                        return "Yes"
                    }
                    return "No"
                },
            },
            {
                key: "enabled",
                title: "Enabled",
                formatter: (value) => {
                    if (value) {
                        return "Yes"
                    }
                    return "No"
                },
            },

            {
                key: "note",
                title: "Note",
            },
            {
                key: "last_access",
                title: "Last Access",
                includeEvents: true,
                formatter: (value, values) => {
                    if (values) {
                        const events = values[0]
                        if (!events) {
                            return "never"
                        }
                        let last = null
                        for (const e of events) {
                            if (e.type === "view") {
                                last = e
                            }
                        }
                        if (last === null) {
                            return "never"
                        }
                        return new Date(last.at).toLocaleString()
                    }
                    return null
                },
            },
        ],
        formFields: [
            {
                label: "Supplier",
                key: "supplierId",
                type: "entity",
                nullable: true,
                descrKeyPath: "name",
                valueKeyPath: "id",
                required: true,
                options: useSupplier.filter([
                    { field: "id", type: "in", value: suppliers },
                ]),
                disabledOnUpdate: true,
            },

            {
                label: "Share Issuances with Consultant",
                key: "consultantId",
                type: "entity",
                descrKeyPath: "name",
                valueKeyPath: "id",
                required: true,

                nullable: true,
                Form: ConsultantForm,
                options: useConsultants.filter([]),
                disabledOnUpdate: true,
            },

            {
                label: "Send Email",
                key: "sendEmail",
                type: "toggle",
                onText: "Send",
                offText: "Don't Send",
                disabledOnUpdate: true,
            },

            {
                type: "group",
                fields: [
                    {
                        label: <>Show Pricing &nbsp;</>,
                        key: "pricing",
                        type: "toggle",
                        onText: "Yes",
                        offText: "No",
                    },
                    {
                        label: " Leadtime",
                        key: "leadtime",
                        type: "toggle",
                        onText: "Yes",
                        offText: "No",
                    },
                ],
            },
            {
                label: "Show Urls",
                key: "urls",
                type: "toggle",
                onText: "Yes",
                offText: "No",
            },
            {
                label: "Show Suppliers",
                key: "showSupplier",
                type: "toggle",
                onText: "Yes",
                offText: "No",
            },
            {
                label: "Enabled",
                key: "enabled",
                type: "toggle",
                onText: "Yes",
                offText: "No",
            },

            {
                label: "Note",
                key: "note",
                type: "text",
                option: "multiline",
            },
            {
                key: "link",
                type: "info",
                displayOnly: true,
                label: "Link",
                copy: true,
                qrCode: true,

                valueFunction: (values) => {
                    const infos = useInfos.filter([])
                    if (!infos[0]) {
                        return ""
                    }
                    const studio = useStudios.filter([
                        { field: "id", type: "eq", value: infos[0].studioId },
                    ])
                    if (studio.length === 1) {
                        return (
                            config.main.baseUrl +
                            "/share/" +
                            studio[0].id +
                            "/" +
                            values.id
                        )
                    }
                    return ""
                },
            },
        ],
        emptyFormValue: {
            __new: true,
            enabled: 1,
            sendEmail: 1,
            projectId: projectId,
            pricing: 0,
            leadtime: 1,
            urls: 0,
            showSupplier: 1,
            events: [],
        },
        Form: (props) => {
            return <ProjectSharesForm {...props} projectId={projectId} />
        },
        newOptions: [
            {
                text: "Share Supplier Specifications",
                prop: "type",
                value: "supplier",
            },
            {
                text: "Share Issuances with Consultants",
                prop: "type",
                value: "consultant",
            },
            {
                text: "Share Issuances with Client",
                prop: "type",
                value: "client",
            },
        ],
    }
}

export function usePublicProjectSharesConfig(studioId, id) {
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/projectshares?id=" +
            id +
            "&studioId=" +
            studioId,
        type: "PROJECT_SHARES",
        stateKey: "project_shares",
        name: "Project Shares",
        nameSingle: "Project Share",
        readScope: "read:projects",
        writeScope: "write:projects",
        defaultSortKey: "enabled",
        validator: projectSharesValadator,
        allowDelete: false,

        disablePrint: true,
        showEventsOnForm: true,
        insecure: true,
    }
}

function clientName(projectId, useClient, useProject) {
    const projects = useProject.filter([
        { field: "id", type: "eq", value: projectId },
    ])
    if (!projects[0]) {
        return ""
    }

    const client = useClient.filter([
        { field: "id", type: "eq", value: projects[0].clientId },
    ])

    if (client[0]) {
        return client[0].name
    }
}

export function ProjectSharesList(props) {
    const projectSharesConfig = useConfig(props.projectId)
    return <EntityList config={projectSharesConfig} />
}

export function ProjectSharesForm(props) {
    const projectSharesConfig = useConfig(props.projectId)
    const useClient = useEntityFromConfig(clientConfig)
    const useProject = useEntityFromConfig(useProjectConfig())

    function removeSupplier() {
        const ci = projectSharesConfig.formFields.findIndex(
            (e) => e.key === "consultantId"
        )
        projectSharesConfig.formFields.splice(ci, 1)
        projectSharesConfig.validator = projectSharesSupplierValadator
    }

    function removeConsulant() {
        const ci = projectSharesConfig.formFields.findIndex(
            (e) => e.key === "supplierId"
        )
        projectSharesConfig.formFields.splice(ci, 1)
        projectSharesConfig.validator = projectSharesConsultantValadator
    }

    if (
        props.type === "supplier" ||
        (props.values && props.values.supplierId)
    ) {
        removeSupplier()
    } else if (
        props.type === "consultant" ||
        (props.values && props.values.consultantId)
    ) {
        removeConsulant()
    } else if (
        props.type === "client" ||
        (props.values && !props.values.consultantId && props.values.supplierId)
    ) {
        removeSupplier()
        removeConsulant()
        projectSharesConfig.validator = projectSharesValadator
        projectSharesConfig.formFields.unshift({
            key: "client",
            type: "info",
            displayOnly: true,
            label: "Client",

            valueFunction: (values) => {
                return clientName(props.projectId, useClient, useProject)
            },
        })
    }

    return <EntityForm {...props} config={projectSharesConfig} />
}
