import {
    AnimationClassNames,
    Icon,
    Layer,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    Stack,
    mergeStyles,
    useTheme,
} from "@fluentui/react"
import { createContext, useContext, useEffect, useReducer } from "react"
import { v4 as uuidv4 } from "uuid"

function setupNotificationState() {
    const NotificationContext = createContext({ notifications: [] })

    function NotificationProvider({ children }) {
        const [state, dispatch] = useReducer(notificationReducer, {
            notifications: [],
        })

        const value = { state, dispatch }
        return (
            <NotificationContext.Provider value={value}>
                {children}
            </NotificationContext.Provider>
        )
    }
    const useNotificationState = () => useContext(NotificationContext)

    return { NotificationProvider, NotificationContext, useNotificationState }
}

export const {
    NotificationProvider,
    NotificationContext,
    useNotificationState,
} = setupNotificationState()

function notificationReducer(state, action) {
    if (action.type === "NOTIFICATION") {
        if (action.action === "ADD") {
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            }
        } else if (action.action === "REMOVE") {
            const notif = [...state.notifications]
            const dkey = notif.findIndex((x) => x.id === action.payload.id)
            if (dkey === -1) {
                return state
            } else {
                notif.splice(dkey, 1)
            }
            return {
                ...state,
                notifications: notif,
            }
        } else if (action.action === "UPSERT") {
            const notif = [...state.notifications]
            const dkey = notif.findIndex((x) => x.id === action.payload.id)
            if (dkey === -1) {
                return {
                    ...state,
                    notifications: [...state.notifications, action.payload],
                }
            } else {
                notif[dkey] = action.payload
            }
            return {
                ...state,
                notifications: notif,
            }
        }
    }
    return state
}

export function useNotification() {
    const { state, dispatch } = useNotificationState()
    const notify = (type, message) => {
        const id = uuidv4()
        dispatch({
            type: "NOTIFICATION",
            action: "ADD",
            payload: { type: type, message: message, id: id },
        })
        setTimeout(() => {
            dispatch({
                type: "NOTIFICATION",
                action: "REMOVE",
                payload: { id: id },
            })
        }, 5000)
    }
    const info = (message) => {
        notify("info", message)
    }
    const error = (message) => {
        notify("error", message)
    }
    /*
     ** OBJ shape
     ** {
     **   title:"Marking Specs",
     **   description:"Bla",
     **   percentComplete:0.0-1 MUST HIT 1
     **   onCancel: ()=>{}
     ** }
     */
    const status = (id, statusObj) => {
        if (statusObj.percentComplete >= 1) {
            dispatch({
                type: "NOTIFICATION",
                action: "UPSERT",
                payload: { type: "status", status: statusObj, id: id },
            })
            setTimeout(() => {
                dispatch({
                    type: "NOTIFICATION",
                    action: "REMOVE",
                    payload: { id: id },
                })
            }, 2000)
        } else {
            dispatch({
                type: "NOTIFICATION",
                action: "UPSERT",
                payload: { type: "status", status: statusObj, id: id },
            })
        }
    }
    return {
        state,
        notifications: state.notifications,
        info,
        error,
        notify,
        dispatch,
        status,
    }
}

function StatusNotification(props) {
    const label = (
        <div style={{ width: "100%" }}>
            <Stack
                styles={{ root: { width: "100%" } }}
                enableScopedSelectors
                horizontal
                disableShrink
            >
                <Stack.Item grow align="begin">
                    <div>{props.title}</div>
                </Stack.Item>
                {props.onCancel && (
                    <Stack.Item align="end">
                        <Icon
                            styles={{ root: { cursor: "pointer" } }}
                            iconName="ChromeClose"
                            onClick={() => {
                                props.onCancel()
                            }}
                        />
                    </Stack.Item>
                )}
            </Stack>
        </div>
    )
    return (
        <ProgressIndicator
            styles={{ root: { width: "100%" } }}
            label={label}
            description={props.description}
            percentComplete={props.percentComplete}
        />
    )
}

export function NotificationArea() {
    const theme = useTheme()
    const contentClass = mergeStyles([
        {
            color: theme.palette.white,
            lineHeight: "50px",
            bottom: 0,
            position: "fixed",
            right: 0,
            textAlign: "center",
            minWidth: "300px",
            padding: theme.spacing.m,
        },

        AnimationClassNames.fadeIn100,
    ])
    const messageBarStyles = {
        root: {
            margin: 5,
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.3)",
            width: "calc(300px" + theme.spacing.m + ")",
        },
    }

    const statusBarStyles = {
        root: {
            ...messageBarStyles.root,
            backgroundColor: theme.palette.white,
            padding: theme.spacing.l1,
        },
    }
    const { notifications, notify } = useNotification()

    useEffect(() => {
        const f = (e) => {
            const message = { type: e.detail.type, message: e.detail.message }
            notify(message.type, message.message)
        }
        document.addEventListener("infoMessage", f)
        return () => {
            document.removeEventListener("infoMessage", f)
        }
    }, [notifications, notify])
    return (
        <>
            {notifications.length > 0 && (
                <Layer>
                    <div className={contentClass}>
                        {notifications.map((message, i) => {
                            if (message.type === "status") {
                                return (
                                    <div style={statusBarStyles.root}>
                                        <StatusNotification
                                            {...message.status}
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <MessageBar
                                        key={i}
                                        styles={messageBarStyles}
                                        messageBarType={
                                            message.type === "error"
                                                ? MessageBarType.error
                                                : MessageBarType.info
                                        }
                                    >
                                        {message.message}
                                    </MessageBar>
                                )
                            }
                        })}
                    </div>
                </Layer>
            )}
        </>
    )
}
