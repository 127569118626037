import React, { useEffect, useRef, useState } from "react"
import { useBoolean, useId } from "@fluentui/react-hooks"

import { useLocation, useHistory } from "react-router-dom"

import { Nav as FNav } from "@fluentui/react/lib/Nav"

import {
    Callout,
    DefaultButton,
    DialogType,
    FontWeights,
    mergeStyles,
    mergeStyleSets,
    Stack,
    Text,
    useTheme,
} from "@fluentui/react"
import { useEntityFromConfig } from "../hooks/Entity"
import { infoConfig } from "./Infos"
import { userConfig } from "./Users"
import Modal from "../layouts/Modal"
import { ReferForm } from "./Refers"
import UpsertModal from "../layouts/UpsertModal"

import ConfirmDialog from "../layouts/ConfimDialog"

function useStyles() {
    const theme = useTheme()
    const [navStyles, setNavStyles] = useState({})
    const [navStylesSmall, setNavStylesSmall] = useState({})

    useEffect(() => {
        setNavStyles({
            root: {
                background: theme.palette.neutralLight,
                color: theme.palette.black,
                overflowY: "auto",
                width: 200,
                height: "100%",
            },
            link: {
                paddingLeft: 10,
            },
        })

        setNavStylesSmall({
            root: {
                background: theme.palette.neutralLight,
                color: theme.palette.black,
                overflowY: "auto",
                width: 40,
                height: "100%",
            },
            link: {
                paddingLeft: 10,
            },
        })
    }, [theme.palette.neutralLight, theme.palette.black])

    const rButtonStyle = mergeStyles({
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "30px",
    })

    return [navStylesSmall, navStyles, rButtonStyle]
}

export default function Nav(props) {
    const location = useLocation()
    const history = useHistory()
    const [navStylesSmall, navStyles, rButtonStyle] = useStyles()
    const infos = useEntityFromConfig(infoConfig)
    const info = infos.filter([])
    const navLinks = [
        {
            links: [
                {
                    name: "Open/New Project",
                    link: "/open",
                    key: "/open",
                    icon: "OpenFile",
                },
                {
                    name: "Clients",
                    link: "/clients",
                    key: "/clients",
                    icon: "AccountManagement",
                },
                {
                    name: "Users",
                    link: "/users",
                    key: "/users",
                    icon: "Contact",
                },
                {
                    name: "Suppliers",
                    link: "/suppliers",
                    key: "/suppliers",
                    icon: "DeliveryTruck",
                },
                {
                    name: "Projects",
                    link: "/projects",
                    key: "/projects",
                    icon: "Shapes",
                },
                {
                    name: "Tag Types",
                    link: "/tagtypes",
                    key: "/tagtypes",
                    icon: "Tag",
                },
                {
                    name: "Consultants",
                    link: "/consultants",
                    key: "/consultants",
                    icon: "ConstructionCone",
                },
                {
                    name: "Taxes",
                    link: "/taxes",
                    key: "/taxes",
                    icon: "Bank",
                },
            ],
        },
    ]
    if (info && info[0] && info[0].isGod) {
        navLinks[0].links.push({
            name: "Studios",
            link: "/studios",
            key: "/studios",
            icon: "Teamwork",
        })
    }

    const stackClass = mergeStyles({ height: "95vh" })

    return (
        <div className="no-print">
            <Stack className={stackClass}>
                <FNav
                    selectedKey={
                        location.pathname === "/"
                            ? "/clients"
                            : location.pathname
                    }
                    groups={navLinks}
                    styles={props.size !== "large" ? navStylesSmall : navStyles}
                    onLinkClick={(event, element) => {
                        history.push(element.link)
                    }}
                />
                <Stack.Item grow={2}>
                    <div>&nbsp;</div>
                </Stack.Item>
                <div className={rButtonStyle}>
                    <ReferButton {...props} />
                </div>
            </Stack>
        </div>
    )
}

function useReferStyles() {
    return mergeStyleSets({
        button: {
            width: 130,
        },
        callout: {
            width: 320,
            maxWidth: "90%",
            padding: "20px 24px",
        },
        title: {
            marginBottom: 12,
            fontWeight: FontWeights.semilight,
        },
        link: {
            display: "block",
            marginTop: 20,
        },
    })
}

function ThankYouDialog(props) {
    const options = {
        type: DialogType.normal,
        title: "Thank you",
        closeButtonAriaLabel: "Close",
        subText:
            "Thank you for spreading the word! $50 is coming once they signup. ",
    }
    return (
        <ConfirmDialog
            {...props}
            noPrimaryButton
            dismissText="Your Welcome"
            dialogContentProps={options}
        ></ConfirmDialog>
    )
}

function ReferButton(props) {
    const [modal, setModal] = useState(null)
    const formRef = useRef()
    const labelId = useId("callout-label")
    const buttonId = useId("callout-button")
    const [
        isCalloutVisible,
        {
            toggle: toggleIsCalloutVisible,
            setTrue: calloutOn,
            setFalse: calloutOff,
        },
    ] = useBoolean(false)
    const descriptionId = useId("callout-description")
    const styles = useReferStyles()

    const useUsers = useEntityFromConfig(userConfig)
    const useInfo = useEntityFromConfig(infoConfig)

    const infos = useInfo.filter([])
    const info = infos[0] ? infos[0] : {}

    const users = info.userId
        ? useUsers.filter([{ field: "id", type: "eq", value: info.userId }])
        : []
    const user = users[0] ? users[0] : {}

    useEffect(() => {
        if (user.id) {
            if (!user.prefs.seenReferalPromo) {
                calloutOn()
                setTimeout(() => calloutOff(), 5000)
                user.prefs.seenReferalPromo = true
                useUsers.dispatch.upsert(user)
            }
        }
    }, [user])

    function StoryTime(props2) {
        return (
            <>
                {!props2.onModal && (
                    <Text
                        as="h1"
                        block
                        variant="xLarge"
                        className={styles.title}
                        id={labelId}
                    >
                        Refer a Friend Get $50
                    </Text>
                )}
                {props2.onModal && (
                    <Text
                        as="h1"
                        block
                        variant="xLarge"
                        className={styles.title}
                        id={labelId}
                    >
                        Invite your friend to try Prospecer
                    </Text>
                )}
                <Text
                    block
                    variant={props2.onModal ? "medium" : "small"}
                    id={descriptionId}
                >
                    Refer a friend and when they sign up get a $50 prepaid
                    virtual mastercard mailed to
                    {user && user.contact && user.contact.email}.
                    <br />
                    <br />
                    Applies to users of studios without outstanding balences.
                    Payout is sent after refered studio pays for second month.
                </Text>
            </>
        )
    }
    return (
        <>
            {modal}
            <DefaultButton
                onMouseOver={() => {
                    calloutOn()
                }}
                onMouseOut={() => {
                    calloutOff()
                }}
                id={buttonId}
                styles={{ root: { minWidth: 0, padding: 5 } }}
                iconProps={{ iconName: "Money" }}
                onClick={() => {
                    setModal(
                        <UpsertModal
                            buttonLabel={"Refer Your Friend"}
                            submitRef={formRef}
                            AddForm={ReferForm}
                            onClose={(values) => {
                                if (values) {
                                    setModal(
                                        <ThankYouDialog
                                            onDismiss={() => {
                                                setModal(null)
                                            }}
                                        />
                                    )
                                } else {
                                    setModal(null)
                                }
                            }}
                        >
                            <div style={{ maxWidth: "400px" }}>
                                <StoryTime onModal />
                            </div>
                            <br />
                        </UpsertModal>
                    )
                }}
            >
                {props.size === "large" && <>Refer a Friend</>}
            </DefaultButton>
            {isCalloutVisible && (
                <Callout
                    className={styles.callout}
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={descriptionId}
                    role="dialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <StoryTime />
                </Callout>
            )}
        </>
    )
}
