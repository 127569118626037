import React, { useEffect, useMemo, useState } from "react"

import { invoiceLineItems as invoiceLIneItemsValadator } from "@prospecer/schema"
import config from "../config"

import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { useConfig as useProjectConfig } from "./Projects"
import { Stack } from "@fluentui/react"
import { InvoicePanel, InvoiceLayout } from "../layouts/InvoiceLayout"
import { clientConfig } from "./Clients"
import { v4 as uuidv4 } from "uuid"
import { moneyFormatter } from "../utils"
import { useTaxControl } from "./Taxes"
import { useConfig as useSpecificationConfig } from "./Specifications"
import { SpecificationCard } from "./SpecificationCard"
import MiniList from "../layouts/MiniList"

/*
id: yup.string().matches(uuid, "id must be a valid uuid").required(),
invoiceId: yup
    .string()
    .matches(uuid, "invoiceId must be a valid UUID")
    .required(),
specificationId: yup
    .string()
    .matches(uuid, "specificationId must be a valid UUID"),
note: yup.string().ensure(),
quantity: yup.number().integer().required(),
price: yup.number().required().integer(),
subTotal: yup.number().required().integer(),
taxAmount: yup.number().required().integer(),
*/

function useInvoiceLineItemsColumns(
    invoiceId,
    projectId,
    publicAccess = false,
    po = false
) {
    return [
        {
            key: "note",
            title: "Description",
        },
        ...(po
            ? [
                  {
                      key: "meta",
                      title: "Info",
                      formatter: (value) => {
                          return <MiniListForPos meta={value} />
                      },
                  },
              ]
            : []),
        ...(!publicAccess
            ? [
                  {
                      key: "specificationId",
                      title: "Spec",
                      formatter: (value, values) => {
                          if (value && !publicAccess) {
                              return (
                                  <div>
                                      <SpecificationCard
                                          id={value}
                                          projectId={projectId}
                                      />
                                  </div>
                              )
                          }
                      },
                  },
              ]
            : []),
        {
            key: "price",
            title: "Price",
            formatter: moneyFormatter,
        },
        {
            key: "subTotal",
            title: "Sub Total",
            formatter: moneyFormatter,
        },
        {
            key: "taxAmount",
            title: "Tax Amount",
            formatter: moneyFormatter,
        },
        {
            key: "quantity",
            title: "Quantity",
        },
        {
            key: "total",
            title: "Total",
            formatter: moneyFormatter,
        },
    ]
}

function MiniListForPos(props) {
    const values = props.meta
    const columns = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            maxWidth: 70,
            minWidth: 70,
            isMultiline: false,
        },
        {
            key: "value",
            name: "Value",
            fieldName: "value",
            isMultiline: false,
        },
    ]
    const items = []
    for (const value of values) {
        items.push({
            key: 1,
            name: value.name,
            value: value.value,
        })
    }

    return <MiniList items={items} columns={columns} />
}

function useInvoiceLineItemFormFeilds(po = false, pub = false) {
    const taxControl = useTaxControl("taxIds", "Tax", pub)
    return [
        {
            label: "Note",
            key: "note",
            type: "text",
        },
        {
            label: "Price",
            key: "price",
            type: "money",
        },
        {
            ...taxControl,
        },
        {
            label: "Quantity",
            key: "quantity",
            type: "number",
        },
        ...(po
            ? [
                  {
                      label: "Info",
                      key: "meta",
                      type: "info",
                      component: true,
                      valueFunction: (values) => {
                          return <MiniListForPos meta={values.meta} />
                      },
                  },
              ]
            : []),
    ]
}

export function useInvoiceLineItmesConfig(invoiceId, projectId, po = false) {
    const taxControl = useTaxControl("taxIds", "Tax")
    const columns = useInvoiceLineItemsColumns(invoiceId, projectId, false, po)
    const formFiels = useInvoiceLineItemFormFeilds(po)
    return {
        baseUrl: config.api.baseUrl + "/invoiceLineItems",
        type: "INVOICE_LINE_ITEMS",
        stateKey: "invoice_line_items",
        name: "Invoice Line Items",
        nameSingle: "Invoice Line Item",
        readScope: "read:billing",
        writeScope: "write:billing",
        defaultSortKey: "name",
        partitionField: "invoiceId",
        partitionKey: invoiceId,
        filter: [{ field: "invoiceId", type: "eq", value: invoiceId }],
        listColumns: columns,
        formFields: formFiels,
        emptyFormValue: { invoiceId: invoiceId, taxIds: [] },
        validator: invoiceLIneItemsValadator,
        disablePrint: true,
        Form: (props) => {
            return (
                <InvoiceLineItemsForm
                    {...props}
                    invoiceId={invoiceId}
                    projectId={projectId}
                />
            )
        },
        allowDelete: true,
    }
}

export function usePublicInvoiceLineItmesConfig(
    projectSharesId,
    studioId,
    invoiceId,
    po = false
) {
    const columns = useInvoiceLineItemsColumns(false, false, true, po)
    const taxControl = useTaxControl("taxIds", "Tax", true)
    const formFiels = useInvoiceLineItemFormFeilds(po, true)

    return {
        baseUrl:
            config.api.baseUrl +
            (po ? "/public/poLineItems?id=" : "/public/invoiceLineItems?id=") +
            projectSharesId +
            "&studioId=" +
            studioId,
        type: "INVOICE_LINE_ITEMS",
        stateKey: "invoice_line_items",
        name: "Invoice Line Items",
        nameSingle: "Invoice Line Item",
        readScope: "read:billing",
        writeScope: "write:billing",
        defaultSortKey: "name",
        insecure: true,
        filter: [{ field: "invoiceId", type: "eq", value: invoiceId }],
        listColumns: columns,
        formFields: formFiels,
        Form: (props) => {
            return (
                <PublicInvoiceLineItemsForm
                    {...props}
                    projectSharesId={projectSharesId}
                    studioId={studioId}
                    invoiceId={invoiceId}
                    po={po}
                    readOnly
                />
            )
        },
    }
}

export function InvoiceLineItemsList(props) {
    const invoiceConfig = useInvoiceLineItmesConfig(
        props.invoiceId,
        props.projectId
    )
    return <EntityList {...props} config={invoiceConfig} />
}

export function InvoiceLineItemsForm(props) {
    const invoiceConfig = useInvoiceLineItmesConfig(
        props.invoiceId,
        props.projectId
    )
    const uuid = useMemo(() => {
        return uuidv4()
    }, [])
    const invoiceLineItemId = props.values?.id ?? uuid
    return (
        <EntityForm
            {...props}
            ensureId={invoiceLineItemId}
            config={invoiceConfig}
        />
    )
}

export function PublicInvoiceLineItemsForm(props) {
    const invoiceConfig = usePublicInvoiceLineItmesConfig(
        props.projectSharesId,
        props.studioId,
        props.invoiceId,
        props.po
    )
    const uuid = useMemo(() => {
        return uuidv4()
    }, [])
    const invoiceLineItemId = props.values?.id ?? uuid
    return (
        <EntityForm
            {...props}
            ensureId={invoiceLineItemId}
            config={invoiceConfig}
        />
    )
}
