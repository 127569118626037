import { Image, Stack } from "@fluentui/react"
import { useRef, useState } from "react"
import Panel from "./Panel"
import config from "../config"

export function InvoicePanel(props) {
    const submitRef = props.submitRef
    const [hideButtons, setHideButtons] = useState(props.hideButtons)

    return (
        <Panel
            large
            hideButtons
            title={props.title}
            onClose={() => {
                props.onClose(false)
            }}
            onSave={async () => {
                if (props.readOnly) {
                    props.onClose(true)
                    return
                }
                setHideButtons(true)
                const result = await submitRef.current()
                if (result) {
                    props.onClose(true)
                } else {
                    console.error("Failed invoice upsert")
                }
                setHideButtons(false)
            }}
        >
            {!props.hasLayout && <InvoiceLayout {...props} />}
            {props.children}
        </Panel>
    )
}

export function InvoiceLayout(props) {
    return (
        <Stack>
            <Stack horizontal>
                <Stack.Item grow={3}>
                    {props.imageId && (
                        <Stack.Item>
                            <Image
                                src={
                                    config.images.baseUrl +
                                    props.imageId +
                                    "-xs"
                                }
                            />
                        </Stack.Item>
                    )}
                    <br />
                    From:
                    <br />
                    {props.from}
                    <br />
                    <Stack.Item>
                        <Stack horizontal>{props.actionButtons}</Stack>
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item grow={2}>
                    <Stack>
                        <Stack.Item>
                            {props.po ? "Supplier:" : "Bill To:"}
                            <br />
                            {props.to}
                        </Stack.Item>
                        <br />
                    </Stack>
                    {props.Form}
                </Stack.Item>
            </Stack>
            <Stack.Item>
                <div style={{ height: "auto" }}>{props.list}</div>
            </Stack.Item>
            <Stack.Item>
                <Stack style={{ root: "100%" }} horizontal>
                    <Stack.Item grow={9} style={{ textAlign: "right" }}>
                        <b>Total:</b>&nbsp;
                    </Stack.Item>
                    <Stack.Item>{props.total} </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}
