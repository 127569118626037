import {
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    SelectionMode,
} from "@fluentui/react"

export default function MiniList(props) {
    const items = props.items
    const columns = props.columns
    const lineThrough = props.lineThrough
    const renderRow = (props) => {
        const rowStyles = {
            root: {
                background: "transparent",
                marginTop: -6,
                marginBottom: -6,
                textDecoration: lineThrough ? "line-through" : "none",

                paddingTop: 0,
                paddingBottom: 0,
                minHeight: 1,
                overflow: "show",
                zIndex: 3,
                cell: {
                    margin: 0,
                    padding: 0,
                    verticalAlign: "top",
                    minHeight: 1,
                    height: 20,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                row: {
                    minHeight: 1,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                checkCell: {
                    height: 32,
                    minHeight: 1,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                fields: {
                    minHeight: 1,
                },
                cellPadded: { paddingTop: 0, paddingBottom: 0 },

                selectors: {
                    ":hover": {
                        background: "transparent",
                    },
                },
            },
        }
        /*if (props.noPrint) {
            return (
                <div className="no-print">
                    <DetailsRow
                        {...props}
                        compact={true}
                        styles={rowStyles}
                        className="foo"
                    />
                </div>
            )
        }*/
        return (
            <DetailsRow
                {...props}
                compact={true}
                styles={rowStyles}
                className="foo"
            />
        )
    }

    return (
        <div style={{ overflow: "hidden" }}>
            <DetailsList
                cellStyleProps={{
                    cellLeftPadding: 0,
                    cellRightPadding: 0,
                    cellExtraRightPadding: 0,
                }}
                styles={{
                    root: {
                        overflow: "hidden",
                        selectors: {
                            [`& .ms-List-cell`]: {
                                minHeight: "0 !important",
                                wordBreak: "break-word",
                            },
                        },
                    },
                }}
                compact={true}
                items={items}
                columns={columns}
                onRenderDetailsHeader={() => null}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
                selectionMode={SelectionMode.none}
                onRenderRow={renderRow}
            />
        </div>
    )
}
