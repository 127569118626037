import { useEntityFromConfig } from "../hooks/Entity"
import MiniList from "../layouts/MiniList"
import { useTagTypeConfig } from "./TagTypes"

export function SpecificationDescription(props) {
    const useTag = useEntityFromConfig(useTagTypeConfig())

    const value = props.value
    const tagFields = props.tagFields
    const tagTypeId = props.tagTypeId
    const archived = props.archived
    const tag = useTag.filter([
        { field: "id", type: "one", value: tagTypeId },
    ])[0]
    if (!tag) {
        return null
    }
    const columns = [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            maxWidth: 100,
            isMultiline: true,
        },
        {
            key: "value",
            name: "Value",
            fieldName: "value",
            isMultiline: true,
        },
    ]

    const items = []

    for (const field of tag.fields) {
        const value = tagFields[field.key]
        if (value) {
            items.push({
                key: field.key,
                name: field.name,
                value: value,
            })
        }
    }

    for (const [key, v] of Object.entries(value.custom)) {
        items.push({
            key: "tag2-" + key,
            name: v.name,
            value: v.value,
        })
    }

    return <MiniList items={items} columns={columns} lineThrough={archived} />
}
