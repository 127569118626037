import React from "react"
import { InlineWidget } from "react-calendly"
import { useEntityFromConfig } from "../hooks/Entity"
import { infoConfig } from "./Infos"
import { userConfig } from "./Users"

export default function Calendly(props) {
    const useInfo = useEntityFromConfig(infoConfig)
    const useUser = useEntityFromConfig(userConfig)

    const infos = useInfo.filter([])
    const users = infos[0]
        ? useUser.filter([{ field: "id", type: "eq", value: infos[0].userId }])
        : []

    return (
        <div className="App">
            <InlineWidget
                url="https://calendly.com/prospecer/support-call"
                prefill={{
                    email:
                        users[0] && users[0].contact && users[0].contact.email
                            ? users[0].contact.email
                            : null,
                }}
            />
        </div>
    )
}
