import React, { useMemo, useState, useRef } from "react"
import {
    ComboBox,
    Icon,
    Label,
    Separator,
    Stack,
    Text,
    useTheme,
} from "@fluentui/react"
import { valueInKeyPath } from "@prospecer/ffsds/src/shared/keypath"
import UpsertModal from "../layouts/UpsertModal"

function buildSelectOptions(items, valueKeyPath, descrKeyPath, detailsKeyPath) {
    const options = []

    for (const i of items) {
        options.push({
            key: valueInKeyPath(valueKeyPath, i),
            text: valueInKeyPath(descrKeyPath, i),
            details: detailsKeyPath ? valueInKeyPath(detailsKeyPath, i) : null,
        })
    }
    return options
}
export default function Entity(props) {
    const [addForm, setAddForm] = useState(null)
    const submitRef = useRef()

    const theme = useTheme()
    const options = useMemo(() => {
        const options = []
        if (props.nullable) {
            options.unshift({
                key: null,
                text: "",
            })
        }
        if (props.Form) {
            options.push({
                key: "__new",
                text: "Add New",
            })
        }
        options.push(
            ...buildSelectOptions(
                props.options,
                props.valueKeyPath,
                props.descrKeyPath,
                props.detailsKeyPath
            )
        )
        return options
    }, [
        props.options,
        props.valueKeyPath,
        props.descrKeyPath,
        props.Form,
        props.detailsKeyPath,
        props.nullable,
    ])

    const onRenderOptionWithDescr = (option) => {
        const addStyle = {
            root: {
                color: theme.palette.themeDarker,
                textAlign: "center",
            },
        }
        return (
            <Stack verticalAlign="center">
                <Label styles={option.key === "__new" ? addStyle : {}}>
                    {option.key === "__new" && (
                        <Icon
                            styles={{ root: { paddingRight: 5 } }}
                            iconName="Add"
                        />
                    )}
                    {option.text}
                </Label>
                {option.details && (
                    <>
                        <Text>{option.details}</Text>
                        <Separator />
                    </>
                )}
            </Stack>
        )
    }

    return (
        <>
            {addForm}
            <ComboBox
                label={props.label}
                options={options}
                styles={{ ...props.styles, label: { marginBottom: "-2px" } }}
                autoComplete="on"
                allowFreeform={false}
                disabled={props.disabled}
                onChange={(e, option, value) => {
                    if (option.key === "__new") {
                        setAddForm(
                            <UpsertModal
                                buttonLabel={props.addButtonLabel}
                                submitRef={submitRef}
                                AddForm={props.Form}
                                onClose={(values) => {
                                    if (values && values.id) {
                                        props.onChange(e, values.id)
                                    }
                                    setAddForm(null)
                                }}
                            />
                        )
                    } else {
                        props.onChange(e, option.key)
                    }
                }}
                onRenderOption={onRenderOptionWithDescr}
                errorMessage={props.errorMessage}
                onGetErrorMessage={props.onGetErrorMessage}
                selectedKey={props.value === null ? "" : props.value}
                required={props.required}
                placeholder={props.placeholder}
            />
        </>
    )
}
