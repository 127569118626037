import React from "react"

import { refers as refersValidator } from "@prospecer/schema"
import config from "../config"
import { EntityList, EntityForm } from "./Entities"
import { useEntityFromConfig } from "../hooks/Entity"
import { infoConfig } from "./Infos"

export function useRefersConfig(userId) {
    return {
        baseUrl: config.api.baseUrl + "/refers",
        type: "REFERS",
        stateKey: "refers",
        name: "Refers",
        nameSingle: "Refer",
        readScope: "read:specifications",
        writeScope: "read:specifications",

        formFields: [
            {
                label: "Studio Name",
                key: "studioName",
                type: "text",
            },
            {
                label: "Name",
                key: "contact.name",
                type: "text",
            },
            {
                label: "Email",
                key: "contact.email",
                type: "email",
            },
            {
                label: "Phone",
                key: "contact.phone",
                type: "tel",
                coupledValue: "contact.country",
            },
        ],
        emptyFormValue: { contact: {}, userId: userId },
        validator: refersValidator,
        Form: ReferForm,
    }
}

export function ReferForm(props) {
    const infos = useEntityFromConfig(infoConfig)
    const info = infos.filter([])
    const userId = info[0] ? info[0].userId : null
    const refersConfig = useRefersConfig(userId)
    return <EntityForm {...props} config={refersConfig} />
}
