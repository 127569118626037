import {
    mergeStyles,
    mergeStyleSets,
    Stack,
    TooltipHost,
    useTheme,
    Text,
} from "@fluentui/react"
import { useId } from "@fluentui/react-hooks"

/*
items = [{
    key:"key",
    value:"1245",
    label:"This value",
    descr:"kdhjsa dklsjfklf jsdklfjdsfklajk fjksdk"
    suffix:"+"
}
]
*/

function useStyles() {
    const theme = useTheme()

    return mergeStyleSets({
        root: {
            backgroundColor: theme.palette.themeLighter,
            padding: theme.spacing.m,
            borderTop: " 1px solid " + theme.palette.themeDarkAlt,
            borderBottom: " 1px solid " + theme.palette.themeDarkAlt,
            color: theme.palette.black,
        },
        itemRoot: { gap: "10px", paddingRight: "10px" },
    })
}

function SummaryItem(props) {
    const tooltipId = useId("tooltip")
    const styles = useStyles()

    return (
        <Stack align="center" className={styles.itemRoot} horizontal>
            <TooltipHost
                content={props.descr}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={tooltipId}
            >
                <div key={props.key}>
                    <Stack>
                        <Text variant="xLarge">{props.value}</Text>
                        <Text variant="xSmall">{props.label}</Text>
                    </Stack>
                </div>
            </TooltipHost>
            <Stack.Item align="center">{props.suffix}</Stack.Item>
        </Stack>
    )
}

export default function SummaryBar(props) {
    const styles = useStyles()

    const items = []
    for (const i of props.items) {
        items.push(<SummaryItem key={"root" + i.key} {...i} />)
    }

    return (
        <div className={styles.root}>
            <Stack horizontal>{items}</Stack>
        </div>
    )
}
