import {
    ActionButton,
    DefaultButton,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    PrimaryButton,
    Separator,
    Stack,
    Text,
    getTheme,
} from "@fluentui/react"
import { useEntityFromConfig } from "../hooks/Entity"
import { tagFormatter, useConfig } from "./Specifications"
import Modal from "../layouts/Modal"
import { useState } from "react"
import { useBoolean } from "@fluentui/react-hooks"
import { useTagTypeConfig } from "./TagTypes"
import { result } from "lodash"
import { useNotification } from "../hooks/Notifications"

async function moveExisting(useSpecification, conflictSpec, toNumber) {
    const results = useSpecification.filter([
        {
            field: "tagTypeId",
            type: "eq",
            value: conflictSpec.tagTypeId,
        },
        { field: "projectId", type: "eq", value: conflictSpec.projectId },
        { field: "id", type: "neq", value: conflictSpec.id },
        { field: "tagNumber", type: "eq", value: conflictSpec.tagNumber },
    ])

    if (results[0]) {
        await useSpecification.dispatch.upsert({
            ...results[0],
            tagNumber: toNumber,
        })
    }
}

async function moveDown(useSpecification, conflictSpec, max, setProgress) {
    const results = useSpecification.filter([
        {
            field: "tagTypeId",
            type: "eq",
            value: conflictSpec.tagTypeId,
        },
        { field: "projectId", type: "eq", value: conflictSpec.projectId },
        { field: "id", type: "neq", value: conflictSpec.id },
        { field: "tagNumber", type: "gteq", value: conflictSpec.tagNumber },
        { type: "_sort", key: "tagNumber", direction: "DSC" },
    ])

    const oldNum = conflictSpec.tagNumber
    setProgress("TagNumAdjust-marking-dialog", {
        title: "Making Space",
        description: "Moving current spec out of the way",
        percentComplete: 0,
    })
    if (!conflictSpec.__new) {
        await useSpecification.dispatch.upsert({
            ...conflictSpec,
            tagNumber: max + 1,
        })
    }

    let count = 1
    for (const r of results) {
        setProgress("TagNumAdjust-marking-dialog", {
            title: "Moving Down",
            description: "Moving " + r.tagNumber + " down",
            percentComplete: results.length + 1 / count,
        })
        count++
        await useSpecification.dispatch.upsert({
            ...r,
            tagNumber: r.tagNumber + 1,
        })
    }
    setProgress("TagNumAdjust-marking-dialog", {
        title: "Making Space",
        description: "Moving Current spec back",
        percentComplete: 1,
    })
    if (!conflictSpec.__new) {
        await useSpecification.dispatch.upsert({
            ...conflictSpec,
            tagNumber: oldNum,
        })
    }
}
function TagResolveModal(props) {
    const values = props.values
    const { status } = useNotification()

    const theme = getTheme()
    const useSpecification = useEntityFromConfig(useConfig())
    const results = useSpecification.filter([
        {
            field: "tagTypeId",
            type: "eq",
            value: values.tagTypeId,
        },
        { field: "projectId", type: "eq", value: props.projectId },
        { field: "id", type: "neq", value: values.id },
    ])
    const max = results.reduce((prev, current) =>
        prev.tagNumber > current.tagNumber ? prev : current
    ).tagNumber

    const useTagType = useEntityFromConfig(useTagTypeConfig())
    const tag = tagFormatter(useTagType, values.tagTypeId, values.tagNumber)

    const boxStyle = {
        boxShadow: theme.effects.elevation8,
        padding: theme.spacing.s1,
        marginBottom: theme.spacing.l1,
    }
    const stackTokens = {
        childrenGap: "m",
        padding: "s1",
    }

    return (
        <Modal title={"Resolve conflict for " + tag} {...props}>
            <Stack>
                <div style={boxStyle}>
                    <Stack enableScopedSelectors tokens={stackTokens}>
                        <Text variant="large">
                            {" "}
                            Use{" "}
                            {tagFormatter(
                                useTagType,
                                values.tagTypeId,
                                max + 1
                            )}
                        </Text>
                        This is the least destructive option. Nothing will
                        change with other specs. The next available number will
                        be used for this current spec and you will not change
                        any existing specs.
                        <PrimaryButton
                            onClick={() => {
                                props.setValues({
                                    ...values,
                                    tagNumber: max + 1,
                                })
                                props.onClose()
                            }}
                        >
                            <b>
                                Use{" "}
                                {tagFormatter(
                                    useTagType,
                                    values.tagTypeId,
                                    max + 1
                                )}
                            </b>
                        </PrimaryButton>
                    </Stack>
                </div>
                <div style={boxStyle}>
                    <Stack enableScopedSelectors tokens={stackTokens}>
                        <Text variant="large">
                            Move the existing{" "}
                            {tagFormatter(
                                useTagType,
                                values.tagTypeId,
                                values.tagNumber
                            )}
                        </Text>
                        Move the existing{" "}
                        {tagFormatter(
                            useTagType,
                            values.tagTypeId,
                            values.tagNumber
                        )}{" "}
                        to {tagFormatter(useTagType, values.tagTypeId, max + 1)}
                        . This will prevent the spec you are working with
                        conflicting. If you have shared the existing{" "}
                        {tagFormatter(
                            useTagType,
                            values.tagTypeId,
                            values.tagNumber
                        )}{" "}
                        or used it in drawings then those drawings will no
                        longer reference the correct spec.
                        <DefaultButton
                            styles={{
                                root: {
                                    backgroundColor: theme.palette.red,
                                    color: theme.palette.white,
                                },
                            }}
                            onClick={() => {
                                moveExisting(useSpecification, values, max + 1)

                                props.onClose()
                            }}
                        >
                            <b>
                                Move existing{" "}
                                {tagFormatter(
                                    useTagType,
                                    values.tagTypeId,
                                    values.tagNumber
                                )}{" "}
                                to{" "}
                                {tagFormatter(
                                    useTagType,
                                    values.tagTypeId,
                                    max + 1
                                )}
                            </b>
                        </DefaultButton>
                    </Stack>
                </div>
                {max !== values.tagNumber && (
                    <div style={boxStyle}>
                        <Stack enableScopedSelectors tokens={stackTokens}>
                            <Text variant="large">Move everything down</Text>
                            This will move{" "}
                            {tagFormatter(
                                useTagType,
                                values.tagTypeId,
                                values.tagNumber
                            )}{" "}
                            to{" "}
                            {tagFormatter(
                                useTagType,
                                values.tagTypeId,
                                max + 1
                            )}{" "}
                            down one number to make room for this spec. This
                            action is not recommended if you have shared any of
                            your specs or have referenced any spec in a drawing.{" "}
                            <b>This is the most destructive option</b>
                            <DefaultButton
                                styles={{
                                    root: {
                                        backgroundColor: theme.palette.red,
                                        color: theme.palette.white,
                                    },
                                }}
                                onClick={() => {
                                    moveDown(
                                        useSpecification,
                                        values,
                                        max + 1,
                                        status
                                    )

                                    props.onClose()
                                }}
                            >
                                <b>
                                    Move{" "}
                                    {tagFormatter(
                                        useTagType,
                                        values.tagTypeId,
                                        values.tagNumber
                                    )}{" "}
                                    through{" "}
                                    {tagFormatter(
                                        useTagType,
                                        values.tagTypeId,
                                        max
                                    )}{" "}
                                    down one
                                </b>
                            </DefaultButton>
                        </Stack>
                    </div>
                )}
            </Stack>
        </Modal>
    )
}

export default function TagTypeWraper(props) {
    if (props.readOnly) {
        return <Stack horizontal>{props.children}</Stack>
    }

    return <TagTypeWraperAdmin {...props} />
}
export function TagTypeWraperAdmin(props) {
    console.log(props)
    const useSpecification = useEntityFromConfig(useConfig())
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
        useBoolean(false)

    const values = props.values
    const filters = []

    if (values.id) {
        filters.push({ field: "id", type: "neq", value: values.id })
    }
    filters.push({ field: "tagTypeId", type: "eq", value: values.tagTypeId })
    filters.push({ field: "tagNumber", type: "eq", value: values.tagNumber })
    filters.push({ field: "projectId", type: "eq", value: props.projectId })

    const results = useSpecification.filter(filters)

    return (
        <>
            {isModalOpen && (
                <TagResolveModal
                    setValues={props.setValues}
                    onClose={() => {
                        hideModal()
                    }}
                    values={values}
                    projectId={props.projectId}
                />
            )}
            <Stack>
                <Stack horizontal>{props.children}</Stack>
                {results.length > 0 && (
                    <MessageBar
                        messageBarType={MessageBarType.warning}
                        actions={
                            <div>
                                <MessageBarButton
                                    onClick={() => {
                                        showModal()
                                    }}
                                >
                                    See Options
                                </MessageBarButton>
                            </div>
                        }
                    >
                        That tag type and tag number conflict with an already
                        saved item
                    </MessageBar>
                )}
            </Stack>
        </>
    )

    //return <Stack horizontal>{props.children}</Stack>
}
