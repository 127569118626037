import React from "react"

import { project as projectValidator } from "@prospecer/schema"
import config from "../config"
import { clientConfig, ClientForm } from "./Clients"
import { useEntityFromConfig } from "../hooks/Entity"
import { EntityList, EntityForm } from "./Entities"
import { userConfig, UserForm } from "./Users"

export function useProjectConfig(withStartFrom = true) {
    const useClient = useEntityFromConfig(clientConfig)
    const useUser = useEntityFromConfig(userConfig)

    return {
        baseUrl: config.api.baseUrl + "/projects",
        type: "PROJECTS",
        stateKey: "projects",
        name: "Projects",
        nameSingle: "Project",
        readScope: "read:projects",
        writeScope: "write:projects",
        defaultSortKey: "name",
        listColumns: [
            {
                key: "name",
                title: "Name",
            },
            {
                key: "clientId",
                title: "Client",
                formatter: (value) => {
                    const r = useClient.filter([
                        { field: "id", type: "one", value: value },
                    ])
                    if (!r[0]) {
                        return null
                    }
                    return r[0].name
                },
            },
            {
                key: "primaryUserId",
                title: "Primary Designer",
                formatter: (value) => {
                    const r = useUser.filter([
                        { field: "id", type: "one", value: value },
                    ])
                    if (!r[0]) {
                        return null
                    }
                    return r[0].contact.name
                },
            },

            {
                key: "secondaryUserId",
                title: "Primary Designer",
                formatter: (value) => {
                    const r = useUser.filter([
                        { field: "id", type: "one", value: value },
                    ])
                    if (!r[0]) {
                        return null
                    }
                    return r[0].contact.name
                },
            },
        ],

        formFields: [
            {
                label: "Name",
                key: "name",
                type: "text",
            },
            {
                label: "Client",
                key: "clientId",
                type: "entity",
                descrKeyPath: "name",
                valueKeyPath: "id",
                options: useClient.filter([]),
                Form: (props) => {
                    return <ClientForm {...props} config={clientConfig} />
                },
            },
            {
                label: "Primary Designer",
                key: "primaryUserId",
                type: "entity",
                descrKeyPath: "contact.name",
                valueKeyPath: "id",
                options: useUser.filter([]),
                nullable: true,
                Form: (props) => {
                    return <UserForm {...props} config={userConfig} />
                },
            },
            {
                label: "Secondary Designer",
                key: "secondaryUserId",
                type: "entity",
                descrKeyPath: "contact.name",
                valueKeyPath: "id",
                nullable: true,

                options: useUser.filter([]),
                Form: (props) => {
                    return <UserForm {...props} config={userConfig} />
                },
            },
            {
                label: "Notes",
                key: "note",
                type: "text",
                option: "multiline",
            },
            {
                label: "Archived",
                key: "archive",
                type: "toggle",
                onText: "Archived",
                offText: "Active",
            },
        ],
        emptyFormValue: { __new: true, archive: 0 },
        validator: projectValidator,
        Form: ProjectForm,
        allowDelete: true,
    }
}

export function usePublicProjectsConfig(studioId, projectShareId) {
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/projects?id=" +
            projectShareId +
            "&studioId=" +
            studioId,
        type: "PROJECTS",
        stateKey: "projects",
        name: "Projects",
        nameSingle: "Project",
        readScope: "read:projects",
        writeScope: "write:projects",
        defaultSortKey: "name",
        insecure: true,
    }
}

export function ProjectsList(props) {
    const projectsConfig = useProjectConfig()
    return <EntityList config={projectsConfig} />
}

export function ProjectForm(props) {
    const projectsConfig = useProjectConfig()

    //We have to do this here to avoid recursion
    const useProjets = useEntityFromConfig(projectsConfig)

    projectsConfig.formFields.push({
        label: "Start From",
        key: "fromProjectId",
        type: "entity",
        descrKeyPath: "name",
        valueKeyPath: "id",
        options: useProjets.filter([]),
        disabledOnUpdate: true,
    })
    return <EntityForm {...props} config={projectsConfig} />
}
