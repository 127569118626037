import React from "react"

import { supplier as supplierValadator } from "@prospecer/schema"
import config from "../config"

import { EntityList, EntityForm } from "./Entities"

export function usePublicSupplier(studioId, projectSharesId) {
    return {
        baseUrl:
            config.api.baseUrl +
            "/public/suppliers?id=" +
            projectSharesId +
            "&studioId=" +
            studioId,
        type: "SUPPLIERS",
        stateKey: "suppliers",
        name: "Suppliers",
        nameSingle: "Supplier",
        readScope: "read:suppliers",
        writeScope: "write:suppliers",
        defaultSortKey: "name",
        insecure: true,
        allowDelete: false,
        listColumns: supplierConfig.listColumns,
    }
}

export const supplierConfig = {
    baseUrl: config.api.baseUrl + "/suppliers",
    type: "SUPPLIERS",
    stateKey: "suppliers",
    name: "Suppliers",
    nameSingle: "Supplier",
    readScope: "read:suppliers",
    writeScope: "write:suppliers",
    defaultSortKey: "name",
    listColumns: [
        {
            key: "name",
            title: "Name",
        },
        {
            key: "contact.name",
            title: "Supplier name",
        },
        {
            key: "contact.phone",
            title: "Phone Number",
        },
        {
            key: "web",
            title: "Web Site",
        },
        {
            key: "notes",
            title: "Notes",
        },
    ],
    formFields: [
        {
            label: "Supplier Name",
            key: "name",
            type: "text",
        },
        {
            label: "Website",
            key: "web",
            type: "text",
            prefix: "http://",
        },
        {
            label: "Address",
            key: "contact.address",
            type: "text",
            option: "multiline",
        },
        {
            label: "Country",
            key: "contact.country",
            type: "country",
        },
        {
            label: "City",
            key: "contact.city",
            type: "text",
        },
        {
            label: "State",
            key: "contact.state",
            type: "state",
            coupledValue: "contact.country",
        },
        {
            label: "Zip/Postal",
            key: "contact.postal",
            type: "text",
        },
        {
            label: "Primary Contact",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Name",
            key: "contact.name",
            type: "text",
        },
        {
            label: "Phone",
            key: "contact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },
        {
            label: "Mobile",
            key: "contact.mobile",
            type: "tel",
            coupledValue: "contact.country",
            extension: false,
        },

        {
            label: "Email",
            key: "contact.email",
            type: "email",
        },
        {
            label: "",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Secondary Contact",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Name",
            key: "secondaryContact.name",
            type: "text",
        },
        {
            label: "Phone",
            key: "secondaryContact.phone",
            type: "tel",
            coupledValue: "contact.country",
        },
        {
            label: "Mobile",
            key: "secondaryContact.mobile",
            type: "tel",
            coupledValue: "contact.country",
            extension: false,
        },

        {
            label: "Email",
            key: "secondaryContact.email",
            type: "email",
        },
        {
            label: "",
            type: "separator",
            displayOnly: true,
        },
        {
            label: "Discount",
            key: "discount",
            type: "text",
            subtype: "number",
            prefix: "%",
        },
        {
            label: "Notes",
            key: "notes",
            type: "text",
            option: "multiline",
        },
    ],
    emptyFormValue: { contact: {}, secondaryContact: {} },
    validator: supplierValadator,
    Form: SupplierForm,
    allowDelete: true,
}

export function SuppliersList(props) {
    return <EntityList config={supplierConfig} />
}

export function SupplierForm(props) {
    return <EntityForm {...props} config={supplierConfig} />
}
